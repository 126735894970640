import firebase from '../../config/firebase';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';

export const addRevenda = async ({ data }) => {
    const doc = await firebase.firestore().collection('revendas').add(data)
    return doc.id;
}

export const getRevendaById = async ({ id }) => {
    if (id) {
        const result = await firebase.firestore().collection('revendas').doc(id).get()
        return docToItem(result)
    }
    return null
}

export const updateRevenda = async ({ id, data }) => {
    await firebase.firestore().collection("revendas").doc(id).set(data, { merge: true })
}

export const getRevendaByCode = async ({ code }) => {
    if (code) {
        const result = await firebase.firestore().collection('revendas').where("codigo", "==", code).get()
        return docsToItem(result)
    }
    return null
}
export const getAllRevendas = async () => {
    const result = await firebase.firestore().collection('revendas').get()
    return docsToItems(result)
}
import firebase from '../../config/firebase';
import { docToItem, docsToItems } from '../transform.docs';

export const getAllAccreditedByParkId = async (parkId) => {
    var results = await firebase.firestore().collection("credenciados").where("estacionamentoId", '==', parkId).get()
    return docsToItems(results)
}

export const getAllAccreditedsByClientId = async ({ clienteId }) => {
    var results = await firebase.firestore().collection("credenciados").where("clienteId", '==', clienteId).get()
    return docsToItems(results)
}

export const getAccreditedById = async ({ id }) => {
    var results = await firebase.firestore().collection("credenciados").doc(id).get()
    return docToItem(results)
}

export const getAccreditedByPlateAndParkId = async ({ plate, parkId }) => {
    const accrediteds = await getAllAccreditedByParkId(parkId)
    const accredited = accrediteds.filter(e => e.veiculos.map(e => e.placa).indexOf(plate) !== -1)[0]
    if (accredited) {
        accredited.currentVehicle = accredited.veiculos.filter(e => e.placa === plate)[0]
        return accredited
    }
    return null
}

export const getAccreditedsByPlateAndClientId = async ({ plate, clienteId }) => {
    const accrediteds = await getAllAccreditedsByClientId({ clienteId: clienteId })
    return accrediteds.filter(e => e.veiculos.map(e => e.placa).indexOf(plate) !== -1)
}

export const getAccreditedVehicleByPlateAndParkId = async ({ plate, parkId }) => {
    const accrediteds = await getAllAccreditedByParkId(parkId)
    return accrediteds.map(e => e.veiculos.map(e => e.placa)).flatMap(e => e).filter(e => e.placa === plate)[0]
}

export const updateAccredited = async (id, data) => {
    await firebase.firestore().collection("credenciados").doc(id).update(data)
}
import moment from 'moment'
import { transformPriceTable } from './priceTableTransform'

export const transformTicket = (ticketIn) => {
    const ticketOut = {
        id: ticketIn.id,
        ticket: ticketIn.ticket,
        plate: ticketIn.placa,
        parkId: ticketIn.estacionamentoId,
        createdAt: moment(ticketIn.createAt.toDate() ?? new Date()).toDate(),
        type: ticketIn.typeVehicler,
        system: ticketIn.system,
        status: ticketIn.status,
        lastSimulate: ticketIn.lastSimulate,
        currentTable: transformPriceTable(ticketIn.tableSelectedInCheckIn),
        paymentsLogged: ticketIn.paymentsLogged?.map(e => {
            return {
                cashierId: e.caixaId,
                canceled: e.canceled,
                createdAt: e.createAt,
                paymentMethod: {
                    type: e.formaPagamento.tipo,
                    name: e.formaPagamento.nome
                },
                resume: {
                    total: e.resumo.valorCobrado,
                    discount: e.resumo.valorDesconto,
                    parking: e.resumo.valorEstacionamento,
                    products: e.resumo.valorProdutos,
                    services: e.resumo.valorServicos
                },
                online: e.online
            }
        }),
        monthly: ticketIn.monthly ? {
            id: ticketIn.monthly?.id,
            name: ticketIn.monthly?.nome,
            dueDate: moment(ticketIn.monthly?.plano?.dataFatura?.toDate() ?? new Date()).toDate()
        } : undefined,
        statusLogged: ticketIn.statusLogged?.map(e => {
            return {
                createdAt: e.createAt,
                status: e.status,
                createdBy: {
                    nome: e.createBy?.nome ?? "Automação",
                    email: e.createBy?.email ?? "",
                }
            }
        }),
        products: ticketIn.produtos?.map(e => {
            return {
                name: e.descricao,
                type: e.tipoProduto,
                quantity: e.quantidade,
                value: e.valor
            }
        }),
        closedAt: ticketIn.closedAt ? moment(ticketIn.closedAt?.toDate() ?? new Date()).toDate() : undefined
    }
    return ticketOut
}

export const untransformTicket = (ticketIn) => {
    const ticketOut = {
        id: ticketIn.id,
        ticket: ticketIn.ticket,
        placa: ticketIn.plate,
        estacionamentoId: ticketIn.parkId,
        createAt: ticketIn.createdAt.toDate(),
        typeVehicler: ticketIn.type,
        system: ticketIn.system,
        status: ticketIn.status,
        lastSimulate: ticketIn.lastSimulate,
        closedAt: ticketIn.closedAt?.toDate()
    }
    return ticketOut
}
import { getEstacionamento } from "../../config/auth"
import firebase from '../../config/firebase';
import { docsToItems } from "../transform.docs";

export const getAllCredentialsByParkId = async () => {
    const parkId = getEstacionamento()?.id
    if (parkId) {
        const result = await firebase.firestore().collection('credenciados').where('estacionamentoId', '==', parkId).get()
        return docsToItems(result)
    }
}

export const addCredential = async ({ data }) => {
    await firebase.firestore().collection('credenciados').add(data)
}

export const updateCredential = async ({ id, data }) => {
    await firebase.firestore().collection('credenciados').doc(id).update(data)
}
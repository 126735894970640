import firebase from '../../config/firebase';
import { docToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addSector = async ({ data }) => {
    await firebase.firestore().collection("setores").add(data)
}

export const updateSectorById = async ({ id, data }) => {
    await firebase.firestore().collection("setores").doc(id).update(data)
}

export const removeSectorById = async ({ id }) => {
    await deleteDoc({ collection: "setores", id: id })
}

export const getAllSectoresByParkId = async ({ estacionamentoId }) => {
    var results = await firebase.firestore().collection("setores").where("estacionamentoId", '==', estacionamentoId).get()
    return docsToItems(results).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getSectorById = async ({ id }) => {
    var result = await firebase.firestore().collection("setores").doc(id).get()
    return docToItem(result)
}

export const getAllSectores = async ({ clienteId }) => {
    var results = await firebase.firestore().collection("setores").where("clienteId", '==', clienteId).get()
    return docsToItems(results).sort((a, b) => a.nome.localeCompare(b.nome))
}
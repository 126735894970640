import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../components/common/commons'
import firebase from '../../config/firebase';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import { sendClickButton, sendLog } from '../../shared/analytics'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import NumberFormat from 'react-number-format';
import 'firebase/auth';
import 'firebase/storage';
import { AlertWarning } from '../../shared/alert-manager';
import { deleteDoc } from '../../store/collections/collectionBaseWorker';
import { reloadWithAlert, reloadWithAlertError, toastSuccess, toastWarning, toCurrency } from '../../shared/utils';
import Tooltip from '@mui/material/Tooltip';

const initializeState = {
    id: null,
    tipo: 'Porcentagem',
    tipoProgressivo: 'Valor',
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    manterDesconto: 'Não',
    valor: 0.0,
    tolerancia: '',
    porcentagem: "",
    nome: '',
    status: 'Ativo',
    visibilidade: "APP",
    progressives: [],
    random: Math.random()
}

class CriarConvenio extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
    }

    reset = () => {
        this.setState({ id: null })
        this.setState({ tipo: 'Porcentagem' })
        this.setState({ valor: 0.0 })
        this.setState({ tolerancia: '' })
        this.setState({ visibilidade: 'APP' })
        this.setState({ porcentagem: "" })
        this.setState({ nome: '' })
        this.setState({ manterDesconto: 'Não' })
        this.setState({ status: 'Ativo' })
        this.setState({ progressives: [] })
        this.setState({ porcentagem: "" })
        this.setState({ random: Math.random() })
    }

    update = async () => {
        if (!this.checkFields()) {
            return
        }
        sendClickButton("Convênio", "Cadastrar")
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            alterBy: getUsuario().email,
            tipo: this.state.tipo,
            valor: this.state.valor,
            porcentagem: this.state.porcentagem.toString(),
            nome: this.state.nome,
            status: this.state.status,
            tolerancia: this.state.tolerancia,
            manterDesconto: this.state.manterDesconto,
            visibilidade: this.state.visibilidade,
            progressives: this.state.progressives,
            alterAt: new Date(),
            isDeleted: false,
        }
        await firebase.firestore().collection('convenios').doc(this.state.id).update(data)
        await saveLastUpdate()
        toastSuccess('Convênio atualizado com sucesso!');
        sendLog("Convênio", "Convênio atualizado com sucesso!", true, data)
        this.reset()
    }

    add = async () => {
        if (!this.checkFields()) {
            return
        }
        sendClickButton("Convênio", "Atualizar")
        const data = {
            tipo: this.state.tipo,
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            createdBy: getUsuario().email,
            revendaId: getRevendaIdByPark(),
            valor: this.state.valor,
            porcentagem: this.state.porcentagem.toString(),
            nome: this.state.nome,
            status: this.state.status,
            tolerancia: this.state.tolerancia,
            manterDesconto: this.state.manterDesconto,
            visibilidade: this.state.visibilidade,
            progressives: this.state.progressives,
            createdAt: new Date(),
            isDeleted: false,
        }
        await firebase.firestore().collection('convenios').add(data)
        await saveLastUpdate()
        toastSuccess('Convênio cadastrado com sucesso!');
        sendLog("Convênio", "Convênio cadastrado com sucesso!", true, data)
        this.reset()
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamentoId: item.id })
        this.setState({ random: Math.random() })
    }

    atualizarValor = (event, maskedvalue, floatvalue) => {
        this.setState({ valor: floatvalue })
    }

    checkFields = () => {
        if (this.state.nome === '') {
            toastWarning("Informe o nome do convênio.")
            return false
        }
        if (this.state.tolerancia === '') {
            toastWarning("Informe a tolerância do convênio.")
            return false
        }
        if (this.state.tipo === 'Progressivo' && this.state.progressives.length === 0) {
            toastWarning("Informe ao menos um valor e desconto progressivo do convênio.")
            return false
        }
        if (this.state.tipo === 'Porcentagem' && this.state.porcentagem === "") {
            toastWarning("Informe a porcentagem de desconto do convênio.")
            return false
        }
        if (this.state.tipo === 'Valor' && this.state.valor === 0) {
            toastWarning("Informe o valor de desconto do convênio.")
            return false
        }
        return true
    }

    onEdit = (item) => {
        this.setState({
            ...item,
            visibilidade: item.visibilidade ?? "APP",
            id: item.id
        })
    }

    onDuplicate = (item) => {
        this.setState({
            ...item,
            visibilidade: item.visibilidade ?? "APP",
            id: null
        })
    }

    onRemoveProgressivo = (progressivo) => {
        var list = this.state.progressives
        list = list.filter((item) => { return item.id !== progressivo.id })
        this.setState({ progressives: list.sort((a, b) => a.cobranca - b.cobranca) })
    }

    onAddProgressivo = () => {
        const cobranca = (this.state.cobranca ?? 0)
        if (cobranca <= 0) {
            toastWarning("Informe um valor de cobrança maior que zero.")
            return
        }
        const porcentagem = (this.state.porcentagem ?? "")
        if (this.state.tipoProgressivo === 'Porcentagem' && porcentagem === "") {
            toastWarning("Informe a porcentagem de desconto do convênio.")
            return
        }
        const desconto = (this.state.desconto ?? 0)
        if (this.state.tipoProgressivo === 'Valor' && desconto === 0) {
            toastWarning("Informe um valor de desconto maior que zero.")
            return
        }
        if (desconto > cobranca) {
            toastWarning("O valor de desconto não pode ser maior que o valor de cobrança.")
            return

        }
        const newList = this.state.progressives.filter((e) => e.cobranca === this.state.cobranca)
        if (newList.length > 0) {
            toastWarning("Este valor de cobrança já esta adicionado.")
            return
        }
        var list = this.state.progressives
        list.push({
            id: Math.floor(Math.random() * 1000) + 1,
            charge: this.state.cobranca ?? 0,
            discount: this.state.desconto ?? 0,
            porcentage: parseFloat(this.state.porcentagem) || 0,
            typeProgresive: this.state.tipoProgressivo
        })
        this.setState({ progressives: list.sort((a, b) => a.cobranca - b.cobranca) })
        this.setState({ cobranca: 0.0 })
        this.setState({ desconto: 0.0 })
    }

    render() {
        return (
            <NavBar>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        {this.state.id ? 'Atualizar convênio' : 'Cadastrar convênio'}
                        <HelpButton />
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-6">
                                <div className='row'>
                                    <div className="col-lg-2">
                                        <label>Status</label>
                                        <select className='form-select' value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                            <option value='Ativo'>Ativo</option>
                                            <option value='Inativo'>Inativo</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>Nome</label>
                                        <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome && this.state.nome} className="form-control" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Visibilidade</label>
                                        <select className='form-select' value={this.state.visibilidade && this.state.visibilidade} onChange={(e) => this.setState({ visibilidade: e.target.value })}>
                                            <option value='APP'>Aplicável no administrativo e aplicativo</option>
                                            <option value='ADMINISTRATIVO'>Aplicável apenas administrativo</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-lg-4">
                                        <label>Tipo</label>
                                        <select className='form-select' value={this.state.tipo && this.state.tipo} onChange={(e) => this.setState({ tipo: e.target.value })}>
                                            <option value='Valor'>Valor</option>
                                            <option value='Porcentagem'>Porcentagem</option>
                                            {
                                                isAdministrador() &&
                                                <option value='Progressivo'>Progressivo</option>
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.state.tipo === 'Valor' && <>
                                            <div className="col-lg-4">
                                                <label>Valor</label>
                                                <CurrencyInput value={this.state.valor && this.state.valor} prefix="R$" className='form-control' onChangeEvent={this.atualizarValor} />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Tolerância</label>
                                                <NumberFormat className="form-control" value={this.state.tolerancia} mask='_' onChange={(e) => this.setState({ tolerancia: e.target.value })} format="##:##" />
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.tipo === 'Porcentagem' && <>
                                            <div className="col-lg-4">
                                                <label>Porcentagem ( % )</label>
                                                <input type="text" onChange={(e) => this.setState({ porcentagem: e.target.value })} maxLength={100} value={this.state.porcentagem && this.state.porcentagem} className="form-control" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Tolerância</label>
                                                <NumberFormat className="form-control" value={this.state.tolerancia} mask='_' onChange={(e) => this.setState({ tolerancia: e.target.value })} format="##:##" />
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.tipo === 'Progressivo' && <>
                                            <div className="col-lg-4">
                                                <label>Tolerância</label>
                                                <NumberFormat className="form-control" value={this.state.tolerancia} mask='_' onChange={(e) => this.setState({ tolerancia: e.target.value })} format="##:##" />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="col-lg-12 mt-3">
                                    {
                                        this.state.tipo === "Valor" &&
                                        <AlertWarning tag="Informação" message="Se o total a ser cobrador der R$20,00 e o campo abaixo for R$5,00 reais, então será cobrado R$15,00 reais." />
                                    }
                                    {
                                        this.state.tipo === "Porcentagem" &&
                                        <AlertWarning tag="Informação" message="Se o total a ser cobrador der R$20,00 e o campo abaixo for 50%, então será cobrado R$ 10,00 reais." />
                                    }
                                    {
                                        this.state.tipo === "Progressivo" &&
                                        <AlertWarning tag="Informação" message="É atribuido um desconto progressivo para o valor total da cobrança." />
                                    }
                                </div>
                                {
                                    this.state.tipo === "Progressivo" &&
                                    <div className="col-lg-12 mt-3">
                                        <div className='row'>
                                            <div className="col-lg-3">
                                                <label>Valor da cobrança</label>
                                                <CurrencyInput prefix="R$" value={this.state.cobranca && this.state.cobranca} className='form-control' onChangeEvent={(event, maskedvalue, floatvalue) => { this.setState({ cobranca: floatvalue }) }} />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Tipo</label>
                                                <select className='form-select' value={this.state.tipoProgressivo && this.state.tipoProgressivo} onChange={(e) => this.setState({ tipoProgressivo: e.target.value })}>
                                                    <option value='Valor'>Valor</option>
                                                    <option value='Porcentagem'>Porcentagem</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.tipoProgressivo === 'Valor' &&
                                                <div className="col-lg-3">
                                                    <label>Valor de desconto</label>
                                                    <CurrencyInput prefix="R$" value={this.state.desconto && this.state.desconto} className='form-control' onChangeEvent={(event, maskedvalue, floatvalue) => { this.setState({ desconto: floatvalue }) }} />
                                                </div>
                                            }
                                            {
                                                this.state.tipoProgressivo === 'Porcentagem' &&
                                                <div className="col-lg-3">
                                                    <label>Porcentagem ( % )</label>
                                                    <input type="text" onChange={(e) => this.setState({ porcentagem: e.target.value })} maxLength={100} value={this.state.porcentagem && this.state.porcentagem} className="form-control" />
                                                </div>
                                            }
                                            <div className='col-lg-3 my-4'>
                                                <button type="button" onClick={(e) => { this.onAddProgressivo() }} className="btn btn-primary">
                                                    <i className="fas fa-plus mx-2" /> Adicionar
                                                </button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {
                                                this.state.progressives.length !== 0 ?
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Cobrança</th>
                                                                    <th scope="col">Desconto</th>
                                                                    <th scope="col"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.progressives.map(progressivo =>
                                                                        <tr key={progressivo.id}>
                                                                            <td>{toCurrency(progressivo.charge)}</td>
                                                                            <td>{progressivo.discount !== 0 ? toCurrency(progressivo.discount) : <span>{progressivo.porcentage}%</span>}</td>
                                                                            <td width='10'>
                                                                                <button type="button" onClick={(e) => { this.onRemoveProgressivo(progressivo) }} className="btn btn-danger">
                                                                                    <i className="fas fa-trash" />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <EmptyContent text='Nenhum desconto progressivo adicionado.' />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6 my-3">
                                <div className="alert alert-secondary" role="alert">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h4>Manter desconto</h4>
                                            <select className='form-select' value={this.state.manterDesconto && this.state.manterDesconto} onChange={(e) => this.setState({ manterDesconto: e.target.value })}>
                                                <option value='Não'>Não</option>
                                                <option value='Sim'>Sim</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <strong>Não</strong> manter desconto:<br />
                                        Caso o cliente ultrapasse o tempo de tolerância será cobrado o valor total, exemplo:<br />
                                        Tempo de Tolerância: 5 horas<br />
                                        Tempo de permanencia do cliente no estacionamento: 6 horas<br />
                                        <strong>Valor a ser cobrado: 6 hora</strong><p />

                                        <strong>Manter</strong> desconto:<br />
                                        Caso o cliente ultrapasse o tempo de tolerância será cobrado apenas o excedente, exemplo:<br />
                                        Tempo de Tolerância: 5 horas<br />
                                        Tempo de permanencia do cliente no estacionamento: 6 horas<br />
                                        <strong>Valor a ser cobrado: 1 hora</strong><br />
                                        <p />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="btn-group">
                            {
                                this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={this.state.id ? this.update : this.add} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <ListarConvenios key={this.state.random} ShowRemoveButton='true' estacionamentoId={this.state.estacionamentoId} onEdit={this.onEdit} onDuplicate={this.onDuplicate} />
            </NavBar>
        )
    }
}

class ListarConvenios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: 1
        }
        this.load()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onRemove = async (item) => {
        var confirm = window.confirm('Deseja mesmo remover o convênio ' + item.nome + '?');
        if (confirm) {
            await deleteDoc({ collection: "convenios", id: item.id })
            this.load()
            await saveLastUpdate()
        }
    }

    load() {
        this.setState({ loading: 1 });
        firebase.firestore().collection('convenios').where('estacionamentoId', '==', this.props.estacionamentoId).get().then(async (results) => {
            this.setState({ loading: 0 });
            var items = []
            results.docs.forEach(doc => {
                items.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ items: items.sort((a, b) => a.nome.localeCompare(b.nome)) })
        }).catch(error => {
            reloadWithAlertError(error.message)
            this.setState({ loading: 0 });
        })
    }

    render() {
        return (
            <div className="card my-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os convênios
                </div>
                {
                    this.state.items.length === 0 && this.state.loading === 0 ?
                        <EmptyContent text='Nenhum convênio cadastrado' />
                        :
                        this.state.loading === 0 ?
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" width={10}></th>
                                            <th scope="col">Nome</th>
                                            <th scope="col"></th>
                                            <th scope="col" className="d-none d-sm-table-cell">Status</th>
                                            <th scope="col" className="d-none d-sm-table-cell">Visibilidade</th>
                                            <th width="10"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.items.map(item =>
                                                <tr key={item.id}>
                                                    {
                                                        item.tipo === 'Valor' && <td><span className="badge text-bg-success text-white w-100">Valor</span></td>
                                                    }
                                                    {
                                                        item.tipo === 'Porcentagem' && <td><span className="badge text-bg-primary text-white w-100">Porcentagem</span></td>
                                                    }
                                                    {
                                                        item.tipo === 'Progressivo' && <td><span className="badge text-bg-warning text-white w-100">Progressivo</span></td>
                                                    }
                                                    <td>
                                                        <Tooltip role="button" title={item.id} placement="top">
                                                            <span>{item.nome}</span>
                                                        </Tooltip>
                                                    </td>
                                                    {
                                                        item.tipo === 'Valor' && <td>R${item.valor}</td>
                                                    }
                                                    {
                                                        item.tipo === 'Porcentagem' && <td>{item.porcentage}%</td>
                                                    }
                                                    {
                                                        item.tipo === 'Progressivo' && <td>{item.progressives?.length ?? 0} descontos</td>
                                                    }
                                                    <td className="d-none d-sm-table-cell">{item.status}</td>
                                                    <td className="d-none d-sm-table-cell">{item.visibilidade === "ADMINISTRATIVO" ? <span>Aplicável no administrativo</span> : <span>Aplicável no administrativo e aplicativo</span>}</td>
                                                    <td width={10}>
                                                        <div className="btn-group">
                                                            {this.props.onDuplicate &&
                                                                <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                    <i className="fas fa-copy" />
                                                                </button>
                                                            }
                                                            {this.props.onEdit &&
                                                                <button type="button" onClick={() => this.onEdit(item)} className="btn btn-secondary">
                                                                    <i className="fas fa-edit" />
                                                                </button>
                                                            }
                                                            {this.props.ShowRemoveButton === 'true' &&
                                                                <button type="button" onClick={() => this.onRemove(item)} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <Loading />
                }
            </div>
        );
    }
}

export { CriarConvenio, ListarConvenios };
import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';

export const addVerify = async ({ clientId, nome, email, phone, comoConheceu, revendaId }) => {
    const doc = await firebase.firestore().collection('cellphone_verify').add({
        name: nome,
        email: email,
        phone: phone,
        createdAt: new Date(),
        comoConheceu: comoConheceu,
        platform: 'site',
        lastStatus: 'PARK_CREATED',
        lastDateStatus: new Date(),
        revendaId: revendaId ?? getRevenda()?.id,
        status: [{
            status: 'PARK_CREATED', createdAt: new Date()
        }],
        clientId: clientId,
        completedAt: new Date(),
        isDeleted: false,
    })
    return doc.id;
}
import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento, getRevenda } from '../../config/auth'
import { exportAndDownloadXLS, queryString, setPathWindow, toCurrency } from '../../shared/utils'
import { EmptyContent, FilterComponent } from '../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { sendClickButton, sendScreenView } from '../../shared/analytics';
import { getAllMonthly, getAllMonthlyByRangeWithChages } from '../../store/collections/mensalistaWorker';
import NumberFormat from 'react-number-format';
import { getAllChagesCurrentMonth } from '../../store/collections/monthlyChagesWorker';
import { MonthlyChages } from '../mensalista/clientes/detalhes';
import '../dashboard/index.css'
import moment from 'moment';

class ReportMonthly extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: false,
            estacionamentosIds: [],
            estacionamentoId: getEstacionamento()?.id,
            random: Math.random(),
            tickets: [],
            resultado: null,
            allMonthlies: [],
            isOpenMonthliesSelectedModal: false,
            chagesSelected: [],
            modalTitle: "",
            paymentHistory: [],
            loadingHistory: false,
            state: "normal",
            datesNoRepeat: null,
            grouped: null,
            isOpenPaymentsSelectedModal: false,
            agrouped: "dddd",
            allPaymentsWay: [],
            showPaymentCharts: true,
            groupedTable: [],
            allChages: [],
            allChagesMerged: [],
            auxAllMonthlies: [],
            monthliesFromRange: [],
            showModalChages: false,
            monthlySelectedId: "",
            statePayment: "",
            fieldExportStatus: true,
            fieldExportNome: true,
            fieldExportCellphone: true,
            fieldExportDocument: true,
            fieldExportPlanName: true,
            fieldExportDueDate: true,
            fieldExportPrice: true,
            fieldExportDetail: true
        }
    }

    componentDidMount = async () => {
        await this.findAllMonthlies()
        this.findMountData()
        sendScreenView("Relatório", "Mensalistas")
    }

    findMountData() {
        const estacionamentoId = queryString("e")
        const startDate = new Moment(queryString("startDate"))
        const endDate = new Moment(queryString("endDate"))
        if (estacionamentoId !== undefined && startDate.isValid() && endDate.isValid()) {
            this.setState({ estacionamentoId: estacionamentoId })
            this.setState({ startDate: startDate.toDate() })
            this.setState({ endDate: endDate.toDate() }, () => {
                this.loadPaymentHistory()
            })
        }
    }

    findAllMonthlies = async () => {
        const allChagesCurrentMonth = await getAllChagesCurrentMonth()
        const allMothlies = await getAllMonthly()
        for (const change of allChagesCurrentMonth) {
            const monthly = allMothlies.filter(e => e.id === change.monthlyId)
            if (monthly.length > 0) {
                change.monthly = monthly[0]
            }
        }
        this.setState({ allChages: allChagesCurrentMonth })
    }

    filterMonthlies = () => {
        var monthlies = []
        if (this.state.statePayment === "") {
            monthlies = this.state.auxAllMonthlies
        } else if (this.state.statePayment === "pending") {
            monthlies = this.state.auxAllMonthlies.filter(e => e.chages.quantity.pending > 0)
        } else if (this.state.statePayment === "inDay") {
            monthlies = this.state.auxAllMonthlies.filter(e => e.chages.quantity.pending === 0)
        }
        if (monthlies.length > 0) {
            this.setState({ state: "fill" })
            this.setState({ allMonthlies: monthlies })
        } else {
            this.setState({ state: "empty" })
        }
    }

    filterStatePayment = (e) => {
        this.setState({ statePayment: e.target.value }, () => {
            this.filterMonthlies()
        })
    }

    loadPaymentHistory = async () => {
        this.setState({ state: "loading" })
        this.setState({ statePayment: "" })
        // const startDateURL = Moment(this.state.startDate).format("MM/DD/YYYY")
        // const endDateURL = Moment(this.state.endDate).format("MM/DD/YYYY")
        // setPathWindow('/relatorios/mensalistas?e=' + this.state.estacionamentoId + "&startDate=" + startDateURL + "&endDate=" + endDateURL)
        var allMonthlies = await getAllMonthlyByRangeWithChages({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            estacionamentosIds: this.state.estacionamentosIds
        })
        if (allMonthlies.length === 0) {
            this.setState({ state: "empty" })
        } else {
            this.setState({ state: "fill" })
            this.setState({ allMonthlies: allMonthlies })
            this.setState({ auxAllMonthlies: allMonthlies })
        }

        // if (getEstacionamento().id === this.state.estacionamentoId) {
        //     this.setState({ state: "loading" })
        //     this.setState({ historiesEmpty: false })
        //     var newStartDate = Moment(startDateURL).add(-1, 'days').toDate()
        //     var newEndDate = Moment(endDateURL).add(1, 'days').toDate()
        //     try {
        //         // const startDate = Moment().startOf('month').toDate()
        //         // const endDate = Moment().endOf('month').toDate()
        //         // const results = await getAllChagesByDateRange({ startDate: startDate, endDate: endDate })
        //         const results = await getAllChagesByDateRange({ startDate: newStartDate, endDate: newEndDate })
        //         var monthliesFromRange = await getAllMonthlyByDueDateRange({ startDate: newStartDate, endDate: newEndDate, includeChages: true })
        //         this.setState({ monthliesFromRange: monthliesFromRange })
        //         if (results.length > 0 || monthliesFromRange.length > 0) {
        //             this.setState({ paymentHistory: results.sort((a, b) => b.createAt.toDate() - a.createAt.toDate()) }, () => {
        //                 this.applyGroupingDates()
        //                 this.applyGroupingWayPayment()
        //             });
        //             this.setState({ state: "fill" })
        //         } else {
        //             this.setState({ state: "empty" })
        //         }
        //     } catch (error) {
        //         console.log(error);
        //         this.setState({ state: "empty" })
        //     }
        // }
    }

    applyGroupingDates = () => {
        var list = this.state.paymentHistory.filter(e => e.payment).sort((a, b) => b.createAt.toDate() - a.createAt.toDate())
        const dates = list.map(e => Moment(e.payment?.dataPagamento?.toDate()).format(this.state.agrouped))
        const datesNoRepeat = Array.from(new Set(dates)).sort((a, b) => a.localeCompare(b))
        var grouped = []
        var groupedTable = []
        datesNoRepeat.forEach(data => {
            const filtered = list.filter(e => Moment(e.payment?.dataPagamento?.toDate()).format(this.state.agrouped) === data)
            const total = filtered.map(e => e.payment?.total ?? e.netValue ?? 0).reduce((a, b) => a + b, 0)
            grouped.push(total)
            groupedTable.push({
                date: data,
                total: total,
                quantity: filtered.length
            })
        })
        this.setState({ datesNoRepeat: datesNoRepeat })
        this.setState({ groupedTable: groupedTable })
        this.setState({ grouped: grouped }, () => {
            this.setState({ randomPayments: Math.random() })
        })
    }

    applyGroupingWayPayment = () => {
        const list = this.state.paymentHistory.filter(e => e.payment)
        const types = list.map(e => e.payment.order.tipo) //.split(" - ")[1]
        const typesNoRepeat = Array.from(new Set(types)).sort((a, b) => a.localeCompare(b))
        var grouped = []
        typesNoRepeat.forEach(types => {
            const filtered = list.filter(e => e.payment.order.tipo.indexOf(types) !== -1)
            grouped.push({
                type: types?.toUpperCase() ?? "Não identificado",
                total: filtered.map(e => e.payment.total ?? 0).reduce((a, b) => a + b, 0),
                quantity: filtered.length
            })
        })
        this.setState({ allPaymentsWay: grouped })
    }

    optionsPayments = () => {
        if (this.state.datesNoRepeat === null) {
            return {}
        }
        return {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return 'R$' + value.toFixed(2)
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: this.state.datesNoRepeat,
                labels: {
                    formatter: function (value) {
                        return value
                    }
                }
            }
        }
    }

    seriesPayments = () => {
        if (this.state.grouped === null) {
            return []
        }
        return [
            {
                name: "Valor total",
                data: this.state.grouped
            }]
    }

    onApplyFilter = (item) => {
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.loadPaymentHistory()
        })
    }

    monthlyProvidedCashSelected = (chages) => {
        this.showMonthlyModal(chages, "Todos os pagamentos previstos este mês")
    }

    monthlyDefaultersSelected = (monthlies) => {
        this.showMonthlyModal(monthlies, "Todos os inadimplentes do mês")
    }

    showMonthlyModal = (chages, title) => {
        this.setState({ isOpenMonthliesSelectedModal: true })
        this.setState({ chagesSelected: chages.sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate()) })
        this.setState({ modalTitle: title })
    }

    showMonthlyExportFieldsModal = () => {
        this.setState({ isOpenMonthlyExportFieldsModal: true })
    }

    hideMonthlyExportFieldsModal = () => {
        this.setState({ isOpenMonthlyExportFieldsModal: false })
    }

    hideMonthlyModal = () => {
        this.setState({ isOpenMonthliesSelectedModal: false })
    }

    exportData = () => {
        const revenda = getRevenda()
        const rows = this.state.allChages.map(e => {
            var data = {}
            data.STATUS = e.status === "payed" ? "Pago" : e?.changeDate.toDate() > new Date() ? "Cobrança gerada" : "Pendente"
            if (this.state.fieldExportNome) {
                data.NOME = e.clienteNome
            }
            if (this.state.fieldExportCellphone) {
                data.CELULAR = e.monthly.celular ?? "-"
            }
            if (this.state.fieldExportDocument) {
                data.DOCUMENTO = String(e.monthly.documento)?.replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") ?? "-"
            }
            if (this.state.fieldExportPlanName) {
                data.PLANO = e.plan?.nome ?? "-"
            }
            if (this.state.fieldExportDueDate) {
                data.VENCIMENTO = Moment(e?.changeDate.toDate()).format("DD/MM/YYYY")
            }
            if (this.state.fieldExportPrice) {
                data.VALOR = e.status === "payed" ? (e?.netValue ?? 0).toFixed(2) : (e?.plan?.valor ?? 0).toFixed(2)
            }
            if (this.state.fieldExportDetail) {
                data.DETALHES = `${revenda.siteAdmin}/visualizar/mensalista/${e.monthlyId}`
            }
            return data
        })
        exportAndDownloadXLS(rows, `COBRANÇAS_MENSALISTAS`)
        sendClickButton("Relatório", "Tocou em exportar dados dos mensalistas", null, JSON.stringify(rows))
    }

    handleAgrouped = (e) => {
        this.setState({ agrouped: e.target.value }, () => {
            this.applyGroupingDates()
        })
    }

    showPaymentsModal = () => {
        this.setState({ isOpenMonthliesSelectedModal: true })
        this.setState({ chagesSelected: this.state.paymentHistory.sort((a, b) => b.changeDate.toDate() - a.changeDate.toDate()) })
        this.setState({ modalTitle: "Dados analisados" })
    }

    hidePaymentModal = () => {
        this.setState({ isOpenPaymentsSelectedModal: false })
    }

    openPaymentHistory = (item) => {
        this.setState({ monthlySelectedId: item.id }, () => {
            this.setState({ showModalChages: true })
        })
    }

    closePaymentHistory = () => {
        this.setState({ showModalChages: false })
    }

    exportAllMonthly = () => {
        const revenda = getRevenda()
        const rows = this.state.allMonthlies.map(e => {
            return {
                "Nome": e.nome,
                "Celular": e.celular ?? "-",
                "Plano": e.plano?.nome ?? "-",
                "Valor do plano": e.plano?.valor,
                "Vagas": e.vagas ?? "1",
                "Status": e.chages.quantity.pending === 0 ? "Em dia" : "Pendente",
                "Vencimento": Moment(e.plano?.dataFatura.toDate()).format("DD"),
                "Cobranças pagas": e.chages.quantity.payed,
                "Total cobranças pagas": e.chages.quantity.payedTotal.toFixed(2),
                "Cobranças pendentes": e.chages.quantity.pending,
                "Total cobranças pendentes": e.chages.quantity.pendingTotal.toFixed(2),
                "Cobranças geradas": e.chages.quantity.overdue,
                "Total cobranças geradas": e.chages.quantity.overdueTotal.toFixed(2),
                "Valor total": e.chages.quantity.changesTotal.toFixed(2),
                "Detalhes": `${revenda.siteAdmin}/visualizar/mensalista/${e.id}`
            }
        })
        exportAndDownloadXLS(rows, `MENSALISTAS_EXPORTADOS`)
        sendClickButton("Relatório", "Tocou em exportar mensalistas do relatório", null, JSON.stringify(rows))
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <ReportMonthlyAllCard key={this.state.allMonthlies.length + 1}
                                onSelected={this.monthlyAllCardSelected}
                                monthlies={this.state.allMonthlies} />
                        </div>
                        <div className='col-lg-4'>
                            <ReportMonthlyProvidedCash key={this.state.allChages.length + 2}
                                onSelected={this.monthlyProvidedCashSelected}
                                chages={this.state.allChages} />
                        </div>
                        <div className='col-lg-4'>
                            <ReportMonthlyDefaulters key={this.state.allChages.length + 3}
                                onSelected={this.monthlyDefaultersSelected}
                                chages={this.state.allChages} />
                        </div>
                    </div>
                    <FilterComponent onApplyFilter={this.onApplyFilter} />
                    <div className="col-lg-12 card my-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            {
                                this.state.allMonthlies.length === 0 && <span>Mensalistas</span>
                            }
                            {
                                this.state.allMonthlies.length === 1 && <span>1 Mensalista</span>
                            }
                            {
                                this.state.allMonthlies.length > 0 && <span>{this.state.allMonthlies.length} Mensalistas</span>
                            }
                            {
                                this.state.allMonthlies.length > 0 &&
                                <div className='col-lg-3'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <select className='form-select form-select-sm w-100' value={this.state.statePayment} onChange={this.filterStatePayment}>
                                                <option value="">Todos</option>
                                                <option value="pending">Pendentes</option>
                                                <option value="inDay">Em dia</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-4'>
                                            <button type="button" onClick={this.exportAllMonthly} className="btn btn-sm btn-success w-100">
                                                <i className="fas fa-table mx-2" />
                                                Exportar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            this.state.state === "loading" &&
                            <EmptyContent text="Carregando informações..." />
                        }
                        {
                            this.state.state === "normal" &&
                            <EmptyContent text="Selecione uma data e aplique o filtro." />
                        }
                        {
                            this.state.state === "empty" &&
                            <EmptyContent text="Nenhum resultado encontrado com este filtro." />
                        }
                        {
                            this.state.state === "fill" &&
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td className="d-none d-sm-table-cell" align='center' width={10}><strong>Status</strong></td>
                                        <td><strong>Nome</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Celular</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Plano</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Valor</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Vagas</strong></td>
                                        <td align='center' width={10}><strong>Vencimento</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Pagas</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Pendentes</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Geradas</strong></td>
                                        <td className="d-none d-sm-table-cell" align='center'><strong>Valor total</strong></td>
                                        <td width={10}></td>
                                    </tr>
                                </thead>
                                <tbody className='table-responsive'>
                                    {
                                        this.state.allMonthlies.map((item, index) =>
                                            <tr key={index}>
                                                {
                                                    item.chages.quantity.pending === 0 ?
                                                        <td align='center'><span className="badge text-bg-success w-100">Em dia</span> </td> :
                                                        <td align='center'><span className="badge text-bg-danger w-100">Pendente</span></td>
                                                }
                                                <td>{item.nome}</td>
                                                <td className="d-none d-sm-table-cell" align='center'>{item.celular ?? "-"}</td>
                                                <td className="d-none d-sm-table-cell" align='center'>{item?.plano?.nome ?? "-"}</td>
                                                <td className="d-none d-sm-table-cell" align='center'>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item?.plano?.valor ?? 0} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</td>
                                                <td className="d-none d-sm-table-cell" align='center'>{item?.vagas ?? "1"}</td>
                                                {
                                                    item?.plano?.dataFatura.toDate() > new Date() ?
                                                        <td align='center'>{Moment(item?.plano?.dataFatura.toDate()).format("DD/MM/YYYY")}</td> :
                                                        <td align='center'>{Moment(item?.plano?.dataFatura.toDate()).format("DD/MM/YYYY")}</td>
                                                }
                                                <td className="d-none d-sm-table-cell bg-success text-white" align='center'>( {item.chages.quantity.payed} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.chages.quantity.payedTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</td>
                                                <td className="d-none d-sm-table-cell bg-danger text-white" align='center'>( {item.chages.quantity.pending} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.chages.quantity.pendingTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</td>
                                                <td className="d-none d-sm-table-cell bg-secondary text-white" align='center'>( {item.chages.quantity.overdue} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.chages.quantity.overdueTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</td>
                                                <td className="d-none d-sm-table-cell bg-info text-white" align='center'>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.chages.quantity.changesTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button type="button" onClick={() => { this.openPaymentHistory(item) }} className="btn btn-success">
                                                            <i className="fas fa-sack-dollar" />
                                                        </button>
                                                        <Link to={'/visualizar/mensalista/' + item.id} target='_blank' className="btn btn-primary">
                                                            <i className="fas fa-eye" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                <tfoot>
                                    <td><h5>Totais</h5></td>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td className="d-none d-sm-table-cell bg-success text-white" align='center'><h6><strong>( {this.state.allMonthlies.map(e => e.chages.quantity.payed).reduce((a, b) => a + b, 0)} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.allMonthlies.map(e => e.chages.quantity.payedTotal).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</strong></h6></td>
                                    <td className="d-none d-sm-table-cell bg-danger text-white" align='center'><h6><strong>( {this.state.allMonthlies.map(e => e.chages.quantity.pending).reduce((a, b) => a + b, 0)} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.allMonthlies.map(e => e.chages.quantity.pendingTotal).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</strong></h6></td>
                                    <td className="d-none d-sm-table-cell bg-secondary text-white" align='center'><h6><strong>( {this.state.allMonthlies.map(e => e.chages.quantity.overdue).reduce((a, b) => a + b, 0)} ) {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.allMonthlies.map(e => e.chages.quantity.overdueTotal).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</strong></h6></td>
                                    <td className="d-none d-sm-table-cell bg-info text-white" align='center'><h6><strong>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.allMonthlies.map(e => e.chages.quantity.changesTotal).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</strong></h6></td>
                                </tfoot>
                            </table>
                        }
                    </div>
                    {
                        this.state.showModalChages && <MonthlyChages monthlyId={this.state.monthlySelectedId} onClose={this.closePaymentHistory} />
                    }
                    <Dialog onClose={this.hideMonthlyModal} maxWidth='lg' fullWidth open={this.state.isOpenMonthliesSelectedModal}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.modalTitle}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.chagesSelected.length !== 0 ?
                                    <div className='col-lg-12'>
                                        <table className="table table-responsive table-striped">
                                            <thead>
                                                <tr>
                                                    <td width={30} align='center'><strong>Status</strong></td>
                                                    <td align='center'><strong>Cliente</strong></td>
                                                    <td align='center'><strong>Vencimento</strong></td>
                                                    <td align='center'><strong>Data pagamento</strong></td>
                                                    <td align='center'><strong>Valor cobrado</strong></td>
                                                    <td align='center'><strong>Valor pago</strong></td>
                                                    <td width={30} align='center'></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.chagesSelected.sort((a, b) => b.status === "payed" ? 1 : -1).map((item, index) =>
                                                        <>
                                                            {
                                                                (item.status === undefined || item.status === "payed") &&
                                                                <tr key={index}>
                                                                    <td align='center'><span className="badge text-bg-success w-100">Pago</span></td>
                                                                    <td align='center'>{item.clienteNome}</td>
                                                                    <td align='center'>{Moment(item.payment?.dataFatura?.toDate()).format("DD/MM/YYYY")}</td>
                                                                    <td align='center'>{Moment(item.createAt?.toDate()).format("DD/MM/YYYY")}</td>
                                                                    <td align='center'>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.netValue ?? item.payment.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div>{value}</div>} />}</td>
                                                                    <td align='center'>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.payment.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div>{value}</div>} />}</td>
                                                                    <td>
                                                                        <a href={'/visualizar/mensalista/' + item.monthlyId} target='_blank' className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                item.status === "pending" &&
                                                                <tr key={index}>
                                                                    {
                                                                        (item.changeDate.toDate() < new Date()) ? <td align='center'><span className="badge text-bg-danger btn-block w-100">Pendente</span></td> :
                                                                            <td align='center'><span className="badge text-bg-secondary btn-block w-100">Cobrança gerada</span></td>
                                                                    }
                                                                    <td align='center'>{item.clienteNome}</td>
                                                                    <td align='center'>{Moment(item.changeDate.toDate()).format("DD/MM/YYYY")}</td>
                                                                    <td align='center'>-</td>
                                                                    <td align='center'>{<NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.netValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <div>{value}</div>} />}</td>
                                                                    <td align='center'>-</td>
                                                                    <td>
                                                                        <a href={'/visualizar/mensalista/' + item.monthlyId} target='_blank' className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div> : <EmptyContent text="Nenhum resultado disponível" />
                            }
                        </MuiDialogContent>
                        {
                            this.state.chagesSelected.length !== 0 &&
                            <MuiDialogActions>
                                <div className='m-3 btn-group'>
                                    <button type="button" onClick={this.showMonthlyExportFieldsModal} className="btn btn-sm btn-success">
                                        <i className="fas fa-table mx-2" />
                                        Exportar
                                    </button>
                                    <button onClick={this.hideMonthlyModal} className='btn btn-sm btn-primary'>Fechar</button>
                                </div>
                            </MuiDialogActions>
                        }
                    </Dialog>
                    <Dialog onClose={this.hideMonthlyExportFieldsModal} maxWidth='xs' fullWidth open={this.state.isOpenMonthlyExportFieldsModal}>
                        <MuiDialogTitle className='text-center'>
                            Escolha os campos que deseja no relatório
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" disabled checked={this.state.fieldExportStatus} onChange={(e) => this.setState({ fieldExportStatus: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" disabled>
                                    Status ( Pago, Pendente ou cobrança gerada )
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportNome" checked={this.state.fieldExportNome} onChange={(e) => this.setState({ fieldExportNome: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportNome">
                                    Nome do mensalista
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportCellphone" checked={this.state.fieldExportCellphone} onChange={(e) => this.setState({ fieldExportCellphone: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportCellphone">
                                    Celular
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportDocument" checked={this.state.fieldExportDocument} onChange={(e) => this.setState({ fieldExportDocument: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDocument">
                                    Documento
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportPlanName" checked={this.state.fieldExportPlanName} onChange={(e) => this.setState({ fieldExportPlanName: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportPlanName">
                                    Nome do plano
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportDueDate" checked={this.state.fieldExportDueDate} onChange={(e) => this.setState({ fieldExportDueDate: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDueDate">
                                    Data de vencimento
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportPrice" checked={this.state.fieldExportPrice} onChange={(e) => this.setState({ fieldExportPrice: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportPrice">
                                    Valor
                                </label>
                            </div> <br />
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="fieldExportDetail" checked={this.state.fieldExportDetail} onChange={(e) => this.setState({ fieldExportDetail: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDetail">
                                    Detalhes ( URL )
                                </label>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='m-3 btn-group'>
                                <button type="button" onClick={this.exportData} className="btn btn-success">
                                    Confirmar
                                </button>
                                <button onClick={this.hideMonthlyExportFieldsModal} className='btn btn-primary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar>
            </>
        )
    }
}

class ReportMonthlyAllCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            monthlies: props.monthlies,
            allMonthlies: props.monthlies.length,
            allExpiratedMonthlies: props.monthlies.filter(e => e.plano?.validadeContrato?.toDate() < new Date()).length
        }
    }

    onSelected = () => {
        this.props.onSelected(this.state.monthlies)
    }

    render() {
        return (
            <>
                <a href='/buscar/mensalistas' target='_blank'>
                    <div className="card text-light bg-success mb-3" role="button">
                        <div className="card-body">
                            <h5 className="card-title text-center text-white">Mensalistas filtrados</h5>
                            <h3 className="card-text text-center text-white">
                                {
                                    this.state.monthlies.length !== 0 ?
                                        <span>{this.state.allMonthlies}</span>
                                        : <span>-</span>
                                }
                            </h3>
                            {
                                this.state.monthlies.length !== 0 &&
                                <h5 className='monthly-card monthly-card-green'>
                                    {
                                        this.state.allExpiratedMonthlies === 0 && <span>Nenhum expirado</span>
                                    }
                                    {
                                        this.state.allExpiratedMonthlies === 1 && <span>1 Expirado</span>
                                    }
                                    {
                                        this.state.allExpiratedMonthlies > 1 && <span>{this.state.allExpiratedMonthlies} Expirados</span>
                                    }
                                </h5>
                            }
                            <div className="text-center">
                                <small className="card-text text-white">Ativos</small>
                            </div>
                        </div>
                    </div>
                </a>
            </>)
    }
}

class ReportMonthlyProvidedCash extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            chages: props.chages,
            providedCashMonth: props.chages.map(e => isNaN(e.netValue) ? 0 : parseFloat(e.netValue ?? 0)).reduce((a, b) => a + b, 0),
            payed: props.chages.filter(e => e.payment).map(e => isNaN(e.payment.total) ? 0 : parseFloat(e.payment.total ?? 0) ?? 0).reduce((a, b) => a + b, 0),
        }
    }

    onSelected = () => {
        this.props.onSelected(this.state.chages)
    }

    render() {
        return (
            <>
                <a onClick={this.onSelected}>
                    <div className="card text-light bg-primary mb-3" role="button">
                        <div className="card-body">
                            <h5 className="card-title text-center text-white">Previsto no mês</h5>
                            <h3 className="card-text text-center text-white">
                                {
                                    this.state.chages.length !== 0 ? <span>{toCurrency(this.state.providedCashMonth)}</span> : <span>-</span>
                                }
                            </h3>
                            <h5 className='monthly-card monthly-card-blue'>
                                {toCurrency(this.state.payed)}
                                <span className='mx-1'>
                                    Já pago
                                </span>
                            </h5>
                            <div className="text-center">
                                <small className="card-text text-white">Previsão</small>
                            </div>
                        </div>
                    </div>
                </a>
            </>)
    }
}

class ReportMonthlyDefaulters extends React.Component {

    constructor(props) {
        super(props)
        const defaulters = props.chages.filter(e => !e.payment && moment(e.changeDate.seconds * 1000).isBefore(moment().subtract(1, 'days')))

        var defaultersPercent = 0.0
        if (defaulters.length > 0 && props.chages.length > 0) {
            defaultersPercent = 100 - (((props.chages.length - defaulters.length) / props.chages.length) * 100)
        }
        this.state = {
            total: defaulters.map(e => isNaN(e.netValue) ? 0 : parseFloat(e.netValue ?? 0)).reduce((a, b) => a + b, 0),
            chages: defaulters,
            defaultersTotal: defaulters.length,
            defaultersPercent: defaultersPercent.toFixed(2)
        }
    }

    onSelected = () => {
        this.props.onSelected(this.state.chages)
    }

    render() {
        return (
            <>
                <a onClick={this.onSelected}>
                    <div className="card text-light bg-danger mb-3" role="button">
                        <div className="card-body">
                            <h5 className="card-title text-center text-white">Inadimplentes</h5>
                            <h3 className="card-text text-center text-white">
                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                            </h3>
                            {
                                <h5 className='monthly-card monthly-card-red'> {this.state.defaultersPercent}% </h5>
                            }
                            {
                                this.state.chages.length === 0 &&
                                <div className="text-center">
                                    <small className="card-text text-white">{
                                        <span>pagamentos Pendentes</span>
                                    }</small>
                                </div>
                            }
                            {
                                this.state.chages.length === 1 &&
                                <div className="text-center">
                                    <small className="card-text text-white">{
                                        this.state.chages.length !== 0 ? <span>{this.state.defaultersTotal} Pagamento Pendente</span> : <span>-</span>
                                    }</small>
                                </div>
                            }
                            {
                                this.state.chages.length > 1 &&
                                <div className="text-center">
                                    <small className="card-text text-white">
                                        {
                                            <span>{this.state.defaultersTotal} Pagamentos Pendentes</span>
                                        }
                                    </small>
                                </div>
                            }
                        </div>
                    </div>
                </a>
            </>)
    }
}

class ReportMonthlyAllPayments extends React.Component {

    constructor(props) {
        super(props)
        const payments = props.payments.filter(e => e.payment)
        this.state = {
            total: payments.map(e => e.payment.total).reduce((a, b) => a + b, 0),
            quantity: payments.length
        }
    }

    render() {
        return (
            <>
                <div className="card text-light bg-success mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Total de pagamentos</h5>
                        <h3 className="card-text text-center text-white">
                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                        </h3>
                        <div className="text-center">
                            {
                                this.state.quantity === 0 && <small className="card-text text-white">Nenhum registrados</small>
                            }
                            {
                                this.state.quantity === 1 && <small className="card-text text-white">{this.state.quantity} pagamento registrado</small>
                            }
                            {
                                this.state.quantity > 1 && <small className="card-text text-white">{this.state.quantity} pagamentos registrados</small>
                            }
                        </div>
                    </div>
                </div>
            </>)
    }
}

export { ReportMonthly };
import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getUsuarioCompact, isAdministrador, getRevendaIdByPark, getUsuario } from '../../config/auth'

export const getTicketSettings = async ({ plate }) => {
    if (plate === '') {
        return null
    }
    const estacionamentoId = getEstacionamento().id ?? ""
    const result = await firebase.firestore().collection('ticketSettings')
        .where("estacionamentoId", "==", estacionamentoId)
        .where("plate", "==", plate).get()
    if (result.empty) {
        return null
    }
    return { ...result.docs[0].data(), id: result.docs[0].id };
}

export const updateTicketSettings = async ({ plate, allowToPark, allowToParkReason }) => {
    const clienteId = getCliente().id
    const user = getUsuarioCompact()
    const settings = await getTicketSettings({ plate: plate })
    if (settings) {
        await firebase.firestore().collection('ticketSettings').doc(settings.id).update({
            allowToPark: allowToPark,
            allowToParkReason: allowToParkReason,
            plate: plate,
            estacionamentoId: getEstacionamento().id ?? "",
            alterAt: new Date(),
            alterBy: user,
            isDeleted: false,
        })
    } else {
        await firebase.firestore().collection('ticketSettings').add({
            allowToPark: allowToPark,
            allowToParkReason: allowToParkReason,
            plate: plate,
            estacionamentoId: getEstacionamento().id ?? "",
            revendaId: getRevendaIdByPark(),
            clienteId: clienteId,
            createAt: new Date(),
            createBy: user,
            isDeleted: false,
        })
    }
}
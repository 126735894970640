import React from 'react'
import { getParameterByParkId } from "../../store/collections/parameterWorker"
import { PrinterHeaderComponent, BodyTicketInComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, MessageInTicketComponent, PrinterQRCodeComponent, PrinterFooterComponent } from "./printer-manager"

class PrinterTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket,
            park: props.park,
        }
        console.log(this.state)
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        <BodyTicketInComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterInternalReviewComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterProductsComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterPaymentsComponent ticket={this.state.ticket} />
                        <PrinterQRCodeComponent ticket={this.state.ticket} parameters={this.state.parameters} />
                        <MessageInTicketComponent parameters={this.state.parameters} position={"fim"} />
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

export { PrinterTicketComponent }
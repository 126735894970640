import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllAccreditedTypes = async () => {
    var results = await firebase.firestore().collection("credenciados-tipos").where("clienteId", '==', getCliente().id).get()
    return docsToItems(results).sort((a, b) => a.name.localeCompare(b.name))
}

export const getAccreditedTypeByName = async (name) => {
    var results = await firebase.firestore().collection("credenciados-tipos").where("clienteId", '==', getCliente().id).where("name", "==", name).get()
    return docsToItem(results)
}

export const removeAccreditedType = async (id) => {
    await deleteDoc({ collection: "credenciados-tipos", id: id })
}

export const saveAccreditedType = async (name) => {
    await firebase.firestore().collection("credenciados-tipos").add({
        clienteId: getCliente()?.id,
        estacionamentoId: getEstacionamento()?.id,
        revendaId: getRevendaIdByPark(),
        createdBy: getUsuario()?.nome,
        name: name,
        createdAt: new Date(),
        isDeleted: false,
    })
}
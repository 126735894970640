import { getCliente, getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { generateUUID, getResellerIdByEnv } from '../../shared/utils';
import { docsToItems } from '../transform.docs';

export const addTermsRead = async ({ termsId, info }) => {
    const user = getUsuarioCompact()
    const cliente = getCliente()
    await firebase.firestore().collection('usuarios-termos').add({
        revendaId: getResellerIdByEnv(),
        clienteId: cliente.id,
        createdBy: user,
        createdAt: new Date(),
        termsId: termsId,
        guid: generateUUID(),
        info: info,
        isDeleted: false,
    })
}

export const getTermsByRevendaId = async ({ revendaId }) => {
    const results = await firebase.firestore().collection('usuarios-termos').where("revendaId", "==", revendaId).get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getAllTerms = async () => {
    const results = await firebase.firestore().collection('usuarios-termos').get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}
import React from 'react'
import { NavBar } from '../../../components/navbar/index.js'
import { Loading, EmptyContent } from '../../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getEstacionamento } from '../../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { getRemoteConfigByKey } from '../../../store/collections/remoteConfigWorker.js';
import { TicketListModal } from '../../ticket/index.js';
import { getAllCamerasLPRByParkId } from '../../../store/collections/cameraLPRWorker.js';
import { CancelaCameraLPRPreview } from '../lpr/index.js';
import { AlertWarning } from '../../../shared/alert-manager.js';
import { deleteDevice, getAllDevicesByParkId, updateDevice } from '../../../store/collections/deviceWorker.js';
import { isNullOrEmpty, takeIfIsNotNullOrEmpty } from '../../../shared/utils.js';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker.js';

class CancelaListarAparelhos extends React.Component {

    render() {
        return (
            <NavBar>
                <CancelaListarAparelhosBody />
            </NavBar>
        )
    }
}

class CancelaListarAparelhosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async () => {
        this.setState({ isLoading: true })
        var items = await getAllDevicesByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allEmergencyModel: items.filter(e => e.emergency === true).length === items.length })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    removeDevice = async (item) => {
        var confirm = window.confirm(`Confirma mesmo a remoção deste aparelho?`);
        if (confirm) {
            await deleteDevice({ id: item.id })
            await this.load()
        }
    }

    editPrinter = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ printerName: item.printerName ?? "" })
        this.setState({ isShowingPrinterModal: true })
    }

    savePrinter = async () => {
        const item = this.state.itemSelected
        item.printerName = this.state.printerName
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingPrinterModal: false })
        saveLastUpdate()
        await this.load()
    }

    editTEF = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ tefName: item.tefName ?? "" })
        this.setState({ isShowingTEFModal: true })
    }

    saveTEF = async () => {
        const item = this.state.itemSelected
        item.tefName = this.state.tefName
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingTEFModal: false })
        saveLastUpdate()
        await this.load()
    }

    editUseMode = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ useMode: item.useMode ?? "" })
        this.setState({ isShowingUseMode: true })
    }

    saveUseMode = async () => {
        const item = this.state.itemSelected
        item.useMode = this.state.useMode
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingUseMode: false })
        saveLastUpdate()
        await this.load()
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Aparelhos
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Faça login em um aparelho e ele aparecerá aqui.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td align='center' width='10' />
                                                        <td><strong>Modelo</strong></td>
                                                        <td><strong>Usuário logado</strong></td>
                                                        <td><strong>Bateria</strong></td>
                                                        <td><strong>TEF</strong></td>
                                                        {/* <td><strong>Impressora</strong></td> */}
                                                        <td><strong>Modo de uso</strong></td>
                                                        <td align='right' ><strong>Atualizado</strong></td>
                                                        <td align='right' />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td className="d-none d-sm-table-cell" align='left' width={10}>
                                                                    <span className="badge text-bg-success mx-2 w-100">
                                                                        <span> {item.version} | </span>
                                                                        {item.flavor?.includes("pagSeguro") && ('PagSeguro')}
                                                                        {item.flavor?.includes("cielo") && ('Cielo')}
                                                                        {item.flavor?.includes("getnet") && ('GetNet')}
                                                                        {item.flavor?.includes("Stone") && ('Stone')}
                                                                        {item.flavor?.includes("safra") && ('Safra')}
                                                                        {item.flavor?.includes("store") && ('Google Play')}
                                                                        {item.flavor?.includes("rede") && ('Rede')}
                                                                        {item.flavor?.includes("site") && 'Site'}
                                                                    </span>
                                                                </td>
                                                                <td>{item.model ?? "Não informado"}</td>
                                                                <td>{item.currentUser?.nome ?? "Não informado"}</td>
                                                                <td>{item.batery != "-" ? <span>{item.batery}%</span> : "Não disponível"}</td>
                                                                <td>{takeIfIsNotNullOrEmpty(item.tefName) ?? "Padrão aparelho"}</td>
                                                                {/* <td>{takeIfIsNotNullOrEmpty(item.printerName) ?? "Padrão aparelho"}</td> */}
                                                                <td>
                                                                    <span>{!item.useMode && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "SALES" && "Vendas"}</span>
                                                                    <span>{item.useMode === "PARK" && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "NOTES" && "Emissor de notas"}</span>
                                                                    <span>{item.useMode === "TOTEM" && "Auto atendimento"}</span>
                                                                    <span>{item.useMode === "AUTHENTICATOR" && "Autenticador"}</span>
                                                                </td>
                                                                <td align='right'>{Moment(item.updatedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        {
                                                                            item.flavor?.includes("store") && <>
                                                                                <Tooltip title="Editar TEF" placement="top">
                                                                                    <button className='btn btn-primary' onClick={(e) => this.editTEF(item)}>
                                                                                        <i className="fa-solid fa-cash-register" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                {/* <Tooltip title="Editar impressora" placement="top">
                                                                                    <button className='btn btn-primary' onClick={(e) => this.editPrinter(item)}>
                                                                                        <i className="fa-solid fa-print" />
                                                                                    </button>
                                                                                </Tooltip> */}
                                                                            </>
                                                                        }
                                                                        <Tooltip title="Editar modo de uso" placement="top">
                                                                            <button className='btn btn-primary' onClick={(e) => this.editUseMode(item)}>
                                                                                <i className="fa-solid fa-user-tie" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Remover aparelho" placement="top">
                                                                            <button className='btn btn-danger' onClick={(e) => this.removeDevice(item)}>
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingPrinterModal} onClose={(e) => this.setState({ isShowingPrinterModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar impressora
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Software de impressão</label>
                            <select className="form-select" value={this.state.printerName} onChange={(e) => this.setState({ printerName: e.target.value })}>
                                <option value="">Padrão aparelho</option>
                                <option value="SK210">SK210</option>
                                <option value="POSTech">POSTech</option>
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingPrinterModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.savePrinter()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingTEFModal} onClose={(e) => this.setState({ isShowingTEFModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar TEF
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Software TEF</label>
                            <select className="form-select" value={this.state.tefName} onChange={(e) => this.setState({ tefName: e.target.value })}>
                                <option value="">Padrão aparelho</option>
                                <option value="Destaxa">Destaxa</option>
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingTEFModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveTEF()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingUseMode} onClose={(e) => this.setState({ isShowingUseMode: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar modo de uso
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Modo de uso</label>
                            <select className="form-select" value={this.state.useMode} onChange={(e) => this.setState({ useMode: e.target.value })}>
                                <option value="PARK">Estacionamento</option>
                                <option value="SALES">Vendas</option>
                                <option value="NOTES">Emissor de notas</option>
                                <option value="TOTEM">Auto atendimento</option>
                                {/* <option value="AUTHENTICATOR">Autenticador</option> */}
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingUseMode: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveUseMode()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }
}

export { CancelaListarAparelhos }
import moment from 'moment';
import firebase from '../../config/firebase';
import { docsToItems, docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getNewNotesByParkId = async ({ parkId, days, status, type }) => {
    var query = firebase.firestore().collection('newNotes')
    var date = new Date();
    date.setDate(date.getDate() - days);
    query = query.where('createdAt', '>=', date).where('estacionamentoId', '==', parkId).orderBy("createdAt", "desc")
    if (status) {
        query = query.where('currentStatus', '==', status)
    }
    if (type) {
        query = query.where('type', '==', type)
    }
    const results = await query.get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getAllGeneratedNotesInCurrentMonth = async ({ parkId }) => {
    try {
        const startOfMonth = moment().startOf('month')
        const endOfMonth = moment().endOf('month')
        const results = await firebase.firestore().collection('newNotes')
            .where('createdAt', '>=', startOfMonth.toDate())
            .where('createdAt', '<=', endOfMonth.toDate())
            .where('estacionamentoId', '==', parkId).get()
        return docsToItems(results)
    } catch (error) {
    }
}

export const getAllByRessaleId = async ({ ressaleId, limit }) => {
    try {
        // .where('revendaId', '==', ressaleId)
        var results = await firebase.firestore().collection('newNotes').orderBy("createdAt", "desc").limit(limit ?? 5).get()
        return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
    } catch (error) {
        return []
    }
}

export const getAllChargeNotesByRessaleId = async ({ ressaleId }) => {
    var results = await firebase.firestore().collection('newNotes').where('revendaId', '==', ressaleId).where('type', '==', "cobranca").get()
        return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getNewNoteById = async ({ id }) => {
    var results = await firebase.firestore().collection('newNotes').doc(id).get()
    return docToItem(results)
}

export const updateNewNoteById = async ({ id, data }) => {
    await firebase.firestore().collection('newNotes').doc(id).update(data)
}

export const deleteNewNoteById = async ({ id }) => {
    await deleteDoc({ collection: "newNotes", id: id })
}

export const addNewNote = async ({ data }) => {
    data.origin = "admin"
    const doc = await firebase.firestore().collection('newNotes').add(data)
    data.id = doc.id
    return data
}
import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { docToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addProfileFilled = async ({ clienteId, clienteNome, estacionamentoId, revendaId }) => {
    const doc = await firebase.firestore().collection('perfis').add({
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        nome: "Administrativo",
        cliente: clienteNome,
        createClient: true,
        removeMonthly: true,
        removeMonthlyVehicle: true,
        revendaId: revendaId ?? getRevenda()?.id,
        createProfile: true,
        createUser: true,
        createPlan: true,
        createProduct: true,
        createPartnership: true,
        accessWebSite: true,
        createPaymentTable: true,
        createWayPayment: true,
        habilitarDescontoManual: true,
        changeWayPayments: true,
        changeParkOfTicket: true,
        changeTypeVehicleOfTicket: true,
        changePaymentTableOfTicket: true,
        habilitarFecharCaixa: true,
        habilitarAbrirCaixa: true,
        habilitarPagamentoTicket: true,
        habilitarPagamentoMensalista: true,
        habilitarExtornoPagamento: true,
        habilitarCancelarTicket: true,
        notaFiscalVisualizar: true,
        fecharCaixaCego: false,
        notaFiscalExportar: true,
        notaFiscalConfigurar: true,
        habilitarRemoverSangria: true,
        habilitarRemoverMovimentacaoDeCaixa: true,
        habilitarEmissaoRelatorioCaixasFechados: true,
        notaPermitirEmitirNoAplicativo: true,
        isDeleted: false,
    })
    return doc.id;
}

export const getAllProfilesByClientId = async ({ clientId }) => {
    const result = await firebase.firestore().collection('perfis').where('clienteId', '==', clientId).get()
    return docsToItems(result)
}

export const insertProfile = async ({ data }) => {
    await firebase.firestore().collection('perfis').add(data)
}

export const updateProfile = async ({ id, data }) => {
    await firebase.firestore().collection('perfis').doc(id).update(data)
}

export const deleteProfile = async ({ id }) => {
    await deleteDoc({ collection: "perfis", id: id })
}

export const getProfileById = async ({ id }) => {
    const result = await firebase.firestore().collection('perfis').doc(id).get()
    return docToItem(result)
}
import firebase from '../../config/firebase';
import { docsToItem, docsToItems } from '../transform.docs';

export const getRemotesConfig = async () => {
    const result = await firebase.firestore().collection('remote-config').get()
    return docsToItems(result)
}

export const getRemoteConfigByKey = async ({ key }) => {
    const result = await firebase.firestore().collection('remote-config').where("key", "==", key).get()
    return docsToItem(result)
}
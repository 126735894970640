import { getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addVehicle = async ({ clientId, mensalistaId, placa, modelo, marca, cor, cartao }) => {
    await firebase.firestore().collection('veiculos').add({
        clienteId: clientId,
        estacionamentoId: getEstacionamento()?.id,
        mensalistaId: mensalistaId,
        placa: placa.replaceAll("-", "").toUpperCase(),
        modelo: modelo,
        revendaId: getRevendaIdByPark(),
        marca: marca,
        cartao: String(cartao),
        cor: cor,
        createdAt: new Date(),
        createdBy: getUsuario().email,
        isDeleted: false,
    })
}

export const updateVehicleById = async ({ id, data }) => {
    await firebase.firestore().collection('veiculos').doc(id).update(data)
}

export const getAllVehiclesByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('veiculos').where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehiclesByMonthlyId = async ({ monthlyId }) => {
    const result = await firebase.firestore().collection('veiculos').where('mensalistaId', '==', monthlyId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehiclesByMonthlyIdAndClientId = async ({ monthlyId, clienteId }) => {
    const result = await firebase.firestore().collection('veiculos').where('mensalistaId', '==', monthlyId).where('clienteId', '==', clienteId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehicleByPlateAndClientId = async ({ clientId, placa }) => {
    const result = await firebase.firestore().collection('veiculos').where('clienteId', '==', clientId).where('placa', '==', placa.toUpperCase().replace('-', '')).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVeiculos = async ({ mensalistaId }) => {
    const result = await firebase.firestore().collection('veiculos').where('mensalistaId', '==', mensalistaId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByClientId = async ({ clientId }) => {
    const result = await firebase.firestore().collection('veiculos').where('clienteId', '==', clientId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByMonthlyId = async (mensalistaId) => {
    const result = await firebase.firestore().collection('veiculos').where('mensalistaId', '==', mensalistaId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByCreatedEmail = async ({ email }) => {
    const result = await firebase.firestore().collection('veiculos').where('createdBy', '==', email).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByMonthlyIdAndAccessNumber = async ({ mensalistaId, accessCard }) => {
    const result = await firebase.firestore().collection('veiculos').where('mensalistaId', '==', mensalistaId).where('cartao', '==', parseInt(accessCard).toString()).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosOfMonthliesByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('veiculos').where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result).filter(e => e.mensalistaId).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const deleteVehicleById = async ({ id }) => {
    await deleteDoc({ collection: "veiculos", id: id })
}
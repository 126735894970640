import firebase from '../../config/firebase';
import { docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getNewSubscriptionById = async ({ id }) => {
    const result = await firebase.firestore().collection('assinaturas-cliente-novo').doc(id).get()
    return docToItem(result)
}

export const deleteNewSubscriptionItem = async ({ id }) => {
    await deleteDoc({ collection: "assinaturas-cliente-novo", id: id })
}
import React from 'react'
import { NavBar } from '../../../components/navbar'
import { EmptyContent, Loading } from '../../../components/common/commons';
import moment from 'moment';
import { docsToItems } from '../../../store/transform.docs';
import firebase from '../../../config/firebase';
import { isNullOrEmpty, queryString, setPathWindow } from '../../../shared/utils';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { restoreDoc } from '../../../store/collections/collectionBaseWorker';

class DeveloperBackups extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            limit: parseInt(queryString("limit") ?? 30),
            collection: queryString("collection"),
            isShowingLog: false,
            item: null,
            collections: [
                "accessCard",
                "access_records",
                "analytics",
                "aplicacoes",
                "assinaturas-cliente-novo",
                "caixas",
                "caixas-movimentacoes-categorias",
                "callback-queue",
                "cameras-lpr",
                "card-tokens",
                "cellphone_verify",
                "clientes",
                "cobrancas",
                "contactInfo",
                "contactMessage",
                "convenios",
                "credenciados",
                "credenciados-tipos",
                "email-marketing",
                "equipamentos",
                "estacionamentos",
                "formasPagamentos",
                "helpdesk",
                "last-update",
                "licencas",
                "licencas-historico",
                "mensalistas",
                "messages",
                "monthlyHistory",
                "newNotes",
                "nota-fiscal-configuracao",
                "note-settings",
                "notes",
                "notes-rps-series",
                "online-printer",
                "parametros",
                "perfis",
                "planos",
                "planos-licenca",
                "planos-licenca-novo",
                "planos-licenca-novo-cupons",
                "pos",
                "prism-settings",
                "produtos",
                "produtos-categories",
                "produtos-estoque-historico",
                "produtos-sub-categories",
                "queue-malling",
                "queue-printer",
                "remote-config",
                "revendas",
                "setores",
                "share-status",
                "suporte",
                "support-articles",
                "support-center",
                "tabela-precos",
                "ticket",
                "ticketPayment",
                "ticketSettings",
                "ticket_comment",
                "usuarios",
                "usuarios-termos",
                "vehicle-models",
                "veiculos",
            ]
        }
        this.load()
    }

    restore = async () => {
        var confirm = window.confirm(`Deseja mesmo restaurar este registro?`);
        if (confirm) {
            await restoreDoc({ collection: this.state.collection, id: this.state.item.id })
            this.hideLog()
            await this.load()
        }
    }

    load = async () => {
        if (!isNullOrEmpty(this.state.collection)) {
            this.setState({ isLoading: true })
            const result = await firebase.firestore().collection(this.state.collection).where('isDeleted', '==', true).limit(this.state.limit).get()
            this.setState({ items: result.docs.map(e => { return { ...e.data(), id: e.id } }) })
            this.setState({ isLoading: false })
        }
    }

    updatePath = () => {
        setPathWindow(`/desenvolvimento/backups/?collection=${this.state.collection}&limit=${this.state.limit}`)
    }

    changeCollection = (e) => {
        const collection = e.target.value
        this.setState({ collection: collection }, async () => {
            this.updatePath()
            await this.load()
        })
    }

    changeLimit = (e) => {
        this.setState({ limit: e.target.value }, async () => {
            this.updatePath()
            await this.load()
        })
    }

    showLog = (item) => {
        this.setState({ json: JSON.stringify(item, undefined, 4) })
        this.setState({ item: item })
        this.setState({ isShowingLog: true })
    }

    remove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo deletar este registro?\n\nEsta ação não poderar ser desfeita.`);
        if (confirm) {
            await firebase.firestore().collection(this.state.collection).doc(item.id).delete()
            this.setState({ items: this.state.items.filter(e => e.id !== item.id) })
        }
    }

    hideLog = () => {
        this.setState({ isShowingLog: false })
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Registros apagados
                        <div className='col-lg-3'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <select className='form-select form-select-sm' value={this.state.collection} onChange={this.changeCollection}>
                                        <option value=''>Selecionar tabela</option>
                                        {
                                            this.state.collections.map(item => <option value={item}>{item}</option>)
                                        }
                                    </select>
                                </div>
                                <div className='col-lg-6'>
                                    <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                        <option value={30}>30 registros</option>
                                        <option value={50}>50 registros</option>
                                        <option value={100}>100 registros</option>
                                        <option value={200}>200 registros</option>
                                        <option value={1000}>1000 registros</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.collection === "" ? <EmptyContent text='Selecione uma tabela.' /> : <>
                            {
                                this.state.items.length === 0 && this.state.isLoading === false ?
                                    <EmptyContent text='Nenhum registro encontrado' />
                                    :
                                    !this.state.isLoading ?
                                        <div className='row'>
                                            <div className='table-responsive'>
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Documento</th>
                                                            <th scope="col">Operador</th>
                                                            <th scope="col">Removido</th>
                                                            <th scope="col" align='right'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.items.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{`/${this.state.collection}/${item.id}`}</td>
                                                                    <td>{item.deletedBy?.nome}</td>
                                                                    <td className="d-none d-sm-table-cell">{moment(item.deletedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                    <td align='right'>
                                                                        <div className='btn-group'>
                                                                            <Tooltip title="Visualizar log" placement="top">
                                                                                <button className="btn btn-secondary" onClick={(e) => this.showLog(item)}>
                                                                                    <i className="fas fa-code" />
                                                                                </button>
                                                                            </Tooltip>
                                                                            <Tooltip title="Visualizar matriz" placement="top">
                                                                                <a href={`/revenda/cliente/${item.clienteId}/?e=${item.estacionamentoId}`} target='_blank' rel="noopener noreferrer" className="btn btn-primary">
                                                                                    <i className="fas fa-eye" />
                                                                                </a>
                                                                            </Tooltip>
                                                                            <Tooltip title="Apagar" placement="top">
                                                                                <button className="btn btn-danger" onClick={(e) => this.remove(item)}>
                                                                                    <i className="fas fa-trash" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <Loading />
                            }
                        </>
                    }
                </div>
                <Dialog onClose={this.hideLog} fullWidth maxWidth={"md"} open={this.state.isShowingLog}>
                    <MuiDialogTitle className='text-center'>
                        Registro removido por {this.state.item?.deletedBy?.nome} em {moment(this.state.item?.deletedAt?.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <textarea rows={20} disabled className='form-control' value={this.state.json ?? ""} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={this.hideLog} className='btn btn-secondary'>Fechar</button>
                            <button type="button" onClick={() => this.restore()} className="btn btn-danger">
                                <i className="fas fa-trash-restore-alt mx-2" />
                                Restaurar
                            </button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </NavBar>
        )
    }
}

export { DeveloperBackups };
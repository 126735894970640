import React from 'react'
import { NavBar } from '../../../components/navbar'

import firebase from '../../../config/firebase';
import { getCliente, getUsuario, getEstacionamento, getRevendaIdByPark, isPlatformOwner } from '../../../config/auth'
import { Loading } from '../../../components/common/commons'
import 'firebase/auth';
import 'firebase/storage';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { reloadWithAlert, toastError, toastWarning } from '../../../shared/utils';

const initialState = {
    id: null,
    estacionamentoId: getEstacionamento()?.id,
    clienteId: '',
    documento: '',
    inscricaoMunicipal: '',
    progress: 0,
    senhaCertificado: '',
    validadeCertificado: new Moment(new Date()),
    codigoServico: '',
    issRetido: '',
    aliquotaServicos: 0,
    tributacaoRps: '',
    discriminacao: '',
    typeSumValues: 'NAO_SOMAR',
    key: '',
    hasCertificate: false,
    configureCertificateOwner: false,
    hasDataConfiguredCertificate: false,
    isConfigured: "false",
    cert: '',
    isCPF: false,
    isLoading: true,
    random: Math.random()
};

class NovaNotaBanner extends React.Component {

    render() {
        return (
            <div className="col-12 mb-3">
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="card text-white bg-danger" >
                            <div className="card-body">
                                <h5 className="card-title text-white">Atenção</h5>
                                <h6 className="card-title text-white">
                                    A configuração e emissão de nota da forma antiga será descontinuada em 01/10/2023, acesse o novo painel e faça a nova configuração.
                                </h6>
                            </div>
                            <div className="card-footer">
                                <a href="/nota-fiscal/configurar">
                                    <button className='btn btn-danger'>Acessar novo portal</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class ConfigurarNota extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = async () => {
        this.onEstacionamentoSelected(getEstacionamento())
    }

    searchDatAByWhenPark = async () => {
        this.setState({ clienteId: getCliente().id })
        var result = await firebase.firestore().collection('nota-fiscal-configuracao').where('estacionamentoId', '==', this.state.estacionamentoId).get()
        if (result.size !== 0) {
            var data = result.docs[0].data()
            this.setState({ id: result.docs[0].id })
            this.setState({ documento: data.documento })
            this.setState({ inscricaoMunicipal: data.inscricaoMunicipal })
            this.setState({ validadeCertificado: new Moment(data.validadeCertificado.seconds * 1000) })
            this.setState({ codigoServico: data.codigoServico })
            this.setState({ issRetido: data.issRetido })
            this.setState({ aliquotaServicos: data.aliquotaServicos })
            this.setState({ tributacaoRps: data.tributacaoRps })
            this.setState({ discriminacao: data.discriminacao })
            this.setState({ typeSumValues: data.typeSumValues })
            this.setState({ isConfigured: data.isConfigured })
            this.setState({ key: data.key })
            this.setState({ cert: data.cert })
            this.setState({ hasCertificate: data.cert !== null })
            this.setState({ hasDataConfiguredCertificate: true })
        } else {
            this.setState({ hasDataConfiguredCertificate: false })
        }
        this.setState({ isLoading: false })
    }

    update = () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        firebase.firestore().collection('nota-fiscal-configuracao').doc(this.state.id).update({
            estacionamentoId: this.state.estacionamentoId,
            estacionamentoNome: getEstacionamento().nome,
            clienteId: this.state.clienteId,
            documento: this.state.documento.replace(".", "").replace(".", "").replace("/", "").replace("-", ""),
            inscricaoMunicipal: this.state.inscricaoMunicipal,
            codigoServico: this.state.codigoServico,
            issRetido: this.state.issRetido,
            aliquotaServicos: this.state.aliquotaServicos,
            typeSumValues: this.state.typeSumValues,
            tributacaoRps: this.state.tributacaoRps,
            isConfigured: this.state.isConfigured,
            discriminacao: this.state.discriminacao,
            alterAt: new Date(),
            alterBy: getUsuario().email,
            isDeleted: false,
        }).then((result) => {
            reloadWithAlert("Dados para emissão atualizados com sucesso!")
        }).catch(function (error) {
            toastError(error.message)
        });
    }

    verify = () => {
        if (this.state.id === null) {
            this.configure()
        } else {
            this.update()
        }
    }

    configure = () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        firebase.firestore().collection('nota-fiscal-configuracao').add({
            clienteId: getCliente().id,
            estacionamentoId: this.state.estacionamentoId,
            estacionamentoNome: getEstacionamento().nome,
            revendaId: getRevendaIdByPark(),
            documento: this.state.documento.replace(".", "").replace(".", "").replace("/", "").replace("-", ""),
            inscricaoMunicipal: this.state.inscricaoMunicipal,
            validadeCertificado: this.state.validadeCertificado.toDate(),
            codigoServico: this.state.codigoServico,
            issRetido: this.state.issRetido,
            aliquotaServicos: this.state.aliquotaServicos,
            typeSumValues: this.state.typeSumValues,
            tributacaoRps: this.state.tributacaoRps,
            isConfigured: this.state.isConfigured,
            discriminacao: this.state.discriminacao,
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }).then(() => {
            reloadWithAlert("Dados para emissão configurados com sucesso!")
        }).catch(function (error) {
            toastError(error.message)
        });
    }

    validateFields = () => {
        if (this.state.id === null) {
            toastWarning("Antes você deve salvar os dados para emissão.")
            return false
        }
        if (this.state.file === null) {
            toastWarning("Você deve selecionar um certificado digital com a extensão .pfx")
            return false
        }
        if (this.state.senhaCertificado === '') {
            toastWarning("O campo senha e validade do certificado são obrigatórios.")
            return false
        }
        if (this.state.validadeCertificado.toDate() < new Date()) {
            toastWarning("A data de vencimento deve ser maior que a data atual.")
            return false
        }
        return true;
    }

    uploadCertificate = async () => {
        if (!this.validateFields()) {
            return;
        }
        var fr = new FileReader();
        const senha = this.state.senhaCertificado
        const validate = this.state.validadeCertificado
        const estacionamentoId = this.state.estacionamentoId
        const id = this.state.id
        const file = this.state.file
        fr.onload = function () {
            try {
                var forge = require('node-forge');
                var p12Asn1 = forge.asn1.fromDer(forge.util.createBuffer(fr.result));
                forge.pkcs12.pkcs12FromAsn1(p12Asn1, senha);
                var storageRef = firebase.storage().ref();
                const ref = storageRef.child('nfe/configuracoes/' + getCliente().id + "/" + estacionamentoId + "/certificado.pfx");
                ref.put(file).then((snapshot) => {
                    firebase.firestore().collection('nota-fiscal-configuracao').doc(id).update({
                        estacionamentoNome: getEstacionamento().nome,
                        senhaCertificado: senha,
                        validadeCertificado: validate.toDate(),
                        waitingToAprove: true,
                    }).then(() => {
                        reloadWithAlert("Certificado atualizado com sucesso!")
                    }).catch(err => {
                        reloadWithAlert("Aconteceu algum erro, tente novamente ou entre em contato com o suporte.")
                    })
                }).catch(e => {
                    toastWarning("Aconteceu algum erro, tente novamente ou entre em contato com o suporte.")
                });
            } catch (e) {
                if (e.message.includes("Invalid password?")) {
                    toastWarning("A senha do certificado não esta correta.")
                } else {
                    toastWarning("Aconteceu algum erro, tente novamente ou entre em contato com o suporte.")
                }
            }
        }
        fr.readAsArrayBuffer(this.state.file)
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamento: item })
        this.setState({ isCPF: false })
        if (this.state.documento !== '') {
            this.setState({ documento: this.state.documento })
        } else if (item.tipoDocumento === 'CPF') {
            this.setState({ isCPF: true })
        } else if (item.tipoDocumento === 'CNPJ') {
            this.setState({ documento: item.documento })
        }
        this.setState({ estacionamentoId: item.id }, () => {
            this.searchDatAByWhenPark()
        })
    }

    changeCertificate = () => {
        this.setState({ hasCertificate: false })
    }

    showCertificateOwner = () => {
        this.setState({ showCertificateOwner: true })
    }

    configureCertificateOwner = () => {
        firebase.firestore().collection('nota-fiscal-configuracao').doc(this.state.id).update({
            waitingToAprove: false,
            cert: this.state.cert,
            key: this.state.key
        }).then(() => {
            reloadWithAlert("Certificado atualizado com sucesso!")
        }).catch(err => {
            reloadWithAlert("Aconteceu algum erro, tente novamente ou entre em contato com o suporte.")
        })
    }

    handleVencimentoDateChange = (newValue) => {
        this.setState({ validadeCertificado: newValue })
    }

    checkFields = () => {
        return (this.state.documento === '' ||
            this.state.estacionamentoId === undefined ||
            this.state.inscricaoMunicipal === '' ||
            this.state.codigoServico === '' ||
            this.state.issRetido === '' ||
            this.state.aliquotaServicos === '' ||
            this.state.validadeCertificado === null ||
            this.state.tributacaoRps === '')
    }

    render() {
        return (
            <>
                <NavBar>
                    <NovaNotaBanner />
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Configurar certificado para emissão de nota fiscal
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div>
                                                <label>CNPJ</label>
                                                <NumberFormat className="form-control" mask='_' format={'##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                            </div>
                                            <div>
                                                {
                                                    this.state.isCPF &&
                                                    <div className="my-2 alert alert-danger" role="alert">
                                                        <strong>Não é possível emitir nota fiscal a partir de um CPF.</strong> Você deve informar os dados da empresa do estacionamento.
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                <label>Inscrição Municipal</label>
                                                <input autoComplete='off' type="text" onChange={(e) => this.setState({ inscricaoMunicipal: e.target.value })} value={this.state.inscricaoMunicipal} className="form-control" />
                                            </div>
                                            <div>
                                                <label>Discriminação dos serviços</label>
                                                <textarea className='form-control' rows='5' value={this.state.discriminacao} onChange={(e) => this.setState({ discriminacao: e.target.value })} />
                                            </div>
                                            <div>
                                                <div className="my-2 alert alert-info my-3" role="alert">
                                                    Exemplo: <br />
                                                    Guarda e estacionamento do tipo "valet service"
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5'>
                                            <h5>Dados para emisssão</h5>
                                            <div>
                                                <label>Código de serviço</label>
                                                <select className="form-select" value={this.state.codigoServico} onChange={(e) => this.setState({ codigoServico: e.target.value })} v>
                                                    <option value=''>Selecione</option>
                                                    <option value='7811'>7811 - Guarda e estacionamento de veículos terrestres automotores</option>
                                                    <option value='7838'>7838 - Guarda e estacionamento de veículos terrestres automotores, em postos de gasolina</option>
                                                    <option value='7846'>7846 - Guarda e estacionamento do tipo "valet service"</option>
                                                    <option value=''>Lava-rápido</option>
                                                    <option value='7366'>7366 - Lubrificação, Lavagem E Limpeza Não Automáticas De Veículos, Exceto Em Postos De Gasolina</option>
                                                    <option value='7390'>7390 - Lubrificação, Lavagem E Limpeza De Veículos, Inclusive Automáticas, Em Postos De Gasolina.</option>
                                                    <option value='7412'>7412 - Lubrificação, Lavagem E Limpeza Automáticas De Veículos, Exceto Em Postos De Gasolina.</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label>Valor da alíquota do serviço</label>
                                                <NumberFormat value={this.state.aliquotaServicos} onChange={(e) => this.setState({ aliquotaServicos: parseFloat(e.target.value) })} fixedDecimalScale={false} thousandSeparator={true} className="form-control" />
                                            </div>
                                            <div>
                                                <div className="my-2 alert alert-info my-3" role="alert">
                                                    Exemplo: <br />
                                                    5% - 0.05 <br />
                                                    2,5% - 0.025
                                                </div>
                                            </div>
                                            <div>
                                                <label>ISS Retido</label>
                                                <select className="form-select" value={this.state.issRetido} onChange={(e) => this.setState({ issRetido: e.target.value })}>
                                                    <option value=''>Selecione</option>
                                                    <option value='1'>NF-e com ISS Retido</option>
                                                    <option value='0'>NF-e sem ISS Retido</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label>Tipo de tributação</label>
                                                <select className="form-select" value={this.state.tributacaoRps} onChange={(e) => this.setState({ tributacaoRps: e.target.value })}>
                                                    <option value=''>Selecione</option>
                                                    <option value='T'>T - Tributado em São Paulo</option>
                                                    <option value='F'>F - Tributado Fora de São Paulo</option>
                                                    <option value='A'>A - Tributado em São Paulo, porém Isento</option>
                                                    <option value='B'>B - Tributado Fora de São Paulo, porém Isento</option>
                                                    <option value='D'>D - Tributado em São Paulo com isenção parcial</option>
                                                    <option value='M'>M - Tributado em São Paulo, porém com indicação de imunidade subjetiva</option>
                                                    <option value='N'>N - Tributado fora de São Paulo, porém com indicação de imunidade subjetiva</option>
                                                    <option value='R'>R - Tributado em São Paulo, porém com indicação de imunidade objetiva</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label>Na venda de produtos e serviços</label>
                                                <select className="form-select" value={this.state.typeSumValues} onChange={(e) => this.setState({ typeSumValues: e.target.value })} v>
                                                    <option value='NAO_SOMAR'>Não somar os produtos e serviços no valor da nota</option>
                                                    <option value='SOMAR_SERVICOS'>Somar apenas serviços no valor da nota</option>
                                                    <option value='SOMAR_PRODUTOS_SERVICOS'>Somar serviços e venda de produtos no valor da nota</option>
                                                </select>
                                            </div>
                                            <div className="alert alert-danger my-3" role="alert">
                                                Independente da seleção acima, sempre é gerado uma nota de serviço prestado.
                                            </div>
                                        </div>
                                        {
                                            this.state.hasDataConfiguredCertificate === true && <>
                                                <div className='col-lg-4'>
                                                    {
                                                        this.state.isLoading ?
                                                            <div className="card text-center">
                                                                <div className="card-header">
                                                                    Verificando certificado
                                                                </div>
                                                                <div className="card-body">
                                                                    <Loading />
                                                                </div>
                                                                <div className="card-footer text-muted">
                                                                </div>
                                                            </div> :
                                                            <>
                                                                {
                                                                    this.state.showCertificateOwner === true && <>
                                                                        <div className="card text-center">
                                                                            <div className="card-header">
                                                                                Certificado
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className='row'>
                                                                                    <div className='col-lg-12'>
                                                                                        <label>Certificado .key</label>
                                                                                        <textarea rows='10' onChange={(e) => this.setState({ key: e.target.value })} value={this.state.key} className="form-control" />
                                                                                        <span>Parar gerar o .key</span> <p />
                                                                                        <span>openssl pkcs12 -in certificado.pfx -nocerts -out certificado.key</span>
                                                                                    </div>
                                                                                    <div className='col-lg-12'>
                                                                                        <label>Certificado .crt</label>
                                                                                        <textarea rows='10' onChange={(e) => this.setState({ cert: e.target.value })} value={this.state.cert} className="form-control" />
                                                                                        <span>Parar gerar o .crt</span> <p />
                                                                                        <span>openssl pkcs12 -in certificado.pfx -clcerts -nokeys -out certificado.crt</span>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    isPlatformOwner() && <a href="#" onClick={this.configureCertificateOwner} className="btn btn-danger my-3">Salvar .pem e .key</a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    this.state.hasCertificate ? <>
                                                                        <div className="card text-center">
                                                                            <div className="card-header">
                                                                                Certificado
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">Já existe um certificado selecionado</h5>
                                                                                <p className="card-text">O certificado é utilizado para emissão de nota fiscal.</p>
                                                                                <abutton onClick={this.changeCertificate} className="btn btn-warning text-white">Alterar certificado</abutton>
                                                                                {
                                                                                    isPlatformOwner() && <button onClick={(e) => { this.showCertificateOwner() }} className="btn btn-danger mx-2">Enviar .pem e .key</button>
                                                                                }
                                                                            </div>
                                                                            <div className="card-footer text-muted">
                                                                                Este certificado vence em {Moment(this.state.validadeCertificado.toDate()).format("DD/MM/YYYY")}
                                                                            </div>
                                                                        </div>
                                                                    </> :
                                                                        <>
                                                                            <div className="card">
                                                                                <div className="card-header text-center">
                                                                                    Escolher certificado
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <h5 className="card-text text-center">O certificado é utilizado para emissão de nota fiscal e só é aceito o formato A1 ( com extensão .pfx ).</h5>
                                                                                    <div className="mb-3">
                                                                                        <label htmlFor="formFile" className="form-label">Selecionar arquivo</label>
                                                                                        <div>
                                                                                            <input type="file" accept=".p12, .pfx" onChange={(e) => this.setState({ file: e.target.files[0] })} id="formFile" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className='col-lg-6'>
                                                                                            <label>Senha do certificado</label>
                                                                                            <input type="password" autoComplete='off' onChange={(e) => this.setState({ senhaCertificado: e.target.value })} value={this.state.senhaCertificado} className="form-control" />
                                                                                        </div>
                                                                                        <div className='col-lg-6'>
                                                                                            <label>Validade</label>
                                                                                            <MobileDatePicker
                                                                                                label="-"
                                                                                                inputFormat="DD/MM/yyyy"
                                                                                                value={this.state.validadeCertificado}
                                                                                                onChange={this.handleVencimentoDateChange}
                                                                                                renderInput={(params) => <TextField {...params} size="small" className='btn-block' />}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <a href="#" onClick={this.uploadCertificate} className="btn btn-primary my-3">Salvar certificado</a>
                                                                                </div>
                                                                                <div className="card-footer text-muted">
                                                                                    As alterações só serão refletidas caso você salve um novo certificado.
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                }
                                                                <div>
                                                                    <select className="form-select my-3" value={this.state.isConfigured} onChange={(e) => this.setState({ isConfigured: e.target.value })} v>
                                                                        <option value="true">Manter habilitada a emissão de nota fiscal</option>
                                                                        <option value="false">Manter desabilitar a emissão de nota fiscal</option>
                                                                    </select>
                                                                    <div className="my-2 alert alert-warning" role="alert">
                                                                        Também é importante definir no perfil de acesso na aba 'Financeiro' quem poderá emitir nota fiscal <a href='/perfil'><strong>Editar perfil de acesso</strong></a>
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <button type="button" onClick={this.verify} className="btn btn-default btn-block btn-primary my-3">
                                                {
                                                    this.state.hasDataConfiguredCertificate === false && 'Salvar dados e selecionar certificado'
                                                }
                                                {
                                                    this.state.hasDataConfiguredCertificate === true && 'Salvar dados para emissão'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </NavBar>
            </>
        )
    }
}

export { ConfigurarNota, NovaNotaBanner };
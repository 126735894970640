import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { docToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addProduct = async ({ clienteId,
    estacionamentoId,
    descricao,
    valor,
    valorCusto,
    margemLucro,
    tipoProduto,
    createdBy,
    revendaId }) => {
    const doc = await firebase.firestore().collection('produtos').add({
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? getRevenda()?.id,
        createdAt: new Date(),
        createdBy: createdBy,
        descricao: descricao,
        tipoProduto: tipoProduto,
        valor: valor,
        valorCusto: valorCusto,
        margemLucro: margemLucro,
        isDeleted: false,
    })
    return doc.id;
}

export const getProductById = async ({ id, parkId }) => {
    const result = await firebase.firestore().collection('produtos').doc(id).get()
    return docToItem(result)
}

export const getAllProducts = async ({ parkId }) => {
    var results = await firebase.firestore().collection("produtos").where('estacionamentoId', '==', parkId).get()
    return docsToItems(results).sort((a, b) => a.descricao.trim().localeCompare(b.descricao.trim()))
}

export const deleteProduct = async ({ id }) => {
    await deleteDoc({ collection: "produtos", id: id })
}

export const getAllCategories = async ({ parkId }) => {
    const results = await firebase.firestore().collection('produtos-categories').where('estacionamentoId', '==', parkId).get()
    return docsToItems(results)
}

export const getAllSubCategories = async ({ parkId }) => {
    const results = await firebase.firestore().collection('produtos-sub-categories').where('estacionamentoId', '==', parkId).get()
    return docsToItems(results)
}


import { getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems, docToItem } from '../transform.docs';

export const addRelationship = async ({ clientId, estacionamentoId, tipo, valor, nome, status, tolerancia, manterDesconto }) => {
    const data = {
        tipo: tipo,
        estacionamentoId: estacionamentoId,
        clienteId: clientId,
        valor: valor,
        porcentagem: valor,
        nome: nome,
        status: status,
        tolerancia: tolerancia,
        manterDesconto: manterDesconto,
        createdAt: new Date(),
        createdBy: getUsuario().email,
        revendaId: getRevendaIdByPark(),
        isDeleted: false,
    }
    const doc = await firebase.firestore().collection('convenios').add(data)
    return doc.id;
}

export const getAllRelationshipsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('convenios').where("estacionamentoId", "==", estacionamentoId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getRelationshipById = async ({ id }) => {
    const result = await firebase.firestore().collection('convenios').doc(id).get()
    return docToItem(result)
}

export const getAllRelationshipsByClientId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection('convenios').where("clienteId", "==", clienteId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

import moment from 'moment';
import { getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { sendLog } from '../../shared/analytics';

export const getDocById = async ({ collection, id }) => {
    const result = await firebase.firestore().collection(collection).doc(id).get()
    return docToItem(result)
}

export const getAllDocsByQueries = async ({ collection, queries }) => {
    var q = firebase.firestore().collection(collection)
    for (const query of queries) {
        q = query
    }
    const docs = await q.get()
    return docsToItems(docs)
}

export const getDocByQuery = async ({ collection, query }) => {
    var q = firebase.firestore().collection(collection)
    q = query
    const docs = await q.get()
    return docsToItem(docs)
}

export const addDoc = async ({ collection, data }) => {
    data.createdBy = getUsuarioCompact()
    data.createdAt = new Date()
    const doc = firebase.firestore().collection(collection).add(data)
    return doc.id
}

export const deleteDoc = async ({ collection, id }) => {
    const user = getUsuarioCompact()
    const data = await getDocById({ collection: collection, id: id })
    data.isDeleted = true
    data.deletedBy = user
    data.deletedAt = new Date()
    sendLog(collection, `O usuário ${user.nome} removeu o registro ${collection}/${id} em ${moment().format("DD/MM/YYYY [às] HH:mm:ss")}`, false, JSON.stringify(data))
    await firebase.firestore().collection(collection).doc(id).delete()
    // updateDoc({ collection: collection, id: id, data: data })
}

export const restoreDoc = async ({ collection, id }) => {
    const user = getUsuarioCompact()
    const data = await getDocById({ collection: collection, id: id })
    data.isDeleted = false
    data.restoredBy = user
    data.restoredAt = new Date()
    sendLog("Backup", `O usuário ${user.nome} restaurou o registro ${collection}/${id} em ${moment().format("DD/MM/YYYY [às] HH:mm:ss")}`, false, JSON.stringify(data))
    await updateDoc({ collection: collection, id: id, data: data })
}

export const updateDoc = async ({ collection, id, data }) => {
    data.updatedBy = getUsuarioCompact()
    data.updatedAt = new Date()
    await firebase.firestore().collection(collection).doc(id).update(data)
}
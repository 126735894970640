import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { isNullOrEmpty } from '../../shared/utils';
import { getEstacionamento, getUsuario, isAdministrador } from '../../config/auth';
import { Loading } from '../../components/common/commons';
import { AlertInfo, AlertWarning } from '../../shared/alert-manager';
import { getUseByAccessCode, getUserById } from '../../store/collections/userWorker';
import { getProfileById } from '../../store/collections/profileWorker';

const message = "Informe o código de acesso do usuário que tenha permissão para executar a ação de"
const permissions = [
    { permission: "MANUAL_DISCOUNT", description: `${message} dar desconto manual.` },
    { permission: "CHANGE_PRICE_TABLE", description: `${message} alterar tabela de preço.` },
    { permission: "CLOSE_CASHIER", description: `${message} fechar o caixa.` },
    { permission: "OPEN_CASHIER", description: `${message} abrir o caixa.` },
    { permission: "OPEN_CASHIER_ASSOCIATED", description: `${message} abrir o caixa e alterar usuário por código de acesso.` },
    { permission: "PAYMENT_TICKET", description: `${message} fazer o pagamento do ticket.` },
    { permission: "CREATE_TICKET", description: `${message} lançar um veículo.` },
    { permission: "PAYMENT_MONTHLY", description: `${message} fazer o pagamento do mensalista.` },
    { permission: "CANCEL_TICKET", description: `${message} fazer o cancelamento ou estorno do ticket.` },
    { permission: "LOST_TICKET", description: `${message} imprimir o recibo de perda ou roubo.` },
    { permission: "CHANGE_DATE_TICKET_OUT", description: `${message} editar o horário de saída do ticket.` },
    { permission: "CHANGE_DATE_TICKET_IN", description: `${message} editar o horário de entrada do ticket.` },
    { permission: "CREATE_MONTHLY", description: `${message} cadastrar mensalistas.` },
    { permission: "ROTATE_MONTHLY_SLOT", description: `${message} rotacionar vaga de mensalista.` },
    { permission: "TICKET_BATCK", description: `${message} alterar status de vários tickets.` },
    { permission: "MONTHIE_PAYMENT", description: `${message} pagar vários meses de um mensalista.` },
    { permission: "MONTHLY_NEXT_BILL", description: `${message} lançar produtos e serviços na próxima cobrança.` },
    { permission: "MONTHLY_REMOVE", description: `${message} remover um mensalista e suas cobranças pendentes.` },
    { permission: "MONTHLY_EDIT", description: `${message} editar um mensalista.` },
    { permission: "MONTHLY_CHANGE_PLAN", description: `${message} editar o plano de um mensalista.` },
    { permission: "MONTHLY_CHANGE_DUE_DATE", description: `${message} editar a data de vencimento de um mensalista.` },
    { permission: "MONTHLY_REMOVE_VEHICLE", description: `${message} apagar o veículo de um mensalista.` },
    { permission: "MONTHLY_CHARGE_REGISTER", description: `${message} registrar um pagamento na cobrança de um mensalista.` },
    { permission: "MONTHLY_CHARGE_EDIT", description: `${message} editar cobrança de um mensalista.` },
    { permission: "MONTHLY_CHARGE_REMOVE", description: `${message} remover remover uma cobrança de um mensalista.` },
    { permission: "MONTHLY_CHARGE_ADD", description: `${message} adicionar adicionar cobranças em um mensalista.` },
    { permission: "RESET_PRISM", description: `${message} zerar a sequência de prismas.` },
    { permission: "REMOVE_PRODUCT", description: `${message} remover produtos e serviços de um ticket.` },
    { permission: "CHANGE_USER", description: `${message} trocar de operador via código de acesso.` },
    { permission: "USER_TO_ADD_PRODUCT", description: `${message} ser associado ao produto adicionado.` },
    { permission: "CASHIER_MOVEMENT_ADD", description: `${message} adicionar movimentacoes no caixa.` },
    { permission: "ASSOCIATE_TICKET_TO_MONTHLY", description: `${message} associar um ticket avulso a um mensalista.` },
    { permission: "REPRINT_RECEIPT", description: `${message} reimprimir uma segunda via.` },
    { permission: "ACTIVATE_TOTEM", description: `${message} habilitar a função de auto atendimento.` },
    { permission: "UNACTIVATE_TOTEM", description: `${message} desabilitar a função de auto atendimento.` },
    { permission: "REMOVE_TICKET", description: `${message} apagar um ticket.` },
]

const isPermissionAllowedByUser = async ({ user, currentPermission }) => {
    const profile = await getProfileById({ id: user.perfilId })
    if (isAdministrador()) {
        return true
    }
    if (profile.accessWebSite === false || profile.accessWebSite === undefined) {
        return false
    }
    switch (currentPermission.permission) {
        case "MANUAL_DISCOUNT": return profile.habilitarDescontoManual ?? true
        case "CHANGE_PRICE_TABLE": return profile.changePaymentTableOfTicket ?? true
        case "CLOSE_CASHIER": return profile.habilitarFecharCaixa ?? true
        case "OPEN_CASHIER": return profile.habilitarAbrirCaixa ?? true
        case "PAYMENT_TICKET": return profile.habilitarPagamentoTicket ?? true
        case "PAYMENT_MONTHLY": return profile.habilitarPagamentoMensalista ?? true
        case "CANCEL_TICKET": return profile.habilitarCancelarTicket ?? true
        case "CHANGE_DATE_TICKET_OUT": return profile.habilitarDataSaida ?? true
        case "CHANGE_DATE_TICKET_IN": return profile.habilitarDataEntrada ?? true
        case "MONTHIE_PAYMENT": return profile.habilitarPagamentosDeMultiplasMensalidadesDoMensalista ?? true
        case "MONTHLY_NEXT_BILL": return profile.habilitarProdutosConsumidosNaProximaFatura ?? true
        case "MONTHLY_REMOVE": return profile.removeMonthly ?? true
        case "MONTHLY_EDIT": return profile.editMonthly ?? true
        case "MONTHLY_CHANGE_PLAN": return profile.habilitarMensalistasEditarPlano ?? true
        case "MONTHLY_CHANGE_DUE_DATE": return profile.habilitarMensalistasEditarVencimento ?? true
        case "MONTHLY_REMOVE_VEHICLE": return profile.removeMonthlyVehicle ?? true
        case "MONTHLY_CHARGE_REGISTER": return profile.habilitarMensalistasCobrancaRegistrarPagamento ?? true
        case "MONTHLY_CHARGE_EDIT": return profile.habilitarMensalistasCobrancaEditar ?? true
        case "MONTHLY_CHARGE_REMOVE": return profile.habilitarMensalistasCobrancaApagar ?? true
        case "MONTHLY_CHARGE_ADD": return profile.habilitarMensalistasCobrancaAdicionar ?? true
        case "RESET_PRISM": return profile.habilitarZerarPrisma ?? true
        case "REMOVE_PRODUCT": return profile.habilitarRemoverProdutoOutroOperador ?? true
        case "CASHIER_MOVEMENT_ADD": return profile.habilitarMovimentacoesDeCaixa ?? true
        case "ASSOCIATE_TICKET_TO_MONTHLY": return profile.habilitarTicketAvulsoParaMensalista ?? true
        case "REPRINT_RECEIPT": return profile.habilitarReimpressaoDeSegundaVia ?? true
        case "REMOVE_TICKET": return profile.habilitarApagarTicket ?? true
        case "UNACTIVATE_TOTEM":
        case "ACTIVATE_TOTEM": return profile.habilitarAutoAtendimento ?? true
        default: return true
    }
}

class UserPermissionButton extends React.Component {

    constructor(props) {
        super(props)
        const permission = permissions.filter(e => e.permission === props.permission)
        if (permission.length > 0) {
            this.state = {
                currentPermission: permission[0],
                isShowingModal: false
            }
        }
    }

    showModalIfNeed = async () => {
        const currentUser = getUsuario()
        const user = await getUserById({ id: currentUser.id })
        const allowed = await isPermissionAllowedByUser({ user: user, currentPermission: this.state.currentPermission })
        if (allowed) {
            this.props.action()
        } else {
            this.setState({ isShowingModal: true })
        }
    }

    action = (user) => {
        this.setState({ isShowingModal: false })
        this.props.action(user)
    }

    onClose = () => {
        this.setState({ isShowingModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                <button className={this.props.className} onClick={this.showModalIfNeed}>
                    {this.props.children}
                </button>
                {
                    this.state.isShowingModal && <OperationUserPermissionModal onClose={this.onClose} currentPermission={this.state.currentPermission} action={this.action} />
                }
            </>
        )
    }
}

class OperationUserPermissionModal extends React.Component {

    constructor(props) {
        super(props);
        var currentPermission = null
        if (props.permission) {
            const permission = permissions.filter(e => e.permission === props.permission)
            if (permission.length > 0) {
                currentPermission = permission[0]
            }
        } else {
            currentPermission = props.currentPermission
        }
        const park = getEstacionamento()
        this.state = {
            clienteId: park?.clienteId,
            revendaId: park?.revendaId,
            estacionamentoId: park?.id,
            modalState: "fill",
            value: "",
            message: "",
            isShowingModal: true,
            currentPermission: currentPermission
        }
    }

    verifyEnter = (event) => {
        if (event.key === 'Enter') {
            this.confirm()
        }
    }

    confirm = async () => {
        this.setState({ modalState: "loading" })
        if (!isNullOrEmpty(this.state.value)) {
            const user = await getUseByAccessCode({ clienteId: this.state.clienteId, code: this.state.value })
            if (user) {
                if (user.estacionamentos.map(e => e.id).indexOf(this.state.estacionamentoId) !== -1 && isPermissionAllowedByUser({ user: user, currentPermission: this.state.currentPermission })) {
                    if (this.props.action) {
                        this.props.action(user)
                        this.setState({ isShowingModal: false })
                    }
                    this.setState({ modalState: "fill" })
                } else {
                    this.setState({ modalState: "fill" })
                    this.setState({ message: "Este operador não tem permissão para fazer esta ação." })
                }
            } else {
                this.setState({ modalState: "fill" })
                this.setState({ message: "Nenhum operador encontrado com este código." })
            }
        } else {
            this.setState({ modalState: "fill" })
            this.setState({ message: "Informe o código de acesso do operador." })
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                <Dialog maxWidth={"xs"} fullWidth onClose={this.onClose} open={this.state.isShowingModal}>
                    {
                        this.state.modalState === "loading" &&
                        <MuiDialogContent>
                            <Loading />
                        </MuiDialogContent>
                    }
                    {
                        this.state.modalState === "fill" && <>
                            <MuiDialogTitle className='text-center'>
                                Código de operador
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertInfo message={this.state.currentPermission?.description} />
                                {
                                    this.state.message && <AlertWarning message={this.state.message} />
                                }
                                <div className='col-lg-12 text-center'>
                                    <input type="text" onKeyPress={this.verifyEnter} value={this.state.value} onChange={(e) => { this.setState({ value: e.target.value }) }} className="form-control form-control-lg text-center" />
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className="btn-group m-3">
                                    <button onClick={(e) => this.onClose()} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={(e) => this.confirm()} className='btn btn-success'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>

            </>
        )
    }
}

export { OperationUserPermissionModal, UserPermissionButton }
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc, updateDoc } from './collectionBaseWorker';

export const getAllDevicesByParkId = async ({ estacionamentoId }) => {
    const results = await firebase.firestore().collection('devices')
        .where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(results)
}

export const updateDevice = async ({ id, data }) => {
    data.lastUpdate = new Date()
    await updateDoc({ collection: 'devices', id: id, data: data })
}

export const deleteDevice = async ({ id }) => {
    await deleteDoc({ collection: "devices", id: id })
}

import React from 'react';
import { NavBar } from '../../../components/navbar'
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent, ClientButton } from '../../../components/common/commons'
import Moment from 'moment';
import { getMinimumVersions, alterarEstacionamento, isPlatformOwner, getUsuario, getRevendasAssociadas } from '../../../config/auth'
import { SimpleListarAnalytics } from '../../analytics/logs'
import { getAllUsersByLimit } from '../../../store/collections/userWorker';
import { getAllLastTickets } from '../../../store/collections/ticketWorker';
import { SimpleNewNotes } from '../../nota-fiscal/novo';
import { ListClientsSimple } from '../../cliente';

class HomeRevenda extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            userLastAccesse: [],
            ticketLast: [],
            notes: [],
            certs: [],
            isParkLoading: true,
            parksCreated: [],
            ticketTimit: 5,
            accessTimit: 5,
            certificateLimit: 5,
            acessoRevendedorRevendedores: getUsuario()?.acessoRevendedorRevendedores ?? false,
        }
    }

    componentDidMount() {
        this.loadLastAccess()
        this.loadLastTickets()
    }

    loadLastAccess = async () => {
        this.setState({ isLoading: true });
        const revendasAssociadas = getRevendasAssociadas()
        const allUsers = await getAllUsersByLimit({ limit: this.state.accessTimit, associatedRessales: revendasAssociadas })
        this.setState({ isLoading: false });
        const minimumVersions = getMinimumVersions()
        const list = allUsers.map(e => {
            return {
                ...e,
                isUpdated: this._isLastVersion(minimumVersions, e),
                id: e.id
            }
        })
        this.setState({ userLastAccesse: list })
        this.setState({ isLoading: false });
    }

    _isLastVersion(minimumVersions, user) {
        if (user.version === '' || minimumVersions === null) {
            //É site
            return true
        }
        const minimumFromPlatform = minimumVersions.filter(e => e.store.platform.includes(user.flavor))[0]
        if (minimumFromPlatform === undefined) {
            return true
        }
        return parseInt(user.version.replaceAll(".", "")) >= parseInt(minimumFromPlatform.minimum.replaceAll(".", ""))
    }

    loadLastTickets = async () => {
        this.setState({ isLoading: true });
        const revendasAssociadas = getRevendasAssociadas()
        const tickets = await getAllLastTickets({ limit: this.state.ticketTimit, associatedRessales: revendasAssociadas })
        this.setState({ ticketLast: tickets })
        this.setState({ isLoading: false });
    }

    changeTicketLimit = (e) => {
        this.setState({ ticketTimit: parseInt(e.target.value) }, () => {
            this.loadLastTickets()
        })
    }

    accessTicketLimit = (e) => {
        this.setState({ accessTimit: parseInt(e.target.value) }, () => {
            this.loadLastAccess()
        })
    }

    confirmAlterarCliente(estacionamentoId) {
        alterarEstacionamento(estacionamentoId)
        setTimeout(() => {
            window.location.href = "/home"
        }, 500);
    }

    classIsUpdateName = (updated) => {
        if (updated) {
            return "badge text-bg-success w-100"
        }
        return "badge text-bg-danger w-100"
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className="col-lg-6">
                            <div className='card mb-4'>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Últimos acessos
                                    <div className='col-lg-3'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <select className='form-select form-select-sm' value={this.state.accessTimit} onChange={this.accessTicketLimit}>
                                                    <option value={5}>5 registros</option>
                                                    <option value={10}>10 registros</option>
                                                    <option value={15}>15 registros</option>
                                                    <option value={20}>20 registros</option>
                                                    <option value={25}>25 registros</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        this.state.userLastAccesse.length === 0 && this.state.isLoading === false ?
                                            <EmptyContent text='Nenhum usuário cadastrado' />
                                            :
                                            this.state.isLoading === false ?
                                                <div className='col-md-12'>
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" width={10} className="d-none d-sm-table-cell"></th>
                                                                    <th scope="col" className="d-none d-sm-table-cell">Estacionamento</th>
                                                                    <th scope="col">Usuário</th>
                                                                    <th scope="col">Data</th>
                                                                    <th scope="col" width="90"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.userLastAccesse.map(user =>
                                                                        <tr key={user.usuarioId}>
                                                                            <td className="d-none d-sm-table-cell">
                                                                                <h6>
                                                                                    <span className={this.classIsUpdateName(user.isUpdated)}>
                                                                                        {user.version}
                                                                                        {!user.flavor && 'Site'}
                                                                                        {user.flavor?.includes("pagSeguro") && (' - PagSeguro')}
                                                                                        {user.flavor?.includes("cielo") && (' - Cielo')}
                                                                                        {user.flavor?.includes("getnet") && (' - GetNet')}
                                                                                        {user.flavor?.includes("Stone") && (' - Stone')}
                                                                                        {user.flavor?.includes("safra") && (' - Safra')}
                                                                                        {user.flavor?.includes("store") && (' - Google Play')}
                                                                                        {user.flavor?.includes("rede") && (' - Rede')}
                                                                                    </span>
                                                                                </h6>
                                                                            </td>
                                                                            {
                                                                                user.estacionamentos?.length > 0 ?
                                                                                    <td className="d-none d-sm-table-cell">{
                                                                                        user.estacionamentos[0].nome.length > 25 ?
                                                                                            <span>{user.estacionamentos[0].nome.substring(0, 25)}...</span> :
                                                                                            user.estacionamentos[0].nome}</td> :
                                                                                    <td className="d-none d-sm-table-cell">Não cadastrado</td>
                                                                            }
                                                                            {
                                                                                <td>{
                                                                                    user.nome.length > 25 ?
                                                                                        <span>{user.nome.substring(0, 25)}...</span>
                                                                                        :
                                                                                        user.nome
                                                                                }
                                                                                </td>
                                                                            }
                                                                            <td>{user.lastAccess ? Moment(user.lastAccess?.seconds * 1000).format("DD/MM HH:mm") : "-"}</td>
                                                                            <td>
                                                                                <div className="btn-group">
                                                                                    <ClientButton item={user} />
                                                                                    {
                                                                                        user.estacionamentos?.length > 0 &&
                                                                                        <button onClick={(e) => { this.confirmAlterarCliente(user.estacionamentos[0].id) }} className='btn btn-success'>
                                                                                            <i className="fas fa-home" />
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='col-md-12'>
                                                    <Loading />
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className='card mb-4'>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Últimos tickets lançados
                                    <div className='col-lg-3'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <select className='form-select form-select-sm' value={this.state.ticketTimit} onChange={this.changeTicketLimit}>
                                                    <option value={5}>5 registros</option>
                                                    <option value={10}>10 registros</option>
                                                    <option value={15}>15 registros</option>
                                                    <option value={20}>20 registros</option>
                                                    <option value={25}>25 registros</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.ticketLast.length === 0 && this.state.isLoading === false ?
                                        <EmptyContent text='Nenhum ticket lançado' />
                                        :
                                        this.state.isLoading === false ?
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped ">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" align='center' className="d-none d-sm-table-cell">Aparelho</th>
                                                                    <th scope="col">Estacionamento</th>
                                                                    <th scope="col" className="d-none d-sm-table-cell">Ticket</th>
                                                                    <th scope="col">Data</th>
                                                                    <th scope="col" width="80"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.ticketLast.map(ticket =>
                                                                        <tr key={ticket.ticket}>
                                                                            <td align='center' width={10} className="d-none d-sm-table-cell">
                                                                                {
                                                                                    ticket.system ?
                                                                                        <span className="badge text-bg-success w-100">
                                                                                            {
                                                                                                ticket.system?.app.length > 15 ? <span>{ticket.system?.version} - Web</span> :
                                                                                                    <span>{ticket.system.version} - {ticket.system?.app}</span>
                                                                                            }
                                                                                        </span> :
                                                                                        <span className="badge text-bg-warning w-100">
                                                                                            Não disponível
                                                                                        </span>
                                                                                }
                                                                            </td>
                                                                            <td>{ticket.estacionamentoNome}</td>
                                                                            <td className="d-none d-sm-table-cell">{ticket.ticket}</td>
                                                                            <td>{Moment(ticket.createAt.toDate()).format("DD/MM HH:mm")}</td>
                                                                            <td>
                                                                                <div className="btn-group">
                                                                                    <a href={'/buscar/ticket?id=' + ticket.id} type="button" className="btn btn-warning text-white">
                                                                                        <i className="fas fa-ticket-alt"></i>
                                                                                    </a>
                                                                                    <button onClick={(e) => { this.confirmAlterarCliente(ticket.estacionamentoId) }} className='btn btn-success'>
                                                                                        <i className="fas fa-home" />
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <Loading />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-lg-6">
                            <SimpleNewNotes limit={5} />
                        </div>
                        <div className="col-lg-6">
                            <ListClientsSimple limit={5} />
                        </div>
                        <div className='col-lg-6'>
                            <SimpleListarAnalytics />
                        </div>
                    </div>
                </NavBar>
            </>)
    }
}

export default HomeRevenda
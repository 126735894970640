import React from 'react'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import 'firebase/auth';
import 'firebase/storage';
import { Loading } from '../../../components/common/commons';
import NumberFormat from 'react-number-format';
import { createNewParkAccount } from '../viewmodel/client-viewmode'
import { createAccount } from '../../../view-model/conta/create-account-view-model';
import { getRevendaById } from '../../../store/collections/revendaWorker';
import { isNullOrEmpty, reloadWithAlert, toastWarning } from '../../../shared/utils';
import { getAllRessellers } from '../../../store/collections/userWorker';
import { getAllSupportCentersByRessales } from '../../../store/collections/supportCenterWorker';
import { getRevendasAssociadas, getUsuario } from '../../../config/auth';

class CriarNovoEstacionamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clientId: props.clientId,
            state: 'fill', //creating
            message: '',
            agentName: '',
            parkName: '',
            parkInternalName: ''
        }
    }

    addNewPark = async () => {
        this.setState({ state: 'loading' })
        this.setState({ message: '' })
        await createNewParkAccount({
            clientId: this.state.clientId,
            agentName: this.state.agentName,
            parkName: this.state.parkName,
            parkInternalName: this.state.parkInternalName
        }, status => {
            this.setState({ state: 'creating' })
            this.setState({ status: status.text })
            this.setState({ progress: status.progress })
        }, error => {
            this.setState({ message: error })
            this.setState({ state: 'fill' })
        }, completed => {
            reloadWithAlert("Novo estacionamento cadastrado com sucesso!")
        })
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {
        return (
            <Dialog maxWidth={"sm"} onClose={this.onClose} open={true}>
                <MuiDialogTitle className='text-center'>
                    Criar novo estabelecimento
                </MuiDialogTitle>
                <MuiDialogContent>
                    {
                        <div className='col-lg-12'>
                            {
                                this.state.state === 'fill' &&
                                <>
                                    <div className='row'>
                                        {
                                            this.state.message !== '' &&
                                            <div className="col-lg-12">
                                                <div className='alert alert-primary'>
                                                    <strong>Atenção: </strong> {this.state.message}
                                                </div>
                                            </div>
                                        }
                                        <div className="col-lg-12">
                                            <label>Nome do representante</label>
                                            <input type="text" onChange={(e) => this.setState({ agentName: e.target.value })} value={this.state.agentName} className="form-control" required />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Nome do estabelecimento</label>
                                            <input type="text" onChange={(e) => this.setState({ parkName: e.target.value })} value={this.state.parkName} className="form-control" required />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Nome interno</label>
                                            <input type="text" onChange={(e) => this.setState({ parkInternalName: e.target.value })} value={this.state.parkInternalName} className="form-control" required />
                                            <small>Este nome será visível apenas para gerenciamento interno.</small>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.state === 'creating' &&
                                <div className='text-center'>
                                    <Loading />
                                    <span>{this.state.status}</span>
                                    <div className={"divider-" + this.state.progress + " my-3"} />
                                </div>
                            }
                        </div>
                    }
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={this.onClose} className='btn btn-secondary'>Cancelar</button>
                        <button onClick={this.addNewPark} className='btn btn-success'>Confirmar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>)
    }
}

class CriarNovaMatriz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            representant: "",
            revendaId: props.revendaId,
            park: "",
            phone: "",
            cnpj: "",
            email: "",
            password: "",
            welcomeEmail: false,
            solicitarLeituraDosTermos: true,
            supportId: null,
            document: "",
            state: 'fill', //creating
            message: '',
            revenda: {},
            supports: []
        }
        this.load()
    }

    load = async () => {
        this.setState({ state: 'loading' })
        const revenda = await getRevendaById({ id: this.state.revendaId })
        this.setState({ revenda: revenda })
        const revendasAssociadas = getUsuario().revendasAssociadas ?? []
        this.setState({ revendasAssociadas: revendasAssociadas })
        await this._loadResselersAll()
        await this._loadSupportCenters()
        this.setState({ state: 'fill' })
    }

    changeRevenda = (e) => {
        this.setState({ revendaId: e.target.value }, () => {
            this._loadSupportCenters()
        })
    }

    _loadResselersAll = async () => {
        const revendasAssociadas = getRevendasAssociadas()
        const results = await getAllRessellers({ associatedResales: revendasAssociadas })
        this.setState({ resselers: results })
    }

    _loadSupportCenters = async () => {
        const associatedResales = getRevendasAssociadas()
        const supports = await getAllSupportCentersByRessales({ associatedResales: associatedResales })
        this.setState({ supports: supports })
        this.setState({ supportId: null })
    }

    addNew = async () => {
        if (isNullOrEmpty(this.state.revendaId)) {
            toastWarning("Selecione a revenda")
            return
        }
        this.setState({ state: 'loading' })
        this.setState({ message: '' })
        await createAccount({
            nome: this.state.representant,
            email: this.state.email,
            phone: this.state.phone,
            park: this.state.park,
            document: this.state.document,
            password: this.state.password,
            welcomeEmail: this.state.welcomeEmail,
            revendaId: this.state.revendaId,
            isTermsUpdated: this.state.solicitarLeituraDosTermos
        }, status => {
            this.setState({ state: 'creating' })
            this.setState({ status: status.text })
            this.setState({ progress: status.progress })
        }, error => {
            this.setState({ message: error })
            this.setState({ state: 'fill' })
        }, completed => {
            reloadWithAlert("Novo estabelecimento cadastrado")
        })
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {
        return (
            <Dialog maxWidth={"md"} fullWidth onClose={this.onClose} open={true}>
                <MuiDialogTitle className='text-center'>
                    Criar nova matriz
                </MuiDialogTitle>
                <MuiDialogContent>
                    {
                        <div className='col-lg-12'>
                            {
                                this.state.state === 'fill' &&
                                <>
                                    <div className='row'>
                                        {
                                            this.state.message !== '' &&
                                            <div className="col-lg-12">
                                                <div className='alert alert-primary'>
                                                    <strong>Atenção: </strong> {this.state.message}
                                                </div>
                                            </div>
                                        }
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <label className="my-2">Nome do estabelecimento</label>
                                                    <input type="text" value={this.state.park} onChange={(e) => this.setState({ park: e.target.value })} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className="my-2">Documento do estabelecimento</label>
                                                    <NumberFormat className="form-control" value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="my-2">Nome do representante</label>
                                                    <input type="text" value={this.state.representant} onChange={(e) => this.setState({ representant: e.target.value })} className="form-control" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="my-2">Celular do representante</label>
                                                    <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.value })} format="(##)#####-####" />
                                                </div>
                                                <div className="col-lg-12 mt-3">
                                                    <div className='alert alert-warning'>
                                                        <span className={`badge text-bg-warning text-white`}>Informação</span> <br />
                                                        No primeiro acesso o usuário terá que confirmar a leitura dos termos de uso de politica de privacidade para o uso da plataforma.
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="solicitarLeituraDosTermos" checked={this.state.solicitarLeituraDosTermos} onChange={(e) => this.setState({ solicitarLeituraDosTermos: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="solicitarLeituraDosTermos">
                                                                Solicitar leitura dos termos
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className="col-lg-12 mt-2">
                                                    <div className='alert alert-secondary'>
                                                        <span className={`badge text-bg-secondary text-white`}>Acessos</span> <br />
                                                        Este será o primeiro usuário deste estabelecimento, com acesso de administrador e o usuário poderá alterar a senha em outro momento.
                                                        <div className='row'>
                                                            <div className="col-lg-6">
                                                                <label>E-mail</label>
                                                                <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Senha temporária</label>
                                                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='alert alert-secondary'>
                                                        <span className={`badge text-bg-secondary text-white`}>Revenda</span> <br />
                                                        <div className='row'>
                                                            <div className="col-lg-12">
                                                                <label>Revenda</label>
                                                                <select className='form-select' value={this.state.revendaId} onChange={this.changeRevenda}>
                                                                    <option value="">Selecionar ( obrigatório )</option>
                                                                    {
                                                                        this.state.revendasAssociadas?.map(item => { return <option key={item.value} value={item.value}>{item.label}</option> })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>Revendedor</label>
                                                                <select className='form-select' value={this.state.resselerId} onChange={(e) => this.setState({ resselerId: e.target.value })}>
                                                                    <option value="">Selecionar ( opcional )</option>
                                                                    {
                                                                        this.state.resselers?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {/* {
                                                                this.state.revenda?.tipoSuporte === "CONCESSIONARIO" && <>
                                                                    <div className="col-lg-12">
                                                                        <label>Central de suporte</label>
                                                                        <select className='form-select' value={this.state.supportId} onChange={(e) => this.setState({ supportId: e.target.value })}>
                                                                            <option value="">Selecionar ( obrigatório )</option>
                                                                            {
                                                                                this.state.supports?.map(item => { return <option value={item.id}>{item.name}</option> })
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            } */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.state === 'creating' &&
                                <div className='text-center'>
                                    <Loading />
                                    <span>{this.state.status}</span>
                                    <div className={"divider-" + this.state.progress + " my-3"} />
                                </div>
                            }
                        </div>
                    }
                </MuiDialogContent>
                <MuiDialogActions>
                    {
                        this.state.state !== 'creating' && <>
                            <div className='btn-group m-3'>
                                <button onClick={this.onClose} className='btn btn-secondary'>Cancelar</button>
                                <button onClick={this.addNew} className='btn btn-success'>Confirmar</button>
                            </div>
                        </>
                    }
                </MuiDialogActions>
            </Dialog >)
    }
}

export { CriarNovoEstacionamento, CriarNovaMatriz }
import firebase from '../config/firebase';
import moment from 'moment';

export const uploadParkPrinterImage = async (estacionamentoId, file) => {
    return await uploadParkImages(estacionamentoId, file, "images", "logo-park-printer", "jpg")
}

export const uploadProductImage = async (estacionamentoId, file, productId) => {
    return await uploadParkImages(estacionamentoId, file, "products", productId, "jpg")
}

export const uploadClientLogo = async (estacionamentoId, file) => {
    return await uploadParkImages(estacionamentoId, file, "images", "logo-client-site", "jpg")
}

export const uploadAppImage = async (revendaId, file) => {
    return await uploadResaleImages(revendaId, file, "images", "logo-app", "png")
}

export const uploadAdminImage = async (revendaId, file) => {
    return await uploadResaleImages(revendaId, file, "images", "logo-admin", "png")
}

export const uploadPrinterImage = async (revendaId, file) => {
    return await uploadResaleImages(revendaId, file, "images", "logo-printer", "jpg")
}

export const uploadTermsOfUse = async (revendaId, file) => {
    return await uploadResaleImages(revendaId, file, "documents", "termos-de-uso", "txt")
}

export const uploadEmailImage = async (revendaId, file) => {
    return await uploadResaleImages(revendaId, file, "images", "logo-email", "jpg")
}

export const getURLFromURI = async (uri) => {
    var storage = firebase.storage();
    var storageRef = storage.ref();
    const result = (await storageRef.child(uri).getDownloadURL()).toString()
    return result
}

const uploadResaleImages = async (revendaId, file, path, name, extension) => {
    const child = `revendas/${revendaId}/${path}/${name}-${moment().unix()}.${extension}`
    var storageRef = firebase.storage().ref();
    var mountainImagesRef = storageRef.child(child);
    const result = await mountainImagesRef.put(file)
    return result.ref.fullPath.toString()
}

const uploadParkImages = async (estacionamentoId, file, path, name, extension) => {
    const child = `estabelecimentos/${estacionamentoId}/${path}/${name}-${moment().unix()}.${extension}`
    var storageRef = firebase.storage().ref();
    var mountainImagesRef = storageRef.child(child);
    const result = await mountainImagesRef.put(file)
    return result.ref.fullPath.toString()
}

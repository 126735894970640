import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'

export const saveLastUpdate = async (estacionamentoId) => {
    const park = {
        id: estacionamentoId ?? getEstacionamento().id,
        clienteId: getCliente().id,
    }
    await saveLastUpdateByPark(park, true)
}

export const saveLastUpdateNoSkip = async () => {
    const park = {
        id: getEstacionamento().id,
        clienteId: getCliente().id,
    }
    await saveLastUpdateByPark(park, false)
}

export const saveLastUpdateByPark = async (park, canSkip) => {
    const estacionamentoId = park.id
    const clienteId = park.clienteId
    const user = isAdministrador() === false ? getUsuario() : { email: "automação@site.com.br", nome: "Automação" }
    const result = await firebase.firestore().collection('last-update').where('clienteId', '==', clienteId).where('estacionamentoId', '==', estacionamentoId).get()
    if (result.empty) {
        await firebase.firestore().collection('last-update').add({
            clienteId: clienteId,
            estacionamentoId: estacionamentoId,
            revendaId: getRevendaIdByPark(),
            park: park,
            lastUpdate: new Date(),
            canSkip: canSkip,
            alterBy: {
                email: user.email,
                name: user.nome
            },
            isDeleted: false,
        })
    } else {
        await firebase.firestore().collection('last-update').doc(result.docs[0].id).update({
            lastUpdate: new Date(),
            park: park,
            deviceIdentifier: null,
            canSkip: canSkip,
            alterBy: {
                email: user.email,
                name: user.nome
            }
        })
    }
}
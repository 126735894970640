import { getRevenda, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { documentMask, isNullOrEmpty, normalizedField } from '../../shared/utils';
import { docsToItems } from '../transform.docs';

export const addCliente = async ({ nome, email, representante, phone, document, address, revendaId, welcomeEmail }) => {
    var data = {
        nome: nome,
        razaoSocial: nome,
        celular: phone,
        documento: documentMask(document),
        email: email,
        createdAt: new Date(),
        createdBy: email,
        representante: representante,
        revendaId: revendaId ?? getRevenda()?.id,
        habilitarEnviarCobrancaPorWhatsApp: true,
        habilitarEnviarCobrancaPorEmail: true,
        habilitarEmissaoDeNotaFiscal: true,
        welcomeEmail: welcomeEmail ?? true,
        habilitarEstabelecimentoPagamente: false,
        platform: "site",
        endereco: {
            cep: address?.cep,
            logradouro: address?.logradouro ?? "Endereço de exemplo",
            numero: address?.numero ?? "123",
            bairro: address?.bairro,
            cidade: address?.cidade,
            estado: address?.estado,
            ibge: address?.ibge,
            complemento: address?.complemento
        },
        status: "Ativo",
        leadStatus: "aguardando",
        tipoDocumento: normalizedField(document).length === 11 ? "CPF" : "CNPJ",
        isDeleted: false,
    }
    // return "xVWJ40Avw5hVNDefJl9d"
    const doc = await firebase.firestore().collection('clientes').add(data)
    return doc.id;
}

export const getClient = async ({ id }) => {
    if (id) {
        const client = await firebase.firestore().collection('clientes').doc(id).get()
        return {
            habilitarEnviarCobrancaPorWhatsApp: true,
            habilitarEnviarCobrancaPorEmail: true,
            habilitarEmissaoDeNotaFiscal: true,
            ...client.data(),
            id: client.id,
        }
    }
    return null
}

export const countClientByReseleId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection('clientes').where("revendaId", "==", revendaId).get()
    const data = docsToItems(result)
    return data.length
}

export const getAllClientsByRevendaId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection('clientes').where("revendaId", "==", revendaId).get()
    if (!result.empty) {
        return result.docs.map(e => {
            return {
                habilitarEnviarCobrancaPorWhatsApp: true,
                habilitarEnviarCobrancaPorEmail: true,
                habilitarEmissaoDeNotaFiscal: true,
                ...e.data(),
                id: e.id
            }
        })
    }
    return []
}

export const getAllClients = async () => {
    const result = await firebase.firestore().collection('clientes').get()
    return result.docs.map(e => {
        return {
            habilitarEnviarCobrancaPorWhatsApp: true,
            habilitarEnviarCobrancaPorEmail: true,
            habilitarEmissaoDeNotaFiscal: true,
            ...e.data(),
            id: e.id
        }
    })
}

export const getAllClientsByLimit = async ({ limit, revendaId, leadStatus }) => {
    var query = firebase.firestore().collection('clientes')
    if (!isNullOrEmpty(revendaId)) {
        query = query.where('revendaId', '==', revendaId)
    }
    if (!isNullOrEmpty(leadStatus)) {
        query = query.where('leadStatus', '==', leadStatus)
    }
    const result = await query.orderBy("createdAt", 'desc').limit(limit).get()
    return docsToItems(result).filter(e => e.status !== "Inativo")
}

export const getAllClientsByRevendaIdAndStatus = async ({ status, revendaId }) => {
    if (revendaId) {
        const result = await firebase.firestore().collection('clientes').where("status", "==", status).where("revendaId", "==", revendaId).get()
        return docsToItems(result)
    }
    return []
}

export const getAllClientsByStatus = async ({ status }) => {
    const result = await firebase.firestore().collection('clientes').where("status", "==", status).get()
    return docsToItems(result)
}

export const getAllClientsByRevendedorId = async ({ revendedorId }) => {
    const result = await firebase.firestore().collection('clientes').where("revendedor.id", "==", revendedorId).get()
    return docsToItems(result)
}

export const getAllClientsBySupportId = async ({ supportId }) => {
    const result = await firebase.firestore().collection('clientes').where("support.id", "==", supportId).get()
    return docsToItems(result)
}

export const updateClientRevendaId = async ({ id, revendaId }) => {
    await firebase.firestore().collection('clientes').doc(id).update({
        revendaId: revendaId,
        changeRevendaBy: getUsuario()?.nome,
        changeRevendaAt: new Date(),
        revendedorAssociatedAt: null,
        revendedor: null
    })
}

export const setClientUserToReseller = async ({ id, user }) => {
    await firebase.firestore().collection('clientes').doc(id).update({
        revendedorAssociatedAt: new Date(),
        revendedor: user
    })
}

export const updateClientStatus = async ({ id, status }) => {
    await firebase.firestore().collection('clientes').doc(id).update({
        status: status,
    })
}
export const updateClientById = async ({ id, data }) => {
    await firebase.firestore().collection('clientes').doc(id).update(data)
}
import React from 'react'
import { NavBar } from '../../../components/navbar'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { reloadWindow } from '../../../shared/utils';
import { deleteDoc } from '../../../store/collections/collectionBaseWorker';

class QueueCallback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            queues: [],
            isShowLog: false,
            log: '',
            type: '',
            limit: 30
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var query = firebase.firestore().collection('callback-queue')
        if (this.state.type !== '') {
            query = query.where("status", "==", this.state.type)
        }
        try {
            const result = await query.orderBy("createAt", "desc").limit(parseInt(this.state.limit)).get()
            const docs = result.docs.map(e => {
                return {
                    ...e.data(),
                    id: e.id
                }
            })
            this.setState({ queues: docs.sort((a, b) => a.status?.localeCompare(b.status) || b.createAt.toDate() - a.createAt.toDate()) })
            this.setState({ isLoading: false })
        } catch (e) {
        }
    }

    renderStatus(status) {
        return (
            <>
                {status === 'in-queue' && <h5><span className="badge text-bg-warning btn-block text-white my-2">Aguardando</span></h5>}
                {status === 'processed' && <h5><span className="badge text-bg-success btn-block text-white my-2">Processada</span></h5>}
                {status === 'error' && <h5><span className="badge text-bg-danger btn-block text-white my-2">Erro</span></h5>}
            </>
        )
    }

    changeType = (e) => {
        this.setState({ type: e.target.value }, async () => {
            await this.load()
        })
    }

    changeLimit = (e) => {
        this.setState({ limit: e.target.value }, async () => {
            await this.load()
        })
    }

    showRetryModal = (item) => {
        this.setState({ log: JSON.stringify(item.body, undefined, 4) })
        if (item.errorLog !== undefined) {
            this.setState({ errorLog: JSON.stringify(item.errorLog, undefined, 4) })
        }
        this.setState({ currentItem: item })
        this.setState({ isShowRetry: true })
    }

    forceRetry = async () => {
        const item = this.state.currentItem
        await firebase.firestore().collection('callback-queue').doc(item.id).update({
            status: 'in-queue',
            lastUpdate: new Date(),
            times: item.times === undefined ? 2 : parseInt(item.times) + 1
        })
        reloadWindow()
    }

    showDetail = (item) => {
        var pretty = JSON.stringify(item.body, undefined, 4);
        this.setState({ log: pretty })
        this.setState({ isShowLog: true })
    }

    showResultDetail = (item) => {
        var pretty = JSON.stringify(item.result, undefined, 4);
        this.setState({ log: pretty })
        this.setState({ isShowLog: true })
    }

    removeDetail = async (item) => {
        this.setState({ queues: this.state.queues.filter(e => e.id !== item.id) })
        await deleteDoc({ collection: "callback-queue", id: item.id })
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Filas de processamentos
                        <div className='col-lg-3'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                        <option value={30}>30 registros</option>
                                        <option value={50}>50 registros</option>
                                        <option value={100}>100 registros</option>
                                        <option value={200}>200 registros</option>
                                        <option value={1000}>1000 registros</option>
                                    </select>
                                </div>
                                <div className='col-lg-6'>
                                    <select className='form-select form-select-sm' value={this.state.type} onChange={this.changeType}>
                                        <option value=''>Todas</option>
                                        <option value='in-queue'>Na fila</option>
                                        <option value='processed'>Processadas</option>
                                        <option value='error'>Error</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.queues.length === 0 && this.state.isLoading === false ?
                            <EmptyContent text='Nenhum item na fila com este filtro' />
                            :
                            this.state.isLoading === false ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td><strong>Origem</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Data</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Atualização</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Tentativas</strong></td>
                                                <td width="80"><strong>Status</strong></td>
                                                <td width="80"></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.queues.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Tooltip title={item.id} placement='top'>
                                                                <span>{item.origin}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>
                                                            {
                                                                item.lastUpdate !== undefined ? <span>{Moment(item.lastUpdate.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</span> :
                                                                    <span>Não disponível</span>
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>
                                                            {
                                                                item.times === undefined ? <span>1</span> : <span>{item.times}</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            {this.renderStatus(item.status)}
                                                        </td>
                                                        <td align='right'>
                                                            <div className="btn-group">
                                                                {
                                                                    item.status !== 'processed' &&
                                                                    <Tooltip title="Enviar novamente" placement='top'>
                                                                        <button type="button" onClick={() => { this.showRetryModal(item) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-sync" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip title="Dados enviados" placement='top'>
                                                                    <button type="button" onClick={() => { this.showDetail(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-arrow-up" />
                                                                    </button>
                                                                </Tooltip>
                                                                {
                                                                    item.result &&
                                                                    <Tooltip title="Dados recebidos" placement='top'>
                                                                        <button type="button" onClick={() => { this.showResultDetail(item) }} className="btn btn-success">
                                                                            <i className="fas fa-arrow-down" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip title="Remover item" placement='top'>
                                                                    <button type="button" onClick={() => { this.removeDetail(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <Dialog maxWidth={'md'} onClose={(e) => this.setState({ isShowRetry: false })} open={this.state.isShowRetry}>
                    <MuiDialogContent>
                        <h6>Deseja mesmo executar novamente o processo abaixo?</h6>
                        <textarea className="form-control" cols={100} rows={20} disabled>
                            {this.state.log}
                        </textarea>
                        {
                            this.state.errorLog !== undefined &&
                            <textarea className="form-control" cols={100} rows={10} disabled>
                                {this.state.errorLog}
                            </textarea>
                        }
                        <div className='my-3'>
                            <h5>Origem: {this.state.currentItem?.origin}</h5>
                        </div>
                        <div className='btn-group mb-3'>
                            <button onClick={(e) => this.setState({ isShowRetry: false })} className='btn btn-secondary'>Cancelar</button>
                            <button onClick={(e) => this.forceRetry()} className='btn btn-danger'>Confirmar</button>
                        </div>
                    </MuiDialogContent>
                </Dialog>
                <Dialog maxWidth="lg" onClose={(e) => this.setState({ isShowLog: false })} open={this.state.isShowLog}>
                    <MuiDialogTitle className='text-center'>
                        Detalhes do log
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <textarea className="form-control" cols={100} rows={20} disabled>
                            {this.state.log}
                        </textarea>
                        {
                            this.state.errorLog !== undefined &&
                            <textarea className="form-control" cols={100} rows={10} disabled>
                                {this.state.errorLog}
                            </textarea>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={(e) => this.setState({ isShowLog: false })} className='btn btn-secondary'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
            </NavBar>)
    }
}


export { QueueCallback };
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { clearSession, isLogado } from "./auth";

import { FazerLogin, CriarConta, ContaCriada, TermsUpdated, EncerrarConta } from '../view/conta'
import Home from '../view/home'

import { CadastrarCliente, VisualizarCliente } from '../view/cliente'
import { EditarEstacionamento } from '../view/estacionamento/'
import { CriarProduto } from '../view/produto'
import { CriarPerfil } from '../view/perfil/perfil'
import { CriarUsuario } from '../view/usuario';
import { CriarMensalista } from '../view/mensalista/clientes';
import { DetalhesMensalista } from '../view/mensalista/clientes/detalhes';
import { CriarPlano } from '../view/mensalista/planos';
import { CriarCredenciado } from '../view/credenciados';
import { CriarConvenio } from '../view/convenio';
import { CriarFormasPagamentos } from '../view/configuracoes/formas-pagamentos/index'
import { CriarSetores } from '../view/setores'
import { ListarParametros, ListarParametrosCobrancas } from '../view/parametros'
import { CriarTabela } from "../view/tabela";
import { ReportCashier } from "../view/relatorios/cashier"
import { ReportMonthly } from "../view/relatorios/mensalistas"
import { ReportPerformance } from "../view/relatorios/performance"
import { DetailCashier } from "../view/cashier/detail"
import { TicketSearch } from "../view/buscas/ticketSearch"
import { CashierSearch } from "../view/buscas/cashierSearch"
import { MonthlySearch } from "../view/buscas/monthlySearch"
import { Marketing } from "../view/marketing/index"
import { CriarSupportArticle, ListarTreinamento } from "../view/support/index"
import ReportProdutos from '../view/relatorios/produtos'
import { NoteList } from '../view/nota-fiscal/listar'
import { RPSListar } from '../view/nota-fiscal/rps-configurar'
import { MinhaLicenca, LicencaRenvendasDashboard } from '../view/licenca/my-licence'
import { QueueCallback } from '../view/developer/queues/queues'
import { WhatsAppMessageList } from '../view/developer/messages/index'
import { MinhaLicencaSucesso } from '../view/licenca/successo'
import { RevendaFinanceiroGerenciarPlanos } from '../view/revenda/financeiro/gerenciar-planos'
import { RevendaFinanceiroGerenciarCobrancas } from '../view/revenda/financeiro/gerenciar-cobrancas'
import { ListarAnalytics } from '../view/analytics/logs'

//Renvenda
import HomeRevenda from '../view/revenda/home/revenda-home'
import { RevendedoresRevenda } from "../view/revenda/revendedores/index";
import { SupportCenterRevenda } from "../view/revenda/support-center";
import { ListarChamados } from "../view/helpdesk/index";
import { ListarContratos } from "../view/contratos";
import { AnalyticsLogin } from "../view/analytics/users";
import { AnalyticsConsumo } from "../view/analytics/consumo";
import { AnalyticsCaixas } from "../view/analytics/caixas";
import { AnalyticsMensalistas } from "../view/analytics/mensalistas";
import { ListarNotasRevendas } from "../view/revenda/notas/index";

//Pagamentos
import { PagamentosResumo } from "../view/pagamentos/resumo";

// Nota Fisca
import { ConfigurarNota } from '../view/nota-fiscal/configurar'
import { ConfigurarNotaNovo } from '../view/nota-fiscal/novo/configurar'
import { NotaFiscalMunicipiosHomologados } from '../view/nota-fiscal/homologados'

//Desenvolvedor
import { DeveloperRemoteConfig } from '../view/developer/remote-config'
import { DeveloperMinimumVersion } from '../view/developer/minimum-version'
import { DeveloperLastUpdate } from '../view/developer/table-update'
import { FactoriesAndModels } from '../view/developer/factories-models'
import { ListarRevendas, RevendaDetalhes } from "../view/platform/revenda";
import { TermosDeUsoPolitica } from "../view/termos";
import { TermosDeUsoPoliticaParkingSystem } from "../view/termos/index-parkingsystem";
import { ListarNotaNovo } from "../view/nota-fiscal/novo";
import { isAllowedByPage } from "./permissions";
import { TermosDeUsoPoliticaNeoTagus } from "../view/termos/index-neo-tagus";
import { DeveloperApplications } from "../view/developer/applications";

//equipamentos
import { CancelaListarEquipamentos } from "../view/equipamentos/equipamentos";
import { CancelaListarCamerasLPR } from "../view/equipamentos/lpr";
import { EquipamentosTutoriais } from "../view/equipamentos/tutoriais";
import { ReportAccess } from "../view/relatorios/acessos";
import { DeveloperBackups } from "../view/developer/backup";
import { CancelaListarAparelhos } from "../view/equipamentos/aparelhos";

const PermissionRouter = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    if (isLogado()) {
      const allowed = isAllowedByPage()
      if (allowed) {
        return <Component {...props} />
      } else {
        return <Redirect {...props} to={{
          pathname: "/home",
          state: { from: props.location }
        }} />
      }
    } else {
      var url = '?redirect_to='
      if (props.location.pathname !== null) {
        url += props.location.pathname
      }
      if (props.location.search !== null) {
        url += props.location.search
      }
      clearSession()
      return <Redirect {...props} to={{
        pathname: "/" + url,
        state: { from: props.location }
      }} />
    }
  }}
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={FazerLogin} />
      <Route exact path='/conta/criar-conta' component={CriarConta} />
      <Route exact path='/conta/encerrar-conta' component={EncerrarConta} />
      <Route exact path='/conta/bem-vindo' component={ContaCriada} />
      <PermissionRouter exact path='/conta/termos' component={TermsUpdated} />
      <Route exact path='/nota-fiscal/municipios-homologados' component={NotaFiscalMunicipiosHomologados} />
      <Route exact path='/termos-de-uso-e-politica-de-privacidade' component={TermosDeUsoPolitica} />
      <Route exact path='/termos-de-uso-e-politica-de-privacidade-parking-system' component={TermosDeUsoPoliticaParkingSystem} />
      <Route exact path='/neo-tagus/termos-de-uso-e-politica-de-privacidade' component={TermosDeUsoPoliticaNeoTagus} />
      <PermissionRouter exact path='/home' component={Home} />
      <PermissionRouter exact path='/pagamentos/resumo' component={PagamentosResumo} />
      <PermissionRouter exact path='/treinamento-online' component={ListarTreinamento} />
      <PermissionRouter exact path='/cadastrar/usuario' component={CriarUsuario} />
      <PermissionRouter exact path='/cadastrar/perfil' component={CriarPerfil} />
      <PermissionRouter exact path='/cadastrar/mensalista' component={CriarMensalista} />
      <PermissionRouter path='/visualizar/mensalista/:id' component={DetalhesMensalista} />
      <PermissionRouter exact path='/cadastrar/plano' component={CriarPlano} />
      <PermissionRouter exact path='/cadastrar/convenio' component={CriarConvenio} />
      <PermissionRouter exact path='/cadastrar/tabela-preco' component={CriarTabela} />
      <PermissionRouter exact path='/cadastrar/produto' component={CriarProduto} />
      <PermissionRouter exact path='/cadastrar/forma-pagamento' component={CriarFormasPagamentos} />
      <PermissionRouter exact path='/cadastrar/setor' component={CriarSetores} />
      <PermissionRouter exact path='/cadastrar/credenciado' component={CriarCredenciado} />
      <PermissionRouter exact path='/operacao/aplicar-convenios' component={CriarCredenciado} />
      <PermissionRouter exact path='/v1/nota-fiscal/listar' component={NoteList} />
      <PermissionRouter exact path='/v1/nota-fiscal/visualizar-rps' component={RPSListar} />
      <PermissionRouter exact path='/v1/nota-fiscal/configurar' component={ConfigurarNota} />
      <PermissionRouter exact path='/nota-fiscal/listar' component={ListarNotaNovo} />
      <PermissionRouter exact path='/nota-fiscal/configurar' component={ConfigurarNotaNovo} />
      <PermissionRouter exact path='/licenca/minha-licenca' component={MinhaLicenca} />
      <PermissionRouter exact path='/licenca/assinatura-sucesso' component={MinhaLicencaSucesso} />
      <PermissionRouter exact path='/configuracao/parametros' component={ListarParametros} />
      <PermissionRouter exact path='/configuracao/cobrancas' component={ListarParametrosCobrancas} />
      <PermissionRouter exact path='/configuracao/dados-cadastrais' component={EditarEstacionamento} />
      <PermissionRouter path='/relatorios/vendas' component={ReportProdutos} />
      <PermissionRouter path='/relatorios/caixas' component={ReportCashier} />
      <PermissionRouter path='/relatorios/mensalistas' component={ReportMonthly} />
      <PermissionRouter path='/relatorios/acessos' component={ReportAccess} />
      <PermissionRouter path='/relatorios/performance' component={ReportPerformance} />
      <PermissionRouter path='/caixas/:id' component={DetailCashier} />
      <PermissionRouter path='/buscar/ticket' component={TicketSearch} />
      <PermissionRouter path='/buscar/caixas' component={CashierSearch} />
      <PermissionRouter path='/buscar/mensalistas' component={MonthlySearch} />
      <PermissionRouter path='/revenda/cliente/:id' component={VisualizarCliente} />
      <PermissionRouter exact path='/revenda/cliente' component={CadastrarCliente} />
      <PermissionRouter exact path='/revenda/artigos-suporte' component={CriarSupportArticle} />
      <PermissionRouter exact path='/revenda/home' component={HomeRevenda} />
      <PermissionRouter exact path='/revenda/analytics/logs' component={ListarAnalytics} />
      <PermissionRouter exact path='/revenda/analytics/usuarios' component={AnalyticsLogin} />
      <PermissionRouter exact path='/revenda/analytics/consumo' component={AnalyticsConsumo} />
      <PermissionRouter exact path='/revenda/analytics/caixas' component={AnalyticsCaixas} />
      <PermissionRouter exact path='/revenda/analytics/mensalistas' component={AnalyticsMensalistas} />
      <PermissionRouter exact path='/revenda/financeiro/gerenciar-assinaturas' component={LicencaRenvendasDashboard} />
      <PermissionRouter exact path='/revenda/revendedores' component={RevendedoresRevenda} />
      <PermissionRouter exact path='/revenda/central-suporte' component={SupportCenterRevenda} />
      <PermissionRouter exact path='/revenda/financeiro/gerenciar-planos' component={RevendaFinanceiroGerenciarPlanos} />
      <PermissionRouter exact path='/revenda/financeiro/gerenciar-cobrancas' component={RevendaFinanceiroGerenciarCobrancas} />
      <PermissionRouter exact path='/revenda/financeiro/notas-fiscais' component={ListarNotasRevendas} />
      <PermissionRouter path='/revenda/detalhes/:id' component={RevendaDetalhes} />
      <PermissionRouter path='/revenda/chamados' component={ListarChamados} />
      <PermissionRouter path='/revenda/contratos' component={ListarContratos} />
      <PermissionRouter exact path='/desenvolvimento/configuracoes-remotas' component={DeveloperRemoteConfig} />
      <PermissionRouter exact path='/desenvolvimento/versao-minima' component={DeveloperMinimumVersion} />
      <PermissionRouter exact path='/desenvolvimento/marcas-e-modelos' component={FactoriesAndModels} />
      <PermissionRouter exact path='/desenvolvimento/aplicacoes' component={DeveloperApplications} />
      <PermissionRouter exact path='/desenvolvimento/backups' component={DeveloperBackups} />
      <PermissionRouter exact path='/desenvolvimento/atualizacao' component={DeveloperLastUpdate} />
      <PermissionRouter exact path='/desenvolvimento/filas-de-callback' component={QueueCallback} />
      <PermissionRouter exact path='/desenvolvimento/mensagens-whatsapp' component={WhatsAppMessageList} />
      <PermissionRouter exact path='/plataforma/marketing' component={Marketing} />
      <PermissionRouter exact path='/plataforma/revendas' component={ListarRevendas} />
      <PermissionRouter exact path='/equipamentos/totens' component={CancelaListarEquipamentos} />
      <PermissionRouter exact path='/equipamentos/cameras-lpr' component={CancelaListarCamerasLPR} />
      <PermissionRouter exact path='/equipamentos/aparelhos' component={CancelaListarAparelhos} />
      <Route exact path='/equipamentos/tutoriais' component={EquipamentosTutoriais} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
import firebase from '../../config/firebase';
import { getEstacionamento, getUsuario, getCliente, getRevendaIdByPark } from '../../config/auth';
import Moment from 'moment';
import { getAllChagesByMonthlyId, getFirstPendingChageByMonthlyId, getLastPendingChageByMonthlyId } from './monthlyChagesWorker';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';

export const getMonthlyById = async (id) => {
    const result = await firebase.firestore().collection('mensalistas').doc(id).get()
    var monthly = docToItem(result)
    if (monthly) {
        const firstChange = await getFirstPendingChageByMonthlyId({ monthlyId: monthly.id })
        const lastChange = await getLastPendingChageByMonthlyId({ monthlyId: monthly.id })
        if (monthly.plano) {
            monthly.plano.dataFatura = firstChange?.changeDate
            monthly.plano.validadeContrato = lastChange?.changeDate
        }
        return monthly
    }
    return null
}

export const getMonthlyByDocumentAndParkId = async ({ document, estacionamentoId }) => {
    const result = await firebase.firestore().collection('mensalistas').where("documento", "==", document).where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItem(result)
}

export const getAllMonthly = async () => {
    const id = getCliente()?.id
    if (id === undefined) {
        return []
    }
    const result = await firebase.firestore().collection('mensalistas').where('clienteId', '==', id).get()
    return docsToItems(result)
}

export const getAllMonthliesMigrate = async () => {
    const result = await firebase.firestore().collection('mensalistas').get()
    return docsToItems(result)
}

export const getAllMonthlies = async () => {
    const result = await firebase.firestore().collection('mensalistas').get()
    return docsToItems(result)
}

export const getAllMonthlyByClientId = async (id) => {
    const result = await firebase.firestore().collection('mensalistas').where('clienteId', '==', id).get()
    return docsToItems(result)
}

export const getAllMonthlyWithChages = async () => {
    try {
        const result = await firebase.firestore().collection('mensalistas')
            .where('estacionamentoId', '==', getEstacionamento().id).get()
        var monthlies = docsToItems(result)
        for (let index = 0; index < monthlies.length; index++) {
            const chages = await getAllChagesByMonthlyId(monthlies[index].id)
            const chagesByMonthly = chages.filter(e => e.monthlyId = monthlies[index].id)
            monthlies[index].chages = {
                quantity: {
                    payed: chagesByMonthly.filter(e => e.payment).length,
                    payedTotal: chagesByMonthly.filter(e => e.payment).map(e => e.payment.total).reduce((a, b) => a + b, 0),
                    overdue: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).length,
                    overdueTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    pending: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).length,
                    pendingTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    changesTotal: chagesByMonthly.map(e => e.payment?.total ?? e.netValue).reduce((a, b) => a + b, 0),
                },
                status: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate > new Date()).length ? "Em dia" : "Atrasado",
                items: chagesByMonthly
            }
        }
        return monthlies
    } catch (error) {
    }
    return []
}

export const getAllMonthlyByRangeWithChages = async ({ startDate, endDate, estacionamentosIds }) => {
    try {
        var newStartDate = Moment(startDate).add(-1, 'days').toDate()
        var newEndDate = Moment(endDate).add(1, 'days').toDate()
        const result = await firebase.firestore().collection('mensalistas')
            .where('estacionamentoId', 'in', estacionamentosIds)
            .where('plano.dataFatura', '>=', newStartDate)
            .where('plano.dataFatura', '<=', newEndDate).get()
        var monthlies = docsToItems(result)
        for (let index = 0; index < monthlies.length; index++) {
            const chages = await getAllChagesByMonthlyId(monthlies[index].id)
            const chagesByMonthly = chages.filter(e => e.monthlyId = monthlies[index].id)
            monthlies[index].chages = {
                quantity: {
                    payed: chagesByMonthly.filter(e => e.payment).length,
                    payedTotal: chagesByMonthly.filter(e => e.payment).map(e => e.payment.total).reduce((a, b) => a + b, 0),
                    overdue: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).length,
                    overdueTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    pending: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).length,
                    pendingTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    changesTotal: chagesByMonthly.map(e => e.payment?.total ?? e.netValue).reduce((a, b) => a + b, 0),
                },
                status: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate > new Date()).length ? "Em dia" : "Atrasado",
                items: chagesByMonthly
            }
        }
        return monthlies
    } catch (error) {
    }
    return []
}

export const getAllMonthlyByParkId = async ({ estacionamentoId, withCharges }) => {
    const result = await firebase.firestore().collection('mensalistas').where('estacionamentoId', '==', estacionamentoId).get()
    const monthlies = docsToItems(result)
    if (withCharges) {
        for (const monthly of monthlies) {
            const firstChange = await getFirstPendingChageByMonthlyId({ monthlyId: monthly.id })
            const lastChange = await getLastPendingChageByMonthlyId({ monthlyId: monthly.id })
            if (monthly.plano) {
                monthly.plano.dataFatura = firstChange?.changeDate
                monthly.plano.validadeContrato = lastChange?.changeDate
            }
        }
    }
    return monthlies.sort((a, b) => a.nome.localeCompare(b.nome))
}

export const addMonthly = async ({ clientId, estacionamentoId, nome, documento, tipoDocumento, email, celular, telefone, endereco, dataFatura, diaVencimento, validadeContrato, plano }) => {
    const data = {
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        nome: nome,
        documento: documento,
        tipoDocumento: tipoDocumento,
        revendaId: getRevendaIdByPark(),
        email: email,
        celular: celular,
        telefone: telefone,
        endereco: {
            cep: endereco?.cep,
            logradouro: endereco?.logradouro,
            numero: endereco?.numero,
            bairro: endereco?.bairro,
            cidade: endereco?.cidade,
            estado: endereco?.estado,
            complemento: endereco?.complemento
        },
        plano: {
            validadeContrato: validadeContrato?.toDate() ?? Moment().add(365, 'days').toDate(),
            dataFatura: dataFatura.toDate(),
            diaVencimento: diaVencimento.toString(),
            horarioFim: plano?.horarioFim,
            horarioInicio: plano?.horarioInicio,
            planoId: plano?.id,
            nome: plano?.nome,
            valor: plano?.valorMensal
        },
        createdAt: new Date(),
        createdBy: getUsuario().email,
        isDeleted: false,
    }
    const doc = await firebase.firestore().collection('mensalistas').add(data)
    return doc.id
}

export const getAllMonthlyByParkIdMigration = async (id) => {
    const result = await firebase.firestore().collection('mensalistas').where('estacionamentoId', '==', id).get()
    return result.docs.map(e => {
        return { ...e.data(), id: e.id }
    })
}

export const getMonthlyByParkAndCardNumber = async ({ parkId, cardNumber }) => {
    const result = await firebase.firestore().collection('mensalistas').where('estacionamentoId', '==', parkId).where('cartao', '==', cardNumber).get()
    return result.docs.map(e => {
        return { ...e.data(), id: e.id }
    })
}

export const updateVencimentoFatura = async ({ mensalistaId, dataFatura }) => {
    var plano = {
        dataFatura: dataFatura
    }
    await firebase.firestore().collection("mensalistas").doc(mensalistaId).set({
        plano: plano
    }, { merge: true })
}

export const updateMonthly = async ({ id, data }) => {
    await firebase.firestore().collection("mensalistas").doc(id).update(data)
}

export const updateValidadeContrato = async ({ mensalistaId, validadeContrato }) => {
    var plano = {
        validadeContrato: validadeContrato
    }
    await firebase.firestore().collection("mensalistas").doc(mensalistaId).set({
        plano: plano
    }, { merge: true })
}

export const updateDueDate = async ({ mensalistaId, dueDate }) => {
    var plano = {
        dataFatura: dueDate
    }
    await firebase.firestore().collection("mensalistas").doc(mensalistaId).set({
        plano: plano
    }, { merge: true })
}
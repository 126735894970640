
import React from 'react'
import { NavBar } from '../../../components/navbar/index'
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import { ClientButton, EmptyContent, Loading } from '../../../components/common/commons'
import { getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../../config/auth'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import { addLicencePlan, deleteLicencePlan, getAllLicencePlan, getAllLicencePlansByAssociatedRessales, updateLicencePlan } from '../../../store/collections/planosLicencaWorker'
import { getAllClientsByRevendaIdAndStatus } from '../../../store/collections/clienteWorker';
import { getAllParksByClientId } from '../../../store/collections/parkWorker';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { TextField } from '@mui/material';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { addCoupon, deleteCouponById, getAllCoupons, getCouponByIdentifier } from '../../../store/collections/planosLicencaCupomWorker';
import { copyLinkAndShare, documentMask, reloadWithAlert, takeIfIsNotNullOrEmpty, toastWarning } from '../../../shared/utils';
import { sendLog } from '../../../shared/analytics';
import { AlertDanger, AlertInfo, AlertSecondary } from '../../../shared/alert-manager';

class RevendaFinanceiroGerenciarPlanos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            matrizId: "",
            revendaId: getUsuario()?.revendaId,
            email: getUsuario()?.email,
            isAdmin: isAdministrador(),
            isLoading: true,
            openModalCreatePlan: false,
            openModalCalculator: false,
            openModalCreateCoupon: false,
            nome: '',
            descricao: '',
            quantidadeUsuariosAtivos: '2',
            mensal: true,
            bimestral: false,
            trimestral: false,
            semestral: false,
            anual: false,
            valorMensal: 0.0,
            valorBimestral: 0.0,
            valorTrimestral: 0.0,
            valorSemestral: 0.0,
            valorAnual: 0.0,
            quantidadeVeiculosMes: '500',
            quantidadeMensagensMes: 20,
            quantidadeNotasMes: "20",
            diferenciais: '',
            destaques: '',
            status: 'ativo',
            tipo: 'privado',
            frequencia: 'mensal',
            ordem: 1,
            destaque: 'nao',
            clientes: [],
            dia1: true,
            dia5: true,
            dia10: true,
            dia15: true,
            dia20: true,
            dia25: true,
            calculatorBasic: 0,
            calculatorPlus: 0,
            calculatorGold: 0,
            calculatorTotal: 0,
            showCalculatorDetail: false,
            tipoCobranca: "estabelecimento",
            estabelecimentos: [],
            calculatorListBasic: {
                totals: []
            },
            calculatorListPlus: {
                totals: []
            },
            calculatorListGold: {
                totals: []
            },
            taxDiscount: {
                basic: [
                    0,
                    3.75,
                    6.50,
                    9.35,
                    13.72,
                    18.60,
                    29.90
                ],
                plus: [
                    0,
                    11.12,
                    16.89,
                    19.62,
                    26.68,
                    35.74,
                    46.19
                ],
                gold: [
                    0,
                    10.72,
                    16.01,
                    20.84,
                    26.10,
                    36.38,
                    40.02
                ]
            },
            list: [],
            couponType: "value",
            couponDueDate: new Moment(new Date()),
            couponQuantity: 0,
            couponPorcentage: 0.0,
            couponValue: 0.0,
            couponPayments: "1",
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const revendasAssociadas = getUsuario().revendasAssociadas ?? []
        this.setState({ revendasAssociadas: revendasAssociadas })
        const results = await getAllLicencePlansByAssociatedRessales({ associatedResales: revendasAssociadas })
        this.setState({ list: results });
        this.setState({ isLoading: false })
    }

    openModalCreatePlan = () => {
        this.setState({ openModalCreatePlan: true })
    }

    openModalCreateCoupon = () => {
        this.setState({ openModalCreateCoupon: true })
    }

    closeModalCreatePlan = () => {
        this.setState({ openModalCreatePlan: false })
    }

    closeModalCreateCoupon = () => {
        this.setState({ openModalCreateCoupon: false })
    }

    openModalCalculator = () => {
        this.setState({ openModalCalculator: true })
    }

    closeModalCalculator = () => {
        this.setState({ openModalCalculator: false })
    }

    createNewCoupon = async () => {
        const porcentage = parseFloat(this.state.couponPorcentage) > 100 ? 100 : parseFloat(this.state.couponPorcentage)
        const coupon = await getCouponByIdentifier({ identifier: this.state.couponIdentifier })
        if (!coupon) {
            await addCoupon({
                identifier: this.state.couponIdentifier,
                quantity: this.state.couponQuantity,
                type: this.state.couponType,
                porcentage: porcentage,
                value: this.state.couponValue,
                payments: this.state.couponPayments,
                dueDate: this.state.couponDueDate.toDate(),
                createdAt: new Date(),
                createdBy: getUsuarioCompact()
            })
            reloadWithAlert("Cupom cadastrado com sucesso.")
        } else {
            toastWarning("Já existe um cupom cadastrado com este nome.")
        }
    }

    removeCoupon = async (item) => {
        const confirm = window.confirm(`Deseja mesmo remover este cupom?`);
        if (confirm) {
            await deleteCouponById({ id: item.id })
            sendLog("Licença", `O usuário administrativo ${getUsuarioCompact().nome} removeu o cupom de desconto ${item.identifier}`, true, item)
            toastWarning("Cupom removido com sucesso")
            this.listAllCoupons()
        }
    }

    shareCoupon = (item) => {
        const url = window.location.host + "/licenca/minha-licenca?cupom=" + item.identifier
        copyLinkAndShare({
            title: item.nome,
            message: item.nome,
            url: url,
        })
    }

    listAllCoupons = async () => {
        this.setState({ isShowingAllCoupons: true })
        this.setState({ couponState: "loading" })
        const items = await getAllCoupons()
        if (items.length > 0) {
            this.setState({ allCoupons: items })
            this.setState({ couponState: "items" })
        } else {

            this.setState({ couponState: "empty" })
        }
    }

    createOrUpdatePlan = async () => {
        if (!this.checkFields()) {
            return
        }
        if (this.state.id) {
            await this.update()
        } else {
            await this.create()
        }
    }

    generatePlanData = () => {
        return {
            nome: this.state.nome,
            descricao: this.state.descricao,
            valor: this.state.valor,
            diferenciais: this.state.diferenciais,
            quantidadeUsuariosAtivos: this.state.quantidadeUsuariosAtivos,
            quantidadeVeiculosMes: this.state.quantidadeVeiculosMes,
            quantidadeMensagensMes: parseInt(this.state.quantidadeMensagensMes),
            quantidadeNotasMes: this.state.quantidadeNotasMes,
            destaque: this.state.destaque,
            destaques: this.state.destaques,
            status: this.state.status,
            ordem: this.state.ordem ?? 10000,
            tipo: this.state.tipo,
            frequencia: this.state.frequencia,
            dia1: this.state.dia1,
            dia5: this.state.dia5,
            dia10: this.state.dia10,
            dia15: this.state.dia15,
            dia20: this.state.dia20,
            dia25: this.state.dia25,
            matrizId: this.state.matrizId,
            estabelecimentos: this.state.estabelecimentos.map(e => { return { id: e.id, nome: takeIfIsNotNullOrEmpty(e.nomeInterno) ?? e.nome, documento: documentMask(e.documento ?? "") } }),
            mensal: this.state.mensal,
            bimestral: this.state.bimestral,
            trimestral: this.state.trimestral,
            semestral: this.state.semestral,
            anual: this.state.anual,
            valorMensal: this.state.valorMensal,
            valorBimestral: this.state.valorBimestral,
            valorTrimestral: this.state.valorTrimestral,
            valorSemestral: this.state.valorSemestral,
            valorAnual: this.state.valorAnual,
            tipoCobranca: this.state.tipoCobranca,
            esconderValores: this.state.esconderValores ?? false
        }
    }

    create = async () => {
        var data = this.generatePlanData()
        data.createdAt = new Date()
        data.createBy = this.state.email
        await addLicencePlan({ data: data })
        reloadWithAlert("Plano criado com sucesso!")
    }

    update = async () => {
        const data = this.generatePlanData()
        data.alteredAt = new Date()
        data.alteredBy = this.state.email
        await updateLicencePlan({ id: this.state.id, data: data })
        reloadWithAlert('Plano atualizado com sucesso!');
    }

    onRemove = async (item) => {
        if ((item.assinantes ?? []).length > 0) {
            toastWarning("Não é possível remover plano com assinantes ativos.")
            return
        }
        var confirm = window.confirm(`Você tem certeza que deseja remover o plano: ${item.nome}?`);
        if (confirm) {
            await deleteLicencePlan({ id: item.id })
            reloadWithAlert("Plano removido com sucesso!")
        }
    }

    onEdit = async (item) => {
        if (item.tipo === "privado-pre-selecionado") {
            const clientes = await getAllClientsByRevendaIdAndStatus({ status: "Ativo", revendaId: this.state.revendaId })
            this.setState({ clientes: clientes })
        }
        this.setState({ ...item })
        this.setState({ quantidadeNotasMes: item.quantidadeNotasMes ?? "20" })
        this.setState({ estabelecimentos: item.estabelecimentos?.map(e => { return { id: e.id, nome: e.nome, isChecked: true } }) ?? [] })
        this.openModalCreatePlan()
    }

    onShowAssociateds = (item) => {
        this.setState({ assinantes: item.assinantes })
        this.setState({ isShowingAssinantesModal: true })
    }

    hideAssociateds = () => {
        this.setState({ isShowingAssinantesModal: false })
    }

    onDuplicate = async (item) => {
        if (item.tipo === "privado-pre-selecionado") {
            const clientes = await getAllClientsByRevendaIdAndStatus({ status: "Ativo", revendaId: this.state.revendaId })
            this.setState({ clientes: clientes })
        }
        this.setState({ ...item })
        this.setState({ quantidadeNotasMes: item.quantidadeNotasMes ?? "20" })
        this.setState({ estabelecimentos: item.estabelecimentos?.map(e => { return { id: e.id, nome: e.nome, isChecked: true } }) ?? [] })
        this.setState({ id: null })
        this.openModalCreatePlan()
    }

    checkFields = () => {
        if (this.state.nome === '' || this.state.descricao === '') {
            toastWarning("Informe os dados obrigatórios.")
            return false
        }
        if (this.state.mensal === true && this.state.valorMensal === 0.0) {
            toastWarning("Informe o valor do plano mensal.")
            return false
        }
        if (this.state.bimestral === true && this.state.valorBimestral === 0.0) {
            toastWarning("Informe o valor do plano bimestral.")
            return false
        }
        if (this.state.trimestral === true && this.state.valorTrimestral === 0.0) {
            toastWarning("Informe o valor do plano trimestral.")
            return false
        }
        if (this.state.semestral === true && this.state.valorSemestral === 0.0) {
            toastWarning("Informe o valor do plano semestral.")
            return false
        }
        if (this.state.anual === true && this.state.valorAnual === 0.0) {
            toastWarning("Informe o valor do plano anual.")
            return false
        }
        if (this.state.tipo === "privado-pre-selecionado" && this.state.estabelecimentos.length === 0) {
            toastWarning("Você deve selecionar ao menos um estabelecimento para o tipo de plano 'pré selecionado'.")
            return false
        }
        return true
    }

    share = (item) => {
        const url = window.location.host + "/licenca/minha-licenca?type=plan&id=" + item.id
        copyLinkAndShare({
            title: item.nome,
            message: item.nome,
            url: url
        })
    }

    calculatorBasic = (value) => {
        this.setState({ calculatorBasic: Number(value) }, () => {
            this.calculateNow()
        })
    }

    calculatorPlus = (value) => {
        this.setState({ calculatorPlus: Number(value) }, () => {
            this.calculateNow()
        })
    }

    calculatorGold = (value) => {
        this.setState({ calculatorGold: Number(value) }, () => {
            this.calculateNow()
        })
    }

    applyDiscount = (price, percent) => {
        var resultado = (percent / 100) * price
        return {
            price: price,
            total: Number((price - resultado).toFixed(1)),
            percent: percent,
            discount: resultado
        }
    }

    calculatePlan = (quantity, price, discounts) => {
        var totals = []
        for (let index = 0; index < quantity; index++) {
            if (index < 4) {
                totals.push(this.applyDiscount(price, discounts[index]))
            } else if (index >= 4 && index <= 9) {
                totals.push(this.applyDiscount(price, discounts[4]))
            } else if (index >= 10 && index <= 19) {
                totals.push(this.applyDiscount(price, discounts[5]))
            } else {
                totals.push(this.applyDiscount(price, discounts[6]))
            }
        }
        return {
            total: totals.map((e) => e.total).reduce((a, b) => a + b, 0),
            totals: totals
        }
    }

    calculateNow = () => {
        const totalBasic = this.calculatePlan(this.state.calculatorBasic, 149.90, this.state.taxDiscount.basic)
        const totalPlus = this.calculatePlan(this.state.calculatorPlus, 269.90, this.state.taxDiscount.plus)
        const totalGold = this.calculatePlan(this.state.calculatorGold, 389.90, this.state.taxDiscount.gold)
        this.setState({ calculatorTotal: (totalBasic.total + totalPlus.total + totalGold.total).toFixed(2) })
        this.setState({ calculatorListBasic: totalBasic })
        this.setState({ calculatorListPlus: totalPlus })
        this.setState({ calculatorListGold: totalGold })
    }

    createPlanByCalculator = () => {
        this.setState({ openModalCalculator: false })
        this.setState({ openModalCreatePlan: true })
        this.setState({ valor: this.state.calculatorTotal })
    }

    renderCalculator = (name, object) => {
        return (
            <>
                {
                    object.total !== undefined && object.total !== 0.0 &&
                    <div className='row my-2'>
                        <div className="col-lg-12">
                            <h5>{name}</h5>
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td><strong>Estabelecimentos</strong></td>
                                            <td align="center"><strong>Porcentagem</strong></td>
                                            <td align="right"><strong>Desconto</strong></td>
                                            <td align="right"><strong>Total</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            object.totals.map((item, index) =>
                                                <tr>
                                                    <td>{index + 1}x</td>
                                                    <td align="center">
                                                        {item.percent}%
                                                    </td>
                                                    <td align="right">
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.discount} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                    </td>
                                                    <td align="right">
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td colspan="3"><strong>Sub-total</strong></td>
                                            <td align="right"><strong><NumberFormat decimalScale={2} fixedDecimalScale={true} value={object.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    getDiasVencimentos = (item) => {
        var dias = []
        if (item.dia1) {
            dias.push("1")
        }
        if (item.dia5) {
            dias.push("5")
        }
        if (item.dia10) {
            dias.push("10")
        }
        if (item.dia15) {
            dias.push("15")
        }
        if (item.dia20) {
            dias.push("20")
        }
        if (item.dia25) {
            dias.push("25")
        }
        return dias.join(", ")
    }

    getValores = (item) => {
        var valores = []
        if (item.mensal) {
            valores.push(item.valorMensal)
        }
        if (item.bimestral) {
            valores.push(item.valorBimestral)
        }
        if (item.trimestral) {
            valores.push(item.valorTrimestral)
        }
        if (item.semestral) {
            valores.push(item.valorSemestral)
        }
        if (item.anual) {
            valores.push(item.valorAnual)
        }
        return valores.map(e => `R$${e.toFixed(2)}`).join(", ")
    }

    changeTypePlan = async (e) => {
        this.setState({ tipo: e.target.value })
        if (e.target.value === "privado-pre-selecionado") {
            const clientes = await getAllClientsByRevendaIdAndStatus({ status: "Ativo", revendaId: this.state.revendaId })
            this.setState({ clientes: clientes })
            this.setState({ estabelecimentos: [] })
        }
    }

    changeRevenda = async (e) => {
        this.setState({ revendaId: e.target.value })
    }

    changeClientSelected = async (e) => {
        this.setState({ matrizId: e.target.value })
        const result = await getAllParksByClientId({ clientId: e.target.value })
        this.setState({ estabelecimentos: result })
    }

    onCheckPark = (isChecked, park, index) => {
        park.isChecked = isChecked
        let estabelecimentos = this.state.estabelecimentos
        estabelecimentos[index].isChecked = isChecked
        this.setState({ estabelecimentos: estabelecimentos })
    }

    updateCouponValue = (event, maskedvalue, floatvalue) => {
        this.setState({ couponValue: floatvalue })
    }

    couponDueDateChange = (newValue) => {
        this.setState({ couponDueDate: newValue })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Planos para assinatura
                            <span className='btn-group'>
                                <Tooltip title="Criar cupom de desconto" placement="top">
                                    <button type="button" onClick={this.openModalCreateCoupon} className="btn btn-sm btn-warning text-white">
                                        <i className="fas fa-ticket-alt mx-2" />
                                        Criar cupom
                                    </button>
                                </Tooltip>
                                <button type="button" onClick={this.openModalCreatePlan} className="btn btn-sm btn-primary">
                                    <i className="fas fa-add mx-2" />
                                    Criar plano
                                </button>
                            </span>
                        </div>
                        {
                            this.state.isLoading && <Loading />
                        }
                        {
                            !this.state.isLoading &&
                            <>
                                {
                                    this.state.list.length > 0 ?
                                        <table className="table-striped table-responsive table">
                                            <thead>
                                                <tr>
                                                    <td width={10}></td>
                                                    <td width={10}><strong>Ordem</strong></td>
                                                    <td align='center'><strong>Status</strong></td>
                                                    <td><strong>Nome</strong></td>
                                                    <td align='center'><strong>Tipo</strong></td>
                                                    <td align='center'><strong>Vencimentos</strong></td>
                                                    <td align='center'><strong>Valores</strong></td>
                                                    <td align='center'><strong>Assinantes</strong></td>
                                                    <td width={10}></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.list.map((item, index) =>
                                                        <tr key={index}>
                                                            <td width={10}>
                                                                {item.revendaId === "UhkTEfwE0GH4wpLpfZZm" && <span className="badge text-bg-success w-100">Dimep</span>}
                                                                {item.revendaId === "feHh4buNASnnh6ahmYJ3" && <span className="badge text-bg-primary w-100">Madis</span>}
                                                                {item.revendaId === "yDDlIMYGASii6Scv1Un8" && <span className="badge w-100" style={{ backgroundColor: "#44377b" }}>SelfParking</span>}
                                                                {item.revendaId === "bjhYBaWdULPY9XO7K0VH" && <span className="badge w-100" style={{ backgroundColor: "#1f2021" }}>NeoTagus</span>}
                                                            </td>
                                                            <td width={10} align='center'>
                                                                <Tooltip title={item.id} placement="top">
                                                                    <span>{item.ordem}</span>
                                                                </Tooltip>
                                                            </td>
                                                            <td align='center'>{item.status}</td>
                                                            <td>{item.nome}</td>
                                                            <td align='center'>{item.tipo}</td>
                                                            <td align='center'>{this.getDiasVencimentos(item)} </td>
                                                            <td align='center'>{this.getValores(item)} </td>
                                                            <td align='center'>{item.assinantes?.length ?? 0} </td>
                                                            <td align='right'>
                                                                <div className='btn-group'>
                                                                    <button type="button" className='btn btn-success' onClick={() => { this.share(item) }} >
                                                                        <i className="fas fa-share-alt" />
                                                                    </button>
                                                                    {
                                                                        (item.assinantes?.length ?? 0) > 0 &&
                                                                        <button type="button" onClick={() => { this.onShowAssociateds(item) }} className="btn btn-info">
                                                                            <i className="fas fa-list text-white" />
                                                                        </button>
                                                                    }
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                    <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                    <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table> :
                                        <EmptyContent text="Nenhum plano cadastrado até o momento" />
                                }
                            </>
                        }
                    </div>
                </NavBar >
                <Dialog onClose={this.hideAssociateds} fullWidth maxWidth='sm' open={this.state.isShowingAssinantesModal}>
                    <MuiDialogTitle className='text-center'>
                        Assinantes
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.assinantes && this.state.assinantes.length !== 0 ?
                                <table className="table table-responsive table-striped">
                                    <thead>
                                        <tr>
                                            <td><strong>Estabelecimento</strong></td>
                                            <td align='center'><strong>Desde</strong></td>
                                            <td width={30} align='center'></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.assinantes?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{item.nome}</td>
                                                    <td align='center'>{Moment(item.associatedAt.toDate()).format("DD/MM/YYYY")}</td>
                                                    <td>
                                                        <ClientButton item={item} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table> : <EmptyContent text="Nenhum assinante disponível" />
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={this.hideAssociateds} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
                <div>
                    <Dialog onClose={this.closeModalCalculator} maxWidth='xl' open={this.state.openModalCalculator}>
                        <MuiDialogTitle className='text-center'>
                            Calcular plano
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="alert alert-secondary" role="alert">
                                <strong>Atenção:</strong> O desconto é progressivo para cada novo estacionamento adicionado na matriz para planos Plus e Ouro.
                            </div>
                            <div className='row'>
                                <div className="col-lg-4">
                                    <label>Plano básico</label>
                                    <input type="number" min={0} className="form-control" onChange={(e) => this.calculatorBasic(e.target.value)} value={this.state.calculatorBasic} />
                                </div>
                                <div className="col-lg-4">
                                    <label>Plano plus</label>
                                    <input type="number" min={0} className="form-control" onChange={(e) => this.calculatorPlus(e.target.value)} value={this.state.calculatorPlus} />
                                </div>
                                <div className="col-lg-4">
                                    <label>Plano ouro</label>
                                    <input type="number" min={0} className="form-control" onChange={(e) => this.calculatorGold(e.target.value)} value={this.state.calculatorGold} />
                                </div>
                            </div>
                            {

                                this.state.showCalculatorDetail === false &&
                                <div className='col-lg-12'>
                                    <div className='row my-3'>
                                        <button type="button" onClick={(e) => { this.setState({ showCalculatorDetail: true }) }} className="btn btn-secondary btn-sm btn-block">
                                            Mostrar detalhes do calculo
                                            <i className="fas fa-plus mx-3" />
                                        </button>
                                    </div>
                                </div>
                            }
                            {
                                this.state.showCalculatorDetail &&
                                <>
                                    {this.renderCalculator("Plano básico", this.state.calculatorListBasic)}
                                    {this.renderCalculator("Plano plus", this.state.calculatorListPlus)}
                                    {this.renderCalculator("Plano ouro", this.state.calculatorListGold)}
                                </>
                            }
                            <div className='row'>
                                <div className="col-lg-12 my-3">
                                    <h6>Total</h6>
                                    <h4><NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.calculatorTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></h4>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group'>
                                {
                                    this.state.calculatorTotal !== 0.0 &&
                                    <button onClick={this.createPlanByCalculator} className='btn btn-success'>Criar plano</button>
                                }
                                <button onClick={this.closeModalCalculator} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.closeModalCreateCoupon} fullWidth maxWidth={this.state.isShowingAllCoupons ? 'md' : 'sm'} open={this.state.openModalCreateCoupon}>
                        <MuiDialogTitle className='text-center'>
                            Criar cupom de desconto
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className="col-lg-8">
                                    <label>Nome (Identificador único)</label>
                                    <input type="text" onChange={(e) => this.setState({ couponIdentifier: e.target.value.toUpperCase() })} value={this.state.couponIdentifier} className="form-control" />
                                </div>
                                <div className="col-lg-4">
                                    <label>Quantidade</label>
                                    <input type="number" onChange={(e) => this.setState({ couponQuantity: e.target.value })} value={this.state.couponQuantity} className="form-control" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Tipo</label>
                                    <select className='form-select' value={this.state.couponType} onChange={(e) => this.setState({ couponType: e.target.value })}>
                                        <option value='value'>Valor</option>
                                        <option value='porcentage'>Porcentagem</option>
                                    </select>
                                </div>
                                {
                                    this.state.couponType === 'value' ?
                                        <div className="col-lg-6">
                                            <label>Valor</label>
                                            <CurrencyInput value={this.state.couponValue} prefix="R$" className='form-control' onChangeEvent={this.updateCouponValue} />
                                        </div> :
                                        <div className="col-lg-6">
                                            <label>Porcentagem ( % )</label>
                                            <input type="text" onChange={(e) => this.setState({ couponPorcentage: e.target.value })} value={this.state.couponPorcentage} className="form-control" />
                                        </div>
                                }
                            </div>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Validade</label>
                                    <div>
                                        <MobileDatePicker
                                            label="-"
                                            inputFormat="DD/MM/yyyy"
                                            value={this.state.couponDueDate}
                                            onChange={this.couponDueDateChange}
                                            renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <label>Aplicar desconto no </label>
                                    <select className="form-select" onChange={(e) => this.setState({ couponPayments: e.target.value })} value={this.state.couponPayments} >
                                        <option value='1'>1º pagamento</option>
                                        <option value='2'>2º pagamento</option>
                                        <option value='3'>3º pagamento</option>
                                        <option value='4'>4º pagamento</option>
                                        <option value='5'>5º pagamento</option>
                                        <option value='6'>6º pagamento</option>
                                        <option value='7'>7º pagamento</option>
                                        <option value='8'>8º pagamento</option>
                                        <option value='9'>9º pagamento</option>
                                        <option value='10'>10º pagamento</option>
                                        <option value='11'>11º pagamento</option>
                                        <option value='12'>12º pagamento</option>
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.isShowingAllCoupons && <div>
                                    {
                                        this.state.couponState === "loading" && <Loading />
                                    }
                                    {
                                        this.state.couponState === "empty" && <EmptyContent text="Nenhum cupom cadastrado." />
                                    }
                                    {
                                        this.state.couponState === "items" &&
                                        <div className='mt-3'>
                                            <h5 className='text-center'>Cupons cadastrados</h5>
                                            <table className="table table-striped">
                                                <tr>
                                                    <td><strong>Nome</strong></td>
                                                    <td align='center'><strong>Tipo</strong></td>
                                                    <td align='center'><strong>Valor</strong></td>
                                                    <td align='center'><strong>Validade</strong></td>
                                                    <td align='center'><strong>Pagamentos</strong></td>
                                                    <td align='center'><strong>Quantidade</strong></td>
                                                    <td></td>
                                                </tr>
                                                <tbody>
                                                    {
                                                        this.state.allCoupons.map((item, index) =>
                                                            <tr>
                                                                <td>{item.identifier}</td>
                                                                <td align='center'>{item.type === "value" ? "Valor" : "Porcentagem"}</td>
                                                                <td align='center'>{item.type === "value" ? <span>R${item.value}</span> : <span>{item.porcentage}%</span>}</td>
                                                                <td align='center'>{Moment(item.dueDate.toDate()).format("DD/MM/YYYY")}</td>
                                                                <td align='center'>Até o {item.payments}º pagamento</td>
                                                                <td align='center'>{item.quantity}</td>
                                                                <td width={10}>
                                                                    <div className='btn-group'>
                                                                        <Tooltip title="Copiar link" placement="top">
                                                                            <button type="button" className='btn btn-success' onClick={() => { this.shareCoupon(item) }} >
                                                                                <i className="fas fa-share-alt" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Remover cupom" placement="top">
                                                                            <button type="button" onClick={() => { this.removeCoupon(item, index) }} className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.listAllCoupons} className='btn btn-secondary'>Ver todos</button>
                                <button onClick={this.closeModalCreateCoupon} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.createNewCoupon} className='btn btn-success'>Criar cupom</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.closeModalCreatePlan} maxWidth={this.state.tipo !== "privado-pre-selecionado" ? 'md' : "lg"} open={this.state.openModalCreatePlan}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.id ? "Atualizar plano" : "Criar plano"}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className={this.state.tipo !== "privado-pre-selecionado" ? 'col-md-6' : "col-md-4"}>
                                    <div className='row'>
                                        <div className="col-lg-12">
                                            <label>Tipo de plano</label>
                                            <select className="form-select" onChange={this.changeTypePlan} value={this.state.tipo} >
                                                <option value='publico'>Público</option>
                                                <option value='privado'>Privado</option>
                                                <option value='privado-pre-selecionado'>Privado pré selecionado</option>
                                            </select>
                                        </div>
                                        {
                                            this.state.tipo === "publico" && <div className='col-lg-12 mt-2'>
                                                <AlertDanger message="Este plano será visível para TODOS os clientes." />
                                            </div>
                                        }
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-3">
                                            <label>Ordem</label>
                                            <input type="number" onChange={(e) => this.setState({ ordem: e.target.value })} value={this.state.ordem} className="form-control" />
                                        </div>
                                        <div className="col-lg-9">
                                            <label>Nome</label>
                                            <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                        </div>
                                        <div className="col-lg-12">
                                            <label>Descrição</label>
                                            <textarea rows={3} onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao} className="form-control" />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Status</label>
                                            <select className="form-select" onChange={(e) => this.setState({ status: e.target.value })} value={this.state.status} >
                                                <option value='ativo'>Ativo</option>
                                                <option value='inativo'>Inativo</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Destaque</label>
                                            <select className="form-select" onChange={(e) => this.setState({ destaque: e.target.value })} value={this.state.destaque} >
                                                <option value='nao'>Não</option>
                                                <option value='sim'>Sim</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Diferenciais</label>
                                        <input type="text" placeholder='Separe por virgulas cada diferencial' onChange={(e) => this.setState({ diferenciais: e.target.value })} value={this.state.diferenciais} className="form-control" />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Destaques</label>
                                        <input type="text" placeholder='Separe por virgulas cada destaque' onChange={(e) => this.setState({ destaques: e.target.value })} value={this.state.destaques} className="form-control" />
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-13'>
                                            <label>Permissão de acesso</label>
                                            <select className="form-select" onChange={(e) => this.setState({ quantidadeUsuariosAtivos: e.target.value })} value={this.state.quantidadeUsuariosAtivos} >
                                                <option value='1'>Até 1 usuários</option>
                                                <option value='2'>Até 2 usuários</option>
                                                <option value='3'>Até 3 usuários</option>
                                                <option value='4'>Até 4 usuários</option>
                                                <option value='5'>Até 5 usuários</option>
                                                <option value='10'>Até 10 usuários</option>
                                                <option value='15'>Até 15 usuários</option>
                                                <option value='20'>Até 20 usuários</option>
                                                <option value='25'>Até 25 usuários</option>
                                                <option value='30'>Até 30 usuários</option>
                                                <option value='35'>Até 35 usuários</option>
                                                <option value='40'>Até 40 usuários</option>
                                                <option value='45'>Até 45 usuários</option>
                                                <option value='50'>Até 50 usuários</option>
                                            </select>
                                        </div>
                                        <div className='col-lg-12 mt-2'>
                                            <AlertInfo message="O ciclo representa o período de assinatura, mensal, bimestral, trimestral ou anual." >
                                                <div className='col-lg-12 mt-2'>
                                                    <label>Quantidade de registros no ciclo</label>
                                                    <select className="form-select" onChange={(e) => this.setState({ quantidadeVeiculosMes: e.target.value })} value={this.state.quantidadeVeiculosMes} >
                                                        <option value='100'>Até 100 registros por ciclo</option>
                                                        <option value='200'>Até 200 registros por ciclo</option>
                                                        <option value='300'>Até 300 registros por ciclo</option>
                                                        <option value='400'>Até 400 registros por ciclo</option>
                                                        <option value='500'>Até 500 registros por ciclo</option>
                                                        <option value='1000'>Até 1.000 registros por ciclo</option>
                                                        <option value='1500'>Até 1.500 registros por ciclo</option>
                                                        <option value='2500'>Até 2.500 registros por ciclo</option>
                                                        <option value='3500'>Até 3.500 registros por ciclo</option>
                                                        <option value='4500'>Até 4.500 registros por ciclo</option>
                                                        <option value='5000'>Até 5.000 registros por ciclo</option>
                                                        <option value='8000'>Até 8.000 registros por ciclo</option>
                                                        <option value='10000'>Até 10.000 registros por ciclo</option>
                                                        <option value='20000'>Até 20.000 registros por ciclo</option>
                                                        <option value='30000'>Até 30.000 registros por ciclo</option>
                                                        <option value='40000'>Até 40.000 registros por ciclo</option>
                                                        <option value='50000'>Até 50.000 registros por ciclo</option>
                                                        <option value='60000'>Até 60.000 registros por ciclo</option>
                                                        <option value='70000'>Até 70.000 registros por ciclo</option>
                                                        <option value='80000'>Até 80.000 registros por ciclo</option>
                                                        <option value='90000'>Até 90.000 registros por ciclo</option>
                                                        <option value='100000'>Até 100.000 registros por ciclo</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <label>Quantidade de notas no ciclo</label>
                                                    <select className="form-select" onChange={(e) => this.setState({ quantidadeNotasMes: e.target.value })} value={this.state.quantidadeNotasMes} >
                                                        <option value='0'>Sem notas</option>
                                                        <option value='10'>Até 10 notas por ciclo</option>
                                                        <option value='20'>Até 20 notas por ciclo</option>
                                                        <option value='50'>Até 50 notas por ciclo</option>
                                                        <option value='100'>Até 100 notas por ciclo</option>
                                                        <option value='150'>Até 150 notas por ciclo</option>
                                                        <option value='200'>Até 200 notas por ciclo</option>
                                                        <option value='250'>Até 250 notas por ciclo</option>
                                                        <option value='300'>Até 300 notas por ciclo</option>
                                                        <option value='350'>Até 350 notas por ciclo</option>
                                                        <option value='400'>Até 400 notas por ciclo</option>
                                                        <option value='1000'>Até 1.000 notas por ciclo</option>
                                                        <option value='1500'>Até 1.500 notas por ciclo</option>
                                                        <option value='2500'>Até 2.500 notas por ciclo</option>
                                                        <option value='3500'>Até 3.500 notas por ciclo</option>
                                                        <option value='4500'>Até 4.500 notas por ciclo</option>
                                                        <option value='5000'>Até 5.000 notas por ciclo</option>
                                                        <option value='8000'>Até 8.000 notas por ciclo</option>
                                                        <option value='10000'>Até 10.000 notas por ciclo</option>
                                                        <option value='15000'>Até 15.000 notas por ciclo</option>
                                                        <option value='20000'>Até 20.000 notas por ciclo</option>
                                                        <option value='25000'>Até 25.000 notas por ciclo</option>
                                                        <option value='30000'>Até 30.000 notas por ciclo</option>
                                                    </select>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <label>Quantidade de mensagens no ciclo</label>
                                                    <select className="form-select" onChange={(e) => this.setState({ quantidadeMensagensMes: e.target.value })} value={this.state.quantidadeMensagensMes} >
                                                        <option value='0'>Sem mensagens</option>
                                                        <option value='20'>Até 20 mensagens por ciclo</option>
                                                        <option value='50'>Até 50 mensagens por ciclo</option>
                                                        <option value='100'>Até 100 mensagens por ciclo</option>
                                                        <option value='150'>Até 150 mensagens por ciclo</option>
                                                        <option value='200'>Até 200 mensagens por ciclo</option>
                                                        <option value='250'>Até 250 mensagens por ciclo</option>
                                                        <option value='300'>Até 300 mensagens por ciclo</option>
                                                        <option value='350'>Até 350 mensagens por ciclo</option>
                                                        <option value='400'>Até 400 mensagens por ciclo</option>
                                                        <option value='1000'>Até 1.000 mensagens por ciclo</option>
                                                        <option value='1500'>Até 1.500 mensagens por ciclo</option>
                                                        <option value='2500'>Até 2.500 mensagens por ciclo</option>
                                                        <option value='3500'>Até 3.500 mensagens por ciclo</option>
                                                        <option value='4500'>Até 4.500 mensagens por ciclo</option>
                                                        <option value='5000'>Até 5.000 mensagens por ciclo</option>
                                                        <option value='8000'>Até 8.000 mensagens por ciclo</option>
                                                        <option value='10000'>Até 10.000 mensagens por ciclo</option>
                                                        <option value='15000'>Até 15.000 mensagens por ciclo</option>
                                                        <option value='20000'>Até 20.000 mensagens por ciclo</option>
                                                        <option value='25000'>Até 25.000 mensagens por ciclo</option>
                                                        <option value='30000'>Até 30.000 mensagens por ciclo</option>
                                                        <option value='35000'>Até 35.000 mensagens por ciclo</option>
                                                        <option value='40000'>Até 40.000 mensagens por ciclo</option>
                                                        <option value='45000'>Até 45.000 mensagens por ciclo</option>
                                                        <option value='50000'>Até 50.000 mensagens por ciclo</option>
                                                    </select>
                                                </div>
                                            </AlertInfo>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.tipo !== "privado-pre-selecionado" ? 'col-md-6' : "col-md-4"}>
                                    <div className='col-lg-12 mt-3'>
                                        <label>Vencimento</label>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia1" checked={this.state.dia1} onChange={(e) => this.setState({ dia1: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia1">
                                                Dia 1
                                            </label>
                                        </div>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia5" checked={this.state.dia5} onChange={(e) => this.setState({ dia5: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia5">
                                                Dia 5
                                            </label>
                                        </div>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia10" checked={this.state.dia10} onChange={(e) => this.setState({ dia10: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia10">
                                                Dia 10
                                            </label>
                                        </div>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia15" checked={this.state.dia15} onChange={(e) => this.setState({ dia15: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia15">
                                                Dia 15
                                            </label>
                                        </div>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia20" checked={this.state.dia20} onChange={(e) => this.setState({ dia20: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia20">
                                                Dia 20
                                            </label>
                                        </div>
                                        <div className="form-check form-switch form-check-inline">
                                            <input className="form-check-input" id="dia25" checked={this.state.dia25} onChange={(e) => this.setState({ dia25: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="dia25">
                                                Dia 25
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-lg-12 mt-3'>
                                        <label>Frequências e valores</label>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div className='col-lg-4'>
                                            <div className="form-check form-switch form-check-inline">
                                                <input className="form-check-input" id="mensal" checked={this.state.mensal} onChange={(e) => this.setState({ mensal: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="mensal">
                                                    Mensal
                                                </label>
                                            </div>
                                            {
                                                this.state.mensal &&
                                                <CurrencyInput value={this.state.valorMensal} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valorMensal: floatvalue })} />
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="form-check form-switch form-check-inline">
                                                <input className="form-check-input" id="bimestral" checked={this.state.bimestral} onChange={(e) => this.setState({ bimestral: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="bimestral">
                                                    Bimestral
                                                </label>
                                            </div>
                                            {
                                                this.state.bimestral &&
                                                <CurrencyInput value={this.state.valorBimestral} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valorBimestral: floatvalue })} />
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="form-check form-switch form-check-inline">
                                                <input className="form-check-input" id="trimestral" checked={this.state.trimestral} onChange={(e) => this.setState({ trimestral: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="trimestral">
                                                    Trimestral
                                                </label>
                                            </div>
                                            {
                                                this.state.trimestral &&
                                                <CurrencyInput value={this.state.valorTrimestral} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valorTrimestral: floatvalue })} />
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="form-check form-switch form-check-inline">
                                                <input className="form-check-input" id="semestral" checked={this.state.semestral} onChange={(e) => this.setState({ semestral: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="semestral">
                                                    Semestral
                                                </label>
                                            </div>
                                            {
                                                this.state.semestral &&
                                                <CurrencyInput value={this.state.valorSemestral} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valorSemestral: floatvalue })} />
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="form-check form-switch form-check-inline">
                                                <input className="form-check-input" id="anual" checked={this.state.anual} onChange={(e) => this.setState({ anual: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="anual">
                                                    Anual
                                                </label>
                                            </div>
                                            {
                                                this.state.anual &&
                                                <CurrencyInput value={this.state.valorAnual} prefix="R$" className='form-control' onChangeEvent={(e, b, floatvalue) => this.setState({ valorAnual: floatvalue })} />
                                            }
                                        </div>
                                        <div className='col-lg-12 mt-3'>
                                            <AlertInfo message="Você pode esconder o valor deste plano para os clientes.">
                                                <div className='col-lg-12'>
                                                    <div className="form-check form-switch form-check-inline">
                                                        <input className="form-check-input" id="esconderValores" checked={this.state.esconderValores} onChange={(e) => this.setState({ esconderValores: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="esconderValores">
                                                            Esconder valores
                                                        </label>
                                                    </div>
                                                </div>
                                            </AlertInfo>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.tipo === "privado-pre-selecionado" &&
                                    <div className='col-lg-4'>
                                        <div className='col-lg-12'>
                                            <label>Matriz</label>
                                            <select className="form-select" value={this.state.matrizId} onChange={this.changeClientSelected}>
                                                <option value="">Selecionar matriz</option>
                                                {
                                                    this.state.clientes.map(e => <option value={e.id}>{e.nome}</option>)
                                                }
                                            </select>
                                            {
                                                this.state.matrizId !== "" &&
                                                <div className='mt-2'>
                                                    <label>Estabelecimentos</label>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Nome</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.estabelecimentos.map((item, index) =>
                                                                    <tr>
                                                                        <td>{item.nomeInterno ?? item.nome}</td>
                                                                        <td width={10}>
                                                                            <div className="form-check form-switch">
                                                                                <input className="form-check-input" checked={item.isChecked} onClick={(e) => this.onCheckPark(e.target.checked, item, index)} type="checkbox" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            <div className='mt-2'>
                                                <AlertSecondary message="Selecione os estabelecimentos que terão acesso a este plano." />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.closeModalCreatePlan} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.createOrUpdatePlan} className='btn btn-success'>{this.state.id ? "Atualizar plano" : "Criar plano"}</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </div>
            </>)
    }

}

export { RevendaFinanceiroGerenciarPlanos }
import { getRevendaIdByPark } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems, docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllLicencePlan = async () => {
    const result = await firebase.firestore().collection('planos-licenca-novo').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getAllLicencePlansByAssociatedRessales = async ({ associatedResales }) => {
    const result = await firebase.firestore().collection('planos-licenca-novo').get()
    const items = docsToItems(result)
    return items.filter(e => associatedResales.map(e => e.value).includes(e.revendaId)).sort((a, b) => b.revendaId.localeCompare(a.revendaId))
}

export const getAllPublicLicencePlan = async () => {
    const result = await firebase.firestore().collection('planos-licenca-novo').where('status', '==', 'ativo').where('tipo', '==', 'publico').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getFirstHighlightPlan = async () => {
    const result = await firebase.firestore().collection('planos-licenca-novo').where('status', '==', 'ativo').where('tipo', '==', 'publico').where('destaque', '==', 'sim').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getLicencePlanById = async ({ id }) => {
    const result = await firebase.firestore().collection('planos-licenca-novo').doc(id).get()
    return docToItem(result)
}

export const addLicencePlan = async ({ data }) => {
    data.revendaId = getRevendaIdByPark()
    const doc = await firebase.firestore().collection('planos-licenca-novo').add(data)
    return doc.id;
}

export const updateLicencePlan = async ({ id, data }) => {
    await firebase.firestore().collection('planos-licenca-novo').doc(id).update(data)
}

export const deleteLicencePlan = async ({ id }) => {
    await deleteDoc({ collection: "planos-licenca-novo", id: id })
}
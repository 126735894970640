import React from 'react';
import './licenca.css';
import firebase from '../../config/firebase';
import 'firebase/auth';
import { Redirect } from 'react-router-dom';
import { setSessao, isLogado, setCliente, setIsApp, isAdministrador } from '../../config/auth'
import { sendLastAccess } from '../../shared/user-collection';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const initializeState = {
    loading: false,
    usuario: '',
    senha: '',
    from: '',
    showDownload: false,
    redirect_to: null,
    msgDescription: ''
}

class MinhaLicencaSucesso extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
    }

    componentDidMount = () => {

    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="row">
                            <div className="sucesso-licenca-content d-flex align-items-center ">
                                <form className="mx-auto col-md-8 col-sm-10">
                                    <div className="image-container">
                                        <img width='100' height='100' alt='Logo SelfParking' src={require('../../files/icon_app_white.png')} />
                                    </div>
                                    <h1 className="h3 mb-3 font-weight-normal text-white text-center">Self<strong>Parking</strong></h1>
                                    <div className="row">
                                        <span className='span-item'><p />Processamos o seu pagamento com sucesso, continue utilizando todas as funcionalidades disponíveis no seu plano, qualquer dúvida é só entrar em contato no WhatsApp.</span>
                                    </div>
                                    <div className='my-2'>
                                        <a href="/home">
                                            <button className="btn btn-light btn-block" type="button">Voltar para o início</button>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export { MinhaLicencaSucesso };
import firebase from '../../config/firebase';
import { docsToItem, docsToItems } from '../transform.docs';

export const getAllContactInfoByClientId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection('contactInfo').where('clienteId', '==', clienteId).get()
    return docsToItems(result)
}

export const getContactInfoByPlateAndClientId = async ({ plate, clienteId }) => {
    const result = await firebase.firestore().collection('contactInfo').where('clienteId', '==', clienteId).where('plate', '==', plate.replaceAll("-").toUpperCase()).get()
    return docsToItem(result)
}

export const addOrUpdateContactInfo = async ({ data }) => {
    const exist = getContactInfoByPlateAndClientId({ plate: data.plate, clienteId: data.clienteId })
    if (!exist) {
        const doc = await addContactInfo({ data: data })
        return doc.id
    }
    return exist.id;
}

export const addContactInfo = async ({ data }) => {
    const doc = await firebase.firestore().collection('contactInfo').add(data)
    return doc.id;
}
import React from 'react'
import 'firebase/auth';
import 'firebase/storage';
import NumberFormat from 'react-number-format';
import Chart from "react-apexcharts";

class CashierRevenuesDashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mostrarGraficosVendasPorPagamento: true,
            formasPagamento: [],
            total: 0
        }
    }

    componentDidMount = async () => {
        var allTickets = this.props.tickets
        var mensalistas = this.props.caixa.monthlyPayments
        this.formasPagamentos(allTickets, mensalistas)
    }

    baseOptionsPie = () => {
        return {
            chart: {
                width: '100%',
                type: 'pie',
            },
            legend: {
                show: true,
                position: 'bottom'
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return 'R$' + value
                    }
                },
            },
        }
    }

    formasPagamentos = (list, mensalistas) => {
        const tickets = this.getAllPayments(list)
        if (tickets.length > 0) {
            var formasPagamentosGrouped = []
            var identifiers = new Set(tickets.filter(e => e.formaPagamento).map(e => e.formaPagamento?.id ?? "").filter(e => e !== ''))
            identifiers.forEach(identifier => {
                var ticketsPorFormaDePagamento = tickets.filter(e => e.formaPagamento?.id && e.formaPagamento?.id === identifier)
                if (ticketsPorFormaDePagamento) {
                    formasPagamentosGrouped.push({
                        nome: ticketsPorFormaDePagamento[0].formaPagamento.nome + " - Ticket",
                        total: tickets.filter(e => e.formaPagamento.id === identifier).map(e => e.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b)
                    })
                }
            })
            if (mensalistas) {
                var identifiersMensalistas = new Set(mensalistas.map(e => e.formaPagamento.id))
                identifiersMensalistas.forEach(identifier => {
                    var mensalistasPorFormaDePagamento = mensalistas.filter(e => e.formaPagamento.id === identifier)
                    formasPagamentosGrouped.push({
                        nome: mensalistasPorFormaDePagamento[0].formaPagamento.nome + " - Mensalista",
                        total: mensalistas.filter(e => e.formaPagamento.id === identifier).map(e => e.resumo.payment.total).reduce((a, b) => a + b)
                    })
                })
            }
            this.setState({ formasPagamento: formasPagamentosGrouped.sort((a, b) => b.quantidade - a.quantidade) })
            if (formasPagamentosGrouped.length > 0) {
                this.setState({ total: formasPagamentosGrouped.map(e => e.total).reduce((a, b) => a + b) })
            }
        }
    }

    optionsFormasPagamento = () => {
        return {
            ...this.baseOptionsPie(),
            labels: this.state.formasPagamento.map(e => e.nome),
        }
    }

    seriesFormasPagamento = () => {
        return this.state.formasPagamento.map(e => parseFloat(e.total.toFixed(2)))
    }

    getAllPayments = (tickets) => {
        var resumos = []
        if (tickets !== undefined) {
            resumos = tickets.map(e => e.paymentsLogged ?? []).flatMap(e => e).filter(e => e.canceled !== true).map(e => {
                return {
                    formaPagamento: e.formaPagamento,
                    resumo: e.resumo
                }
            }).filter(e => e.formaPagamento !== null)
        }
        return resumos;
    }

    render() {
        return (
            <>
                {
                    this.state.formasPagamento.length === 0 ?
                        <div className="col-lg-3">
                            <div className='row'>
                                <div className='col-lg-12 text-center my-5'>
                                    <img alt='Icone produtos vazios' width='100' height='100' src={require('../../files/icons/icon_cash_register.png')} />
                                    <h4 className='item-h4'>Nenhum pagamento efetuado até o momento.</h4>
                                </div>
                            </div>
                        </div> :
                        <div className="col-lg-3">
                            <div className="col-12 col-sm-12 col-lg-12">
                                <div className="card mb-4">
                                    <div className="card-title text-right">
                                        {
                                            this.state.mostrarGraficosVendasPorPagamento === false ?
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorPagamento: true })} className="btn btn-success btn-sm m-2">
                                                    <i className="fas fa-chart-pie" />
                                                </button>
                                                :
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorPagamento: false })} className="btn btn-success btn-sm m-2">
                                                    <i className="fas fa-table" />
                                                </button>
                                        }
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.mostrarGraficosVendasPorPagamento === true ?
                                                <>
                                                    <Chart
                                                        type="pie"
                                                        options={this.optionsFormasPagamento()}
                                                        height={200}
                                                        series={this.seriesFormasPagamento()} />
                                                    <div className="col-12 col-sm-12 col-lg-12 my-3">
                                                        <h5 className='text-secondary text-center'>Total de pagamentos no caixa: {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</h5>
                                                    </div>
                                                </> :
                                                <div className='row'>
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped ">
                                                            <tr>
                                                                <td><strong>Nome</strong></td>
                                                                <td align='right'><strong>Total</strong></td>
                                                            </tr>
                                                            <tbody>
                                                                {
                                                                    this.state.formasPagamento.map((formas, index) =>
                                                                        <tr key={index}>
                                                                            <td>{formas.nome}</td>
                                                                            <td align='right' >
                                                                                <NumberFormat value={formas.total} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </>
        )

    }
}

export { CashierRevenuesDashboard }
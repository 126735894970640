import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import Moment from 'moment';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addHistorico = async ({ mensalista, estacionamento, veiculos, caixa, pagamento, currentUser, type }) => {
    var raw = {
        clienteId: estacionamento.clienteId,
        revendaId: getRevendaIdByPark(),
        estacionamento: {
            id: estacionamento.id,
            nome: estacionamento.nome,
            celular: estacionamento.celular,
            documento: estacionamento.documento,
            horarios: estacionamento.horarios,
            endereco: `${estacionamento.endereco?.logradouro}, ${estacionamento.endereco?.numero}`
        },
        estacionamentoId: estacionamento.id,
        clienteNome: mensalista.clienteNome,
        plan: mensalista.plano,
        monthlyId: mensalista.id,
        vehicle: veiculos,
        cashier: (caixa !== undefined && caixa !== null) ? {
            id: caixa.id,
            openAt: caixa.openAt,
            openBy: caixa.openBy
        } : null,
        payment: pagamento,
        createBy: currentUser.email,
        isDeleted: false,
        createAt: new Date(),
        type: type,
        isDeleted: false,
    }
    await firebase.firestore().collection("monthlyHistory").add(raw)
}

export const registerChagesSequences = async ({ monthly, quantity, plano, proRata }) => {
    var calendar = Moment(plano.dataFatura)
    const cliente = getCliente()
    const park = getEstacionamento()
    const user = getUsuario()
    var proximaData = Moment(plano.dataFatura)
    proximaData.set("date", parseInt(plano.diaVencimento)).add(1, 'M')
    var totalPlan = (parseInt(monthly.vagas ?? 0) * (plano?.valor ?? 0))

    const intervalo = Math.floor((proximaData - plano.dataFatura) / (24 * 60 * 60 * 1000));
    for (let month = 0; month < quantity; month++) {
        var netValue = totalPlan
        if (month === 0 && proRata) {
            netValue = (totalPlan / 30) * intervalo
            //no app o proporcional está para baixo, então se checar em 30 ele considera o valor do plano e é isso
            //eu fiz considerando a qnt de dias até o proximo vencimento
            //ex: dataInicial = 15/01/2023 e proximaData = 10/02/2023, a primeira cobrança vai cobrar só 25 dias.
            //ex2: dataInicial = 10/01/2023 e proximaData = 20/02/2023, a primeira cobrança vai cobrar a mais, no total 40 dias.
        }
        if (month !== 0) {
            calendar = calendar.set("date", parseInt(plano.diaVencimento));
            calendar.add(1, 'M')
        }
        const change = {
            type: "admin-added",
            clienteId: cliente.id,
            clienteNome: monthly.nome,
            plan: plano,
            estacionamento: {
                nome: park.nome
            },
            estacionamentoId: park.id,
            monthlyId: monthly.id,
            createBy: user.email ?? "",
            netValue: parseFloat(netValue.toFixed(2)),
            changeDate: calendar.toDate(),
            status: "pending"
        }
        await addChage({ data: change })
    }
}

export const registerChagesSequencesMigration = async ({ monthly, plano, cliente, park, user, proRata, monthPayed }) => {
    const monthies = Math.ceil(Moment(plano.validadeContrato).diff(Moment(new Date()), 'months', true))
    var calendar = Moment(plano.dataFatura)
    var netValue = (parseInt(monthly.vagas ?? 0) * (plano?.valor ?? 0))

    for (let month = 0; month < monthies; month++) {
        if (month === 0 && !proRata) {
            continue
        }
        const thisMonth = Moment(calendar.toDate()).format("MM/YYYY") === Moment(new Date()).format("MM/YYYY")
        if (thisMonth && monthPayed) {
            calendar.add(1, 'M')
            continue
        }
        if (month === 0) {
            const dueCalendar = Moment(plano.dataFatura).set("date", parseInt(plano.diaVencimento));
            const days = dueCalendar.diff(calendar, 'days')
            if (days > 1 && plano?.valor) {
                netValue = (plano.valor / 30) * days
            }
        } else {
            calendar = calendar.set("date", parseInt(plano.diaVencimento));
        }
        const change = {
            type: "admin-added",
            clienteId: cliente.id,
            clienteNome: cliente.nome,
            plan: plano,
            estacionamento: {
                nome: park.nome
            },
            estacionamentoId: park.id,
            monthlyId: monthly.id,
            createBy: user.email ?? "",
            netValue: parseFloat(netValue.toFixed(2)),
            changeDate: calendar.toDate(),
            status: "pending"
        }
        await addChage({ data: change })
        calendar.add(1, 'M')
    }
}

export const removePendingChages = async ({ monthlyId }) => {
    const chages = await getAllPendingChagesByMonthlyId(monthlyId)
    for (const chage of chages) {
        await removeMonthlyChage(chage.id)
    }
}

export const removeAllPendingByClienteId = async (clienteId) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("clienteId", "==", clienteId).get()
    const chages = docsToItems(result)
    for (const chage of chages) {
        await removeMonthlyChage(chage.id)
    }
}
export const removeAllPendingMigration = async () => {
    const result = await firebase.firestore().collection('monthlyHistory').where("status", "==", "pending").get()
    const chages = docsToItems(result)
    for (const chage of chages) {
        await removeMonthlyChage(chage.id)
    }
}

export const removeMonthlyChage = async (id) => {
    await deleteDoc({ collection: "monthlyHistory", id: id })
}

export const changePlanChage = async ({ monthlyId, plano }) => {
    const chages = await getAllPendingChagesByMonthlyId(monthlyId)
    for (var chage of chages) {
        chage.plan = plano
        chage.netValue = parseFloat(plano.valor.toFixed(2))
        await updateChage({ id: chage.id, data: chage })
    }
}

export const changeClienteNomeChage = async ({ monthlyId, name }) => {
    const chages = await getAllChagesByMonthlyId(monthlyId)
    for (var chage of chages) {
        chage.clienteNome = name
        await updateChage({ id: chage.id, data: chage })
    }
}

export const changeDueDate = async ({ monthlyId, diaVencimento }) => {
    const chages = await getAllPendingChagesByMonthlyId(monthlyId)
    for (const chage of chages) {
        const dueCalendar = Moment(chage.changeDate.toDate()).set("date", parseInt(diaVencimento));
        chage.changeDate = dueCalendar.toDate()
        await updateChage({ id: chage.id, data: chage })
    }
}

export const addChage = async ({ data }) => {
    await firebase.firestore().collection("monthlyHistory").add(data)
}

export const updateChage = async ({ id, data }) => {
    await firebase.firestore().collection("monthlyHistory").doc(id).update(data)
}

export const getChargeById = async ({ id }) => {
    const result = await firebase.firestore().collection('monthlyHistory').doc(id).get()
    return docToItem(result)
}

export const getAllChagesByClientId = async ({ id }) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("clienteId", '==', id).get()
    return docsToItems(result).sort((a, b) => b.changeDate?.toDate() - a.changeDate?.toDate())
}

export const getAllChagesByMonthlyId = async (id) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("monthlyId", '==', id).get()
    return docsToItems(result).sort((a, b) => b.changeDate?.toDate() - a.changeDate?.toDate())
}

export const getAllPendingChagesByMonthlyId = async (id) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("monthlyId", '==', id).where("status", "==", "pending").get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getFirstPendingChageByMonthlyId = async ({ monthlyId }) => {
    try {
        const result = await firebase.firestore().collection('monthlyHistory')
            .where("monthlyId", '==', monthlyId)
            .where("status", "==", "pending")
            // .where("isDeleted", "==", false)
            .limit(1)
            .orderBy("changeDate", "asc").get()
        return docsToItem(result)
    } catch (error) {
        console.log(error);
    }
}

export const getLastPendingChageByMonthlyId = async ({ monthlyId }) => {
    try {
        const result = await firebase.firestore().collection('monthlyHistory')
            .where("monthlyId", '==', monthlyId)
            .where("status", "==", "pending")
            // .where("isDeleted", "==", false)
            .limit(1)
            .orderBy("changeDate", "desc").get()
        return docsToItem(result)
    } catch (error) {
        console.log(error);
    }
}
export const getAllChangesByCashierId = async ({ estacionamentoId, cashierId }) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("cashier.id", '==', cashierId).where("estacionamentoId", '==', estacionamentoId).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllPayedChangesByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('monthlyHistory').where("status", '==', "payed").where("estacionamentoId", '==', estacionamentoId).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllPayedChanges = async () => {
    const result = await firebase.firestore().collection('monthlyHistory').where("status", '==', "payed").get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllChangesByCashierIds = async ({ estacionamentoId, cashierIds }) => {
    var items = [];
    try {
        for (const id of cashierIds) {
            const item = await getAllChangesByCashierId({ estacionamentoId: estacionamentoId, cashierId: id })
            items.push(item)
        }
    } catch (error) {
    }
    return items.flatMap(e => e);
}

export const getAllChagesByDateRange = async ({ startDate, endDate, estacionamentoId }) => {
    const result = await firebase.firestore().collection('monthlyHistory')
        .where('changeDate', '>=', startDate)
        .where('changeDate', '<=', endDate)
        .where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllChagesByDatesRange = async ({ startDate, endDate, estacionamentosIds }) => {
    const result = await firebase.firestore().collection('monthlyHistory')
        .where('changeDate', '>=', startDate)
        .where('changeDate', '<=', endDate)
        .where('estacionamentoId', 'in', estacionamentosIds).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllChagesByParkId = async ({ parkId }) => {
    const result = await firebase.firestore().collection('monthlyHistory')
        .where('estacionamentoId', '==', parkId).get()
    return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
}

export const getAllChagesPayedCurrentMonth = async () => {
    const startDate = Moment().startOf('month').toDate()
    const endDate = Moment().endOf('month').toDate()
    try {
        const result = await firebase.firestore().collection('monthlyHistory')
            .where('createAt', '>=', startDate)
            .where('createAt', '<=', endDate)
            .where('status', '==', "payed")
            .where('estacionamentoId', '==', getEstacionamento().id).get()
        return docsToItems(result)
    } catch (error) {
    }
}

export const getAllChagesCurrentMonth = async () => {
    const startDate = Moment().startOf('month').toDate()
    const endDate = Moment().endOf('month').toDate()
    try {
        const result = await firebase.firestore().collection('monthlyHistory')
            .where('changeDate', '>=', startDate)
            .where('changeDate', '<=', endDate)
            .where('estacionamentoId', '==', getEstacionamento().id).get()
        return docsToItems(result)
    } catch (error) {
    }
}
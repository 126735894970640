import { getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';

export const addMonthlyPlan = async ({ clientId, estacionamentoId, vagasDisponiveis, valorMensal, nome, status, horarioInicio, horarioFim }) => {
    const data = {
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        nome: nome,
        horarioInicio: horarioInicio,
        horarioFim: horarioFim,
        vagasDisponiveis: vagasDisponiveis,
        revendaId: getRevendaIdByPark(),
        valorMensal: valorMensal,
        status: status ?? "Ativo",
        createdAt: new Date(),
        createdBy: getUsuario().email,
        isDeleted: false,
    }
    const doc = await firebase.firestore().collection('planos').add(data)
    return doc.id;
}

export const getPlanById = async (id) => {
    const result = await firebase.firestore().collection('planos').doc(id).get()
    return docsToItems(result)
}

export const getAllMonthlyPlans = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('planos').where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result)
}

export const updatePlan = async (id, data) => {
    await firebase.firestore().collection("planos").doc(id).update(data)
}

import React from 'react';
import { NavBar } from '../../../components/navbar'
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';
import { getRevenda, getRevendasAssociadas, getUsuarioCompact } from '../../../config/auth';
import { addSupportCenter, getAllSupportCentersByRessales, removeSupportCenterById, updateSupportCenterById } from '../../../store/collections/supportCenterWorker';
import { searchCEP } from '../../../shared/cepWorker';
import { getAllClientsBySupportId, getClient, updateClientById } from '../../../store/collections/clienteWorker';
import { sendLog } from '../../../shared/analytics';
import { reloadWithAlert, toastWarning } from '../../../shared/utils';

class SupportCenterRevenda extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            stateField: "loading",
            representative: "",
            cellphone: "",
            revendaId: getRevenda()?.id,
            isLoading: true,
            isOpenUpdateModal: false,
            items: [],
        }
        this.loadAll()
    }

    loadAll = async () => {
        this.setState({ stateField: "loading" })
        const associatedResales = getRevendasAssociadas()
        const items = await getAllSupportCentersByRessales({ associatedResales: associatedResales })
        if (items.length > 0) {
            this.setState({ items: items })
            this.setState({ stateField: "list" })
        } else {
            this.setState({ stateField: "empty" })
        }
    }

    onCreate = async () => {
        if (!this.validateFields()) {
            return
        }
        const user = getUsuarioCompact()
        var data = this.getData()
        data.createdAt = new Date()
        data.createdBy = user
        sendLog("Ticket", `O usuário ${user.nome} cadastrou a central de suporte ${data.name} em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}`, false, JSON.stringify(data))

        await addSupportCenter({ data: data })
        this.loadAll()
        this.hideModalToAddSupport()
    }

    onEdit = (item) => {
        this.setState({ currentItem: item })
        this.setState({ name: item.name })
        this.setState({ email: item.email })
        this.setState({ cellphone: item.cellphone })
        this.setState({ number: item.number })
        this.setState({ representative: item.representative })
        this.setState({ zipCode: item.zipCode })
        this.setState({ street: item.street })
        this.setState({ district: item.district })
        this.setState({ city: item.city })
        this.setState({ state: item.state })
        this.setState({ ibge: item.ibge })
        this.setState({ complement: item.complement })
        this.setState({ isOpenUpdateModal: true })
    }

    confirmEdit = async () => {
        if (!this.validateFields()) {
            return
        }
        const user = getUsuarioCompact()
        var data = this.getData()
        data.updatedAt = new Date()
        data.updatedBy = user
        sendLog("Ticket", `O usuário ${user.nome} atualizou a central de suporte ${data.name} em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}`, false, {
            before: this.state.currentItem,
            after: data
        })
        await updateSupportCenterById({ id: this.state.currentItem.id, data: data })
        reloadWithAlert("Central de suporte atualizado com sucesso.")
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover esta central de suporte?`);
        if (confirm) {
            const clientsAssociated = await getAllClientsBySupportId({ supportId: item.id })
            if (clientsAssociated.length === 0) {
                const user = getUsuarioCompact()
                sendLog("Ticket", `O usuário ${user.nome} removeu a central de suporte ${item.name} em ${Moment().format("DD/MM/YYYY [às] HH:mm:ss")}`, false, JSON.stringify(item))
                await removeSupportCenterById({ id: item.id })
                this.loadAll()
            } else {
                toastWarning(`Existem alguns clientes com esta central de suporte associada:${clientsAssociated.map(e => e.nome).join("\n")}`)
            }
        }
    }

    validateFields = () => {
        if (this.state.representative === "") {
            toastWarning("O nome do representante é obrigatório.")
            return false
        }
        if (this.state.cellphone === "") {
            toastWarning("O celular do da central de atendimento é obrigatório.")
            return false
        }
        return true
    }

    getData = () => {
        return {
            revendaId: this.state.revendaId,
            name: this.state.name,
            email: this.state.email,
            cellphone: this.state.cellphone,
            representative: this.state.representative,
            zipCode: this.state.zipCode,
            number: this.state.number,
            street: this.state.street,
            district: this.state.district,
            city: this.state.city,
            state: this.state.state,
            complement: this.state.complement,
            ibge: this.state.ibge
        }
    }

    showModalToAddSupport = () => {
        this.setState({ isOpenUpdateModal: true })
    }

    hideModalToAddSupport = () => {
        this.setState({ isOpenUpdateModal: false })
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ zipCode: result.cep })
            this.setState({ street: result.logradouro })
            this.setState({ district: result.bairro })
            this.setState({ city: result.localidade })
            this.setState({ state: result.uf })
            this.setState({ ibge: result.ibge })
        }
    }
    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className='card mb-4'>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Centrais de suporte
                                    <button type="button" onClick={this.showModalToAddSupport} className="btn btn-sm btn-warning text-white">
                                        <i className="fas fa-headset mx-2" />
                                        Adicionar central
                                    </button>
                                </div>
                                <div className='row'>
                                    {
                                        this.state.stateField === "loading" &&
                                        <div className='col-md-12'>
                                            <Loading />
                                        </div>
                                    }
                                    {
                                        this.state.stateField === "empty" &&
                                        <div className='col-md-12'>
                                            <EmptyContent text="Nenhuma central cadastrada até o momento." />
                                        </div>
                                    }
                                    {
                                        this.state.stateField === "list" &&
                                        <div className='col-md-12'>
                                            <div className='table-responsive'>
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Nome</th>
                                                            <th scope="col">Celular</th>
                                                            <th scope="col">E-mail</th>
                                                            <th scope="col">Desde</th>
                                                            <th scope="col" width="10"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.items.map(item =>
                                                                <tr key={item.id}>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.cellphone}</td>
                                                                    <td>{item.email ?? "Não disponível"}</td>
                                                                    <td>{item.createdAt ? Moment(item.createdAt.toDate()).format("DD/MM/YYYY") : "-"}</td>
                                                                    <td>
                                                                        <div className='btn-group'>
                                                                            <Tooltip role="button" title="Editar central" placement="top">
                                                                                <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                            <Tooltip role="button" title="Apagar central" placement="top">
                                                                                <a type="button" onClick={(e) => this.onRemove(item)} className="btn btn-danger text-white">
                                                                                    <i className="fas fa-trash" />
                                                                                </a>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog fullWidth maxWidth={'sm'} open={this.state.isOpenUpdateModal}>
                        <MuiDialogTitle className='text-center'>
                            Central de suporte
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Nome da central</label>
                                    <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                                </div>
                                <div className="col-lg-6">
                                    <label>Representante</label>
                                    <input type="text" onChange={(e) => this.setState({ representative: e.target.value })} value={this.state.representative} className="form-control" required />
                                </div>
                                <div className="col-lg-6">
                                    <label>Telefone</label>
                                    <NumberFormat className="form-control" value={this.state.cellphone} mask='_' onChange={(e) => this.setState({ cellphone: e.target.value })} format="(##)#####-####" />
                                </div>
                                <div className="col-lg-6">
                                    <label>E-mail</label>
                                    <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                </div>
                            </div>
                            <div className='form-group mt-3'>
                                <h4>Endereço</h4>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-lg-3">
                                    <label>CEP</label>
                                    <NumberFormat autoComplete='off' className="form-control" value={this.state.zipCode} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                </div>
                                <div className="col-lg-9">
                                    <label>Logradouro</label>
                                    <input type="text" onChange={(e) => this.setState({ street: e.target.value })} value={this.state.street} className="form-control" required />
                                </div>
                                <div className="col-lg-5">
                                    <label>Número</label>
                                    <input type="text" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} className="form-control" required />
                                </div>
                                <div className="col-lg-7">
                                    <label>Complemento</label>
                                    <input type="text" onChange={(e) => this.setState({ complement: e.target.value })} value={this.state.complement} className="form-control" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Bairro</label>
                                    <input type="text" onChange={(e) => this.setState({ district: e.target.value })} value={this.state.district} className="form-control" required />
                                </div>
                                <div className="col-lg-3">
                                    <label>Cidade</label>
                                    <input type="text" onChange={(e) => this.setState({ city: e.target.value })} value={this.state.city} className="form-control" required />
                                </div>
                                <div className="col-lg-3">
                                    <label>Estado</label>
                                    <input type="text" onChange={(e) => this.setState({ state: e.target.value })} value={this.state.state} className="form-control" required />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions className='m-3'>
                            <div className='btn-group'>
                                <button onClick={(e) => this.setState({ isOpenUpdateModal: false })} className='btn btn-secondary'>Fechar</button>
                                {
                                    this.state.currentItem ?
                                        <button onClick={this.confirmEdit} className='btn btn-success'>Atualizar</button>
                                        :
                                        <button onClick={this.onCreate} className='btn btn-success'>Cadastrar</button>
                                }
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
            </>)
    }
}

class SupportCenterRevendaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: props.clienteId,
            revendaId: getRevenda()?.id,
            isLoading: true,
            supportId: "",
            items: []
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true })
        const associatedResales = getRevendasAssociadas()
        const items = await getAllSupportCentersByRessales({ associatedResales: associatedResales })
        const client = await getClient({ id: this.state.clienteId })
        this.setState({ currentClient: client })
        this.setState({ items: items }, () => {
            if (client.support?.id) {
                this.selectSupportId(client.support.id)
            }
        })
    }

    confirm = async () => {
        if (!this.state.currentSupport) {
            toastWarning("Selecione uma central de suporte.")
            return
        }
        const client = this.state.currentClient
        client.support = {
            associatedAt: new Date(),
            associatedBy: getUsuarioCompact(),
            ...this.state.currentSupport
        }
        await updateClientById({ id: client.id, data: client })
        reloadWithAlert("Central de suporte associada com sucesso.")
    }

    selectSupportId = (value) => {
        this.setState({ supportId: value })
        const support = this.state.items.filter(e => e.id === value)[0]
        this.setState({ currentSupport: support })
    }

    onCloseModal = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <Dialog fullWidth maxWidth={"xs"} open={true}>
                <MuiDialogTitle className='text-center'>
                    Selecionar central de suporte
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className="col-lg-12">
                        <label>Central de suporte</label>
                        <select className='form-select' value={this.state.supportId} onChange={(e) => this.selectSupportId(e.target.value)}>
                            <option value="">Selecionar</option>
                            {
                                this.state.items?.map(item => { return <option value={item.id}>{item.name}</option> })
                            }
                        </select>
                    </div>
                    {
                        this.state.currentSupport &&
                        <div className="col-lg-12 mt-3">
                            <div className='alert alert-secondary'>
                                <label>Nome</label><br />
                                <strong>{this.state.currentSupport?.name}</strong><br />
                                <label>Celular</label><br />
                                <strong>{this.state.currentSupport?.cellphone}</strong><br />
                                <label>E-mail</label><br />
                                <strong>{this.state.currentSupport?.email ?? "Não disponível"}</strong>
                            </div>
                        </div>
                    }
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={this.onCloseModal} className='btn btn-secondary'>Fechar</button>
                        <button onClick={this.confirm} className='btn btn-success'>Confirmar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
        )
    }
}

export { SupportCenterRevenda, SupportCenterRevendaModal }
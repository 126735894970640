
import { getRevendaIdByPark, isOwner } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';

export const getLastLogs = async ({ limit }) => {
    var results = null
    // if (isPlatformOwner()) {
        results = await firebase.firestore().collection('analytics').orderBy("createdAt", "desc").limit(limit ?? 5).get()
    // } else {
    //     results = []// await firebase.firestore().collection('analytics').where("revendaId", "==", getRevendaIdByParkIdByPark()).where("developer", "==", false).orderBy("createdAt", "desc").limit(limit ?? 5).get()
    // }
    return docsToItems(results)
}
import { getRevenda, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { documentMask, normalizedField } from '../../shared/utils';
import { docsToItems, docToItem } from '../transform.docs';

export const addPark = async ({ clienteId, nome, email, phone, document, nomeInterno, representante, horarios, address, revendaId }) => {
    const data = {
        nome: nome,
        nomeInterno: nomeInterno ?? nome,
        celular: phone,
        documento: documentMask(document),
        horarios: horarios ?? "Horario de funcionamento",
        clienteId: clienteId,
        createdAt: new Date(),
        createdBy: email,
        representante: representante ?? nome,
        revendaId: revendaId ?? getRevenda()?.id,
        endereco: {
            cep: address?.cep,
            logradouro: address?.logradouro ?? "Endereço de exemplo",
            numero: address?.numero ?? "123",
            bairro: address?.bairro,
            cidade: address?.cidade,
            estado: address?.estado,
            ibge: address?.ibge,
            complemento: address?.complemento
        },
        status: "Ativo",
        tipoDocumento: normalizedField(document).length === 11 ? "CPF" : "CNPJ",
        isDeleted: false,
    }
    // return "1zkIuTpPOIroy8FvDHYG"
    const doc = await firebase.firestore().collection('estacionamentos').add(data)
    await firebase.firestore().collection('estacionamentos').doc(doc.id).update({
        id: doc.id
    })
    return doc.id;
}

export const countParksByReseleId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection('estacionamentos').where("revendaId", "==", revendaId).get()
    return docsToItems(result).length
}

export const getAllParksByClientId = async ({ clientId }) => {
    if (clientId) {
        const result = await firebase.firestore().collection('estacionamentos').where('clienteId', '==', clientId).get()
        return docsToItems(result)
    }
    return []
}

export const getAllParks = async () => {
    const result = await firebase.firestore().collection('estacionamentos').get()
    return docsToItems(result)
}

export const getAllParksByRessaleId = async ({ id }) => {
    const result = await firebase.firestore().collection('estacionamentos').where("revendaId", "==", id).get()
    return docsToItems(result)
}

export const getAllParksByLimitId = async ({ limit }) => {
    const result = await firebase.firestore().collection('estacionamentos').limit(limit).get()
    return docsToItems(result)
}

//Não usar mais
export const getPark = async ({ id }) => {
    try {
        const result = await firebase.firestore().collection('estacionamentos').doc(id).get()
        return { success: true, data: { id: result.id, ...result.data() } }
    } catch (err) {
        return { success: false, message: "Cliente não encontrado" }
    }
}

export const getParkById = async ({ id }) => {
    const result = await firebase.firestore().collection('estacionamentos').doc(id).get()
    return docToItem(result)
}

export const updateParkRevendaId = async ({ id, revendaId }) => {
    await firebase.firestore().collection('estacionamentos').doc(id).update({
        revendaId: revendaId,
        changeRevendaBy: getUsuario()?.nome,
        changeRevendaAt: new Date()
    })
}

export const updatePark = async ({ id, data }) => {
    await firebase.firestore().collection('estacionamentos').doc(id).update(data)
}

import React from 'react'
import { getAllRevendas, addRevenda, getRevendaById, updateRevenda, getRevendaByCode } from "../../../store/collections/revendaWorker.js"
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent } from '../../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import { searchCEP } from '../../../shared/cepWorker.js';
import { getUsuario, isPlatformOwner } from '../../../config/auth.js';
import { countClientByReseleId } from '../../../store/collections/clienteWorker.js';
import { countParksByReseleId } from '../../../store/collections/parkWorker.js';
import { Tabs, Tab } from 'react-bootstrap';
import { queryString, reloadWindow, reloadWithAlert, setPathWindow, toastError, toastWarning } from '../../../shared/utils'
import { sendLog } from '../../../shared/analytics.js';
import Select from 'react-select';
import { getURLFromURI, uploadAdminImage, uploadAppImage, uploadPrinterImage, uploadTermsOfUse } from '../../../store/filesWorker.js';
import { createAccount } from '../../../view-model/conta/create-account-view-model.js';
import { updateUserToMainReseller } from '../../../store/collections/userWorker.js';
import { getAllLicencasByRevendaId } from '../../../store/collections/licencaWorker.js';
import { ConfigurarNotaNovoBody } from '../../nota-fiscal/novo/configurar.js';
import { fetchRelaseFunction } from '../../../shared/function-request.js';
import { AlertDanger, AlertSecondary, AlertSuccess, AlertWarning } from '../../../shared/alert-manager.js';

class ListarRevendas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isShowNewRevenda: false,
            tipoCusto: "IMPLANTACAO",
            tipoSuporte: "SELFPARKING",
            tipoDocumento: "CNPJ",
            codigo: "",
            revendas: [],
            auxRevendas: []
        }
        this.load();
    }

    load = async () => {
        this.setState({ isLoading: true })
        const revendas = await getAllRevendas()
        this.setState({ revendas: revendas })
        this.setState({ auxRevendas: revendas })
        this.setState({ isLoading: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ revendas: this.state.auxRevendas })
        } else {
            const result = this.state.auxRevendas.filter(e => e.nome.toUpperCase().includes(value) ||
                e.documento?.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").includes(value) ||
                e.celular?.toUpperCase().replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").includes(value) ||
                e.email?.toUpperCase().includes(value) ||
                e.codigo?.includes(value) ||
                e.representante?.toUpperCase().includes(value) ||
                e.revendedor?.nome?.toUpperCase().includes(value) ||
                e.endereco?.logradouro?.toUpperCase().includes(value) ||
                e.endereco?.numero?.toUpperCase().includes(value))
            this.setState({ revendas: result })
        }
    }

    showNewRevenda = () => {
        this.setState({ isShowNewRevenda: true })
    }

    hideNewRevenda = () => {
        this.setState({ isShowNewRevenda: false })
    }

    confirmNewRevenda = async () => {
        const codigo = this.state.codigo.replaceAll("_", "").padEnd("4", "0")
        const revenda = {
            status: "Ativo",
            nome: this.state.nome,
            representante: this.state.representante,
            documento: this.state.documento,
            tipoDocumento: this.state.tipoDocumento,
            celular: this.state.celular,
            email: this.state.email,
            taxa: parseFloat(this.state.taxa) ?? 0.0,
            imposto: parseFloat(this.state.taxa) ?? 0.0,
            tipoSuporte: this.state.tipoSuporte,
            whatsAppSuporte: this.state.whatsAppSuporte,
            codigo: codigo,
            tipoCusto: this.state.tipoCusto,
            custo: this.state.custo,
            cep: this.state.cep,
            logradouro: this.state.logradouro,
            numero: this.state.numero,
            complemento: this.state.complemento,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            createdAt: new Date(),
            createdBy: getUsuario()?.nome
        }
        if (!this.checkFields()) {
            return
        }
        const revendaByCode = await getRevendaByCode({ code: codigo })
        if (revendaByCode === null) {
            const id = await addRevenda({ data: revenda })
            await this.createRevendaAccount()
            const url = `/revenda/detalhes/${id}`
            window.location.href = url
        } else {
            toastWarning("Já existe uma revenda utilizando este código.")
        }
    }

    createRevendaAccount = async () => {
        await createAccount({
            nome: this.state.nome,
            email: this.state.email,
            phone: this.state.celular,
            park: this.state.nome,
            document: this.state.documento,
            password: this.state.password,
            comoConheceu: "Outros",
            resaleCode: this.state.codigo,
        }, status => {

        }, error => {
            toastError(error)
        }, async userId => {
            await this.changeAccountToResele(userId)
        })
    }

    changeAccountToResele = async (userId) => {
        await updateUserToMainReseller({
            id: userId,
            isMainResele: true
        })
    }

    checkFields = () => {
        return true
        if ((this.state.nome &&
            this.state.representante &&
            this.state.celular &&
            this.state.taxa &&
            (this.state.tipoSuporte === "REVENDA" && !this.state.whatsAppSuporte) &&
            this.state.tipoCusto &&
            this.state.codigo &&
            this.state.custo &&
            this.state.cep &&
            this.state.logradouro &&
            this.state.bairro &&
            this.state.cidade &&
            this.state.estado) === 0) {
            return true
        }
        toastWarning("Informe todos os campos obrigatórios.")
        return false
    }

    changeCusto = (a, b, floatvalue) => {
        this.setState({ custo: floatvalue })
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    render() {
        return (
            <NavBar>
                <div className="row">
                    <div className='col-lg-12'>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Todas as revendas
                                <div className='btn-group'>
                                    <button type="button" onClick={() => { this.showNewRevenda() }} className="btn btn-sm btn-primary">
                                        <i className="fas fa-file-export mx-2"></i>
                                        Cadastrar revenda
                                    </button>
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='col-lg-12'>
                                    <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, endereço ou documento..." className="form-control" />
                                </div>
                            </div>
                            {
                                this.state.isLoading === false ?
                                    <>
                                        {
                                            this.state.revendas.length === 0 ?
                                                <div className="col-lg-12">
                                                    <EmptyContent text="Nenhuma revenda encontrada com esta busca." />
                                                </div> :
                                                <div className='table-responsive'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" width={10} className="d-none d-sm-table-cell"></th>
                                                                <th scope="col">Nome</th>
                                                                <th scope="col" className="d-none d-sm-table-cell">Representante</th>
                                                                <th scope="col" className="d-none d-sm-table-cell">Celular</th>
                                                                <th scope="col" className="d-none d-sm-table-cell">Documento</th>
                                                                <th scope="col">Desde</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.revendas.map(item =>
                                                                    <tr>
                                                                        <td className="d-none d-sm-table-cell">
                                                                            <h6>
                                                                                {
                                                                                    item.status === 'Ativo' && <span className="badge text-bg-success btn-block">Ativo</span>
                                                                                }
                                                                                {
                                                                                    item.status !== 'Ativo' && <span className="badge text-bg-danger btn-block">Inativo</span>
                                                                                }
                                                                            </h6>
                                                                        </td>
                                                                        <td>{item.nome}</td>
                                                                        <td className="d-none d-sm-table-cell">{item.representante}</td>
                                                                        <td className="d-none d-sm-table-cell">{item.celular ?? "Não informado"}</td>
                                                                        <td className="d-none d-sm-table-cell">{item.documento ?? "Não informado"}</td>
                                                                        <td className="d-none d-sm-table-cell">{item.createdAt ? Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY") : "Não informado"}</td>
                                                                        <td width='10'>
                                                                            <a href={'/revenda/detalhes/' + item.id}>
                                                                                <button type="button" className="btn btn-primary">
                                                                                    <i className="fas fa-eye" />
                                                                                </button>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                        }
                                    </> :
                                    <Loading />
                            }
                        </div>
                    </div>
                </div>
                <Dialog maxWidth='md' fullWidth onClose={this.hideNewRevenda} open={this.state.isShowNewRevenda}>
                    <MuiDialogTitle className='text-center'>
                        Cadastrar nova revenda
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='row'>
                            <h5>Dados da revenda</h5>
                            <div className="col-lg-4">
                                <label>Nome fantasia</label>
                                <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                            </div>
                            <div className="col-lg-4">
                                <label>Razão social</label>
                                <input type="text" onChange={(e) => this.setState({ nomeRazaoSocial: e.target.value })} value={this.state.nomeRazaoSocial} className="form-control" />
                            </div>
                            <div className="col-lg-4">
                                <label>Nome representante</label>
                                <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" />
                            </div>
                            <div className="col-lg-4">
                                <label>Contato representante</label>
                                <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                            </div>
                            <div className="col-lg-4">
                                <label>Documento representante</label>
                                <NumberFormat className="form-control" mask='_' format={'###.###.###-##'} value={this.state.representanteDocumento} onChange={(e) => this.setState({ representanteDocumento: e.target.value })} />
                            </div>
                            <div className="col-lg-4">
                                <label>E-mail</label>
                                <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                            </div>
                            <div className="col-lg-4">
                                <label>Tipo documento</label>
                                <div className="input-group mb-3">
                                    <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                        <option value='CNPJ'>CNPJ</option>
                                        <option value='CPF'>CPF</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <label>Número documento</label>
                                <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                            </div>
                            <div className="col-lg-4">
                                <label>Código da revenda</label>
                                <NumberFormat className="form-control" value={this.state.codigo} mask='_' onChange={(e) => this.setState({ codigo: e.target.value })} format="####" />
                            </div>
                        </div>
                        <div className='row'>
                            <h5 className='mt-3'>Dados para login</h5>
                            <div className="col-lg-6">
                                <label>E-mail</label>
                                <input type="email" value={this.state.emailLogin} onChange={(e) => this.setState({ emailLogin: e.target.value })} className="form-control" />
                            </div>
                            <div className="col-lg-6">
                                <label>Senha</label>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className="form-control" />
                            </div>
                        </div>
                        <div className='row'>
                            <h5 className='mt-3'>Endereço</h5>
                            <div className="col-lg-3">
                                <label>CEP</label>
                                <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                            </div>
                            <div className="col-lg-3">
                                <label>Logradouro</label>
                                <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                            </div>
                            <div className="col-lg-2">
                                <label>Número</label>
                                <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                            </div>
                            <div className="col-lg-4">
                                <label>Complemento</label>
                                <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                            </div>
                            <div className="col-lg-6">
                                <label>Bairro</label>
                                <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                            </div>
                            <div className="col-lg-3">
                                <label>Cidade</label>
                                <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                            </div>
                            <div className="col-lg-3">
                                <label>Estado</label>
                                <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                            </div>
                        </div>
                        <div className='row'>
                            <h5 className='mt-3'>Suporte</h5>
                            <div className="col-lg-4">
                                <label>Tipo de suporte</label>
                                <select className="form-select" value={this.state.tipoSuporte} onChange={(e) => this.setState({ tipoSuporte: e.target.value })}>
                                    <option value="SELFPARKING">SelfParking</option>
                                    <option value="REVENDA">Revenda</option>
                                </select>
                            </div>
                            {
                                this.state.tipoSuporte === "REVENDA" &&
                                <div className="col-lg-4">
                                    <label>WhatsApp suporte</label>
                                    <NumberFormat className="form-control" value={this.state.whatsAppSuporte} mask='_' onChange={(e) => this.setState({ whatsAppSuporte: e.target.value })} format="(##)#####-####" />
                                </div>
                            }
                        </div>
                        <div className='row'>
                            <h5 className='mt-3'>Financeiro</h5>
                            <div className="col-lg-3">
                                <label>Taxa revenda ( % )</label>
                                <input type="text" maxLength={3} onChange={(e) => this.setState({ taxa: e.target.value })} value={this.state.taxa} className="form-control" />
                            </div>
                            <div className="col-lg-3">
                                <label>Tipo custo</label>
                                <select className="form-select" value={this.state.tipoCusto} onChange={(e) => this.setState({ tipoCusto: e.target.value })}>
                                    <option value="IMPLANTACAO">Implantação</option>
                                    <option value="MENSAL">Mensal</option>
                                </select>
                            </div>
                            <div className="col-lg-3">
                                <label>Custo integração</label>
                                <CurrencyInput value={this.state.custo} prefix="R$" className='form-control' onChangeEvent={this.changeCusto} />
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={this.hideNewRevenda} className='btn btn-secondary'>Fechar</button>
                            <button onClick={this.confirmNewRevenda} className='btn btn-primary'>Cadastrar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </NavBar >
        )
    }
}

class RevendaDetalhes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isLoadingNote: true,
            mostrarLinksDeAjuda: "true",
            mostrarPowerBy: "true",
            isEditing: false,
            revendaId: "",
            revenda: "",
            key: "site",
            logoApp: "https://dummyimage.com/200x200/ececec/000",
            logoAdministrativo: "https://dummyimage.com/200x200/ececec/000",
            logoPrinter: "https://dummyimage.com/200x48/ececec/000",
            whatsAppProvider: "",
            whatsAppChatCenterApiKey: "",
            logoAppFile: null,
            logoAdministrativoFile: null,
            logoPrinterFile: null,
            diasParaBloqueioPosVencimento: 5,
            diasDeTeste: 7
        }
        this.load()
    }

    componentDidMount = () => {
        this.setState({ key: queryString("tab") ?? "site" })
    }

    load = async () => {
        this.setState({ isLoading: true })
        const revenda = await getRevendaById({ id: this.props.match.params.id })
        this.setState({ ...revenda })
        this.setState({ formaCobranca: revenda.formaCobranca ?? "SELFPARKING" })
        this.setState({ diasParaBloqueioPosVencimento: revenda.diasParaBloqueioPosVencimento ?? 5 })
        this.setState({ diasDeTeste: revenda.diasDeTeste ?? 7 })
        this.setState({ diasDeTeste: revenda.diasDeTeste ?? 7 })
        this.setState({ imposto: revenda.imposto ?? 0 })
        this.setState({ isLoading: false })
        this.fillImages(revenda)
        if (this.state.key === "nota") {
            this.loadNote()
        }
        this.loadAllRessales(revenda)
    }

    loadAllRessales = async (revenda) => {
        const ressales = await getAllRevendas()
        const filtered = ressales.filter(e => e.id !== this.props.match.params.id).map(e => { return { value: e.id, label: e.nome } }) ?? []
        this.setState({ allRessales: filtered }, () => {
            this.setState({ revendasAssociadas: revenda.revendasAssociadas ?? [] })
        })
    }

    saveBasicData = async () => {
        var data = {
            nome: this.state.nome,
            email: this.state.email,
            documento: this.state.documento,
            nomeRazaoSocial: this.state.nomeRazaoSocial,
            representanteDocumento: this.state.representanteDocumento,
            tipoDocumento: this.state.tipoDocumento,
            representante: this.state.representante,
            celular: this.state.celular,
            cep: this.state.cep,
            logradouro: this.state.logradouro,
            numero: this.state.numero,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            codigo: this.state.codigo
        }
        const log = {
            before: this.state,
            after: data
        }
        const revendaByCode = await getRevendaByCode({ code: this.state.codigo })
        if (revendaByCode === null || revendaByCode.id === this.state.id) {
            sendLog("Revenda", `Dados da revenda atualizados por ${getUsuario()?.nome}`, true, log)
            await updateRevenda({ id: this.state.id, data: data })
            reloadWithAlert("Informações atualizadas com sucesso.")
        } else {
            toastWarning("Já existe uma revenda utilizando este código.")
        }
    }

    saveSettings = async () => {
        var data = {
            nome: this.state.nome,
            nomeRazaoSocial: this.state.nomeRazaoSocial,
            email: this.state.email,
            documento: this.state.documento,
            tipoDocumento: this.state.tipoDocumento,
            representante: this.state.representante,
            celular: this.state.celular,
            cep: this.state.cep,
            logradouro: this.state.logradouro,
            numero: this.state.numero,
            bairro: this.state.bairro,
            cidade: this.state.cidade,
            estado: this.state.estado,
            codigo: this.state.codigo,
            site: this.state.site,
            siteCor: this.state.siteCor,
            siteAdmin: this.state.siteAdmin,
            siteTicket: this.state.siteTicket,
            appLinkLoja: this.state.appLinkLoja,
            tipoSuporte: this.state.tipoSuporte,
            whatsAppSuporte: this.state.whatsAppSuporte,
            emailSuporte: this.state.emailSuporte,
            taxa: parseFloat(this.state.taxa) ?? 0.0,
            imposto: parseFloat(this.state.imposto) ?? 0.0,
            tipoCusto: this.state.tipoCusto,
            custo: this.state.custo,
            sendgridKey: this.state.sendgridKey,
            emailRemetente: this.state.emailRemetente,
            nomeRemetente: this.state.nomeRemetente,
            emailTemplateBoasVindas: this.state.emailTemplateBoasVindas,
            emailTemplateGenerico: this.state.emailTemplateGenerico,
            whatsAppTwilioAccountSID: this.state.whatsAppTwilioAccountSID,
            whatsAppTwilioAuthToken: this.state.whatsAppTwilioAuthToken,
            whatsAppApiBrasilWPPDeviceToken: this.state.whatsAppApiBrasilWPPDeviceToken,
            whatsAppApiBrasilWPPAuthorization: this.state.whatsAppApiBrasilWPPAuthorization,
            whatsAppChatCenterApiKey: this.state.whatsAppChatCenterApiKey,
            whatsAppProvider: this.state.whatsAppProvider,
            whatsAppNumber: this.state.whatsAppNumber,
            pacoteApp: this.state.pacoteApp,
            textoBoasVindas: this.state.textoBoasVindas,
            textoLogin: this.state.textoLogin,
            textoAberturaConta: this.state.textoAberturaConta,
            whatsAppTemplateReceiptIn: this.state.whatsAppTemplateReceiptIn,
            whatsAppTemplateReceiptOut: this.state.whatsAppTemplateReceiptOut,
            whatsAppTemplateMonthlyChargePayed: this.state.whatsAppTemplateMonthlyChargePayed,
            whatsAppTemplateStatus: this.state.whatsAppTemplateStatus,
            whatsAppTemplateMonthlyPayment: this.state.whatsAppTemplateMonthlyPayment,
            whatsAppTemplateParkCharge: this.state.whatsAppTemplateParkCharge,
            whatsAppTemplateNewClient: this.state.whatsAppTemplateNewClient,
            whatsAppTemplateDownloadApp: this.state.whatsAppTemplateDownloadApp,
            whatsAppTemplateParkSingleCharge: this.state.whatsAppTemplateParkSingleCharge,
            whatsAppTemplateParkChargePayed: this.state.whatsAppTemplateParkChargePayed,
            cobrancaMensalistaTemplateAntesDoVencimento: this.state.cobrancaMensalistaTemplateAntesDoVencimento,
            cobrancaMensalistaTemplateAposVencimento: this.state.cobrancaMensalistaTemplateAposVencimento,
            cobrancaMensalistaTemplateNoPagamento: this.state.cobrancaMensalistaTemplateNoPagamento,
            cobrancaMensalistaTemplateNoCadastro: this.state.cobrancaMensalistaTemplateNoCadastro,
            appCorPrincipalLight: this.state.appCorPrincipalLight,
            appCorPrincipalDark: this.state.appCorPrincipalDark,
            posGooglePlay: this.state.posGooglePlay,
            posCielo: this.state.posCielo,
            posStone: this.state.posStone,
            posSafra: this.state.posSafra,
            posRede: this.state.posRede,
            posGetnet: this.state.posGetnet,
            posPagSeguro: this.state.posPagSeguro,
            sitePrivacidade: this.state.sitePrivacidade,
            youtube: this.state.youtube,
            bankCode: this.state.bankCode,
            bankTypeAccount: this.state.bankTypeAccount,
            bankAgency: this.state.bankAgency,
            bankAgencyDigit: this.state.bankAgencyDigit,
            bankAccount: this.state.bankAccount,
            bankAccountDigit: this.state.bankAccountDigit,
            paymentMethodBoleto: this.state.paymentMethodBoleto,
            paymentMethodCredito: this.state.paymentMethodCredito,
            paymentMethodPix: this.state.paymentMethodPix,
            habilitarEmissaoNotaFiscal: this.state.habilitarEmissaoNotaFiscal,
            formaCobranca: this.state.formaCobranca,
            diasParaBloqueioPosVencimento: this.state.diasParaBloqueioPosVencimento,
            diasDeTeste: this.state.diasDeTeste,
            mostrarLinksDeAjuda: this.state.mostrarLinksDeAjuda,
            mostrarPowerBy: this.state.mostrarPowerBy,
            revendasAssociadas: this.state.revendasAssociadas
        }
        await this.uploadImages({ data: data })
        var log = {
            before: {
                ...this.state,
                logoAppFile: undefined,
                logoAdministrativoFile: undefined,
                logoPrinterFile: undefined
            },
            after: data
        }
        const revendaByCode = await getRevendaByCode({ code: this.state.codigo })
        if (revendaByCode === null || revendaByCode.id === this.state.id) {
            sendLog("Revenda", `Dados da revenda atualizados por ${getUsuario()?.nome}`, true, log)
            await updateRevenda({ id: this.state.id, data: data })
            reloadWithAlert("Informações atualizadas com sucesso.")
        } else {
            toastWarning("Já existe uma revenda utilizando este código.")
        }
    }

    uploadImages = async ({ data }) => {
        const logoAppFile = this.state.logoAppFile
        if (logoAppFile) {
            data.logoApp = await uploadAppImage(this.state.id, logoAppFile)
        }
        const logoAdministrativoFile = this.state.logoAdministrativoFile
        if (logoAdministrativoFile) {
            data.logoAdministrativo = await uploadAdminImage(this.state.id, logoAdministrativoFile)
        }
        const logoPrinterFile = this.state.logoPrinterFile
        if (logoPrinterFile) {
            data.logoPrinter = await uploadPrinterImage(this.state.id, logoPrinterFile)
        }
        const caminhoTermosDeUsoFile = this.state.caminhoTermosDeUsoFile
        if (caminhoTermosDeUsoFile) {
            data.caminhoTermosDeUso = await uploadTermsOfUse(this.state.id, caminhoTermosDeUsoFile)
            data.caminhoTermosDeUsoLastUpdateDate = new Date()
        }
    }

    fillImages = async (revenda) => {
        if (revenda.logoApp) {
            const url = await getURLFromURI(revenda.logoApp)
            this.setState({ logoApp: url })
        }
        if (revenda.logoAdministrativo) {
            const url = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: url })
        }
        if (revenda.logoPrinter) {
            const url = await getURLFromURI(revenda.logoPrinter)
            this.setState({ logoPrinter: url })
        }
    }

    handleAppFileChange = e => {
        const file = e.target.files[0]
        this.setState({ logoAppFile: file })
        const url = URL.createObjectURL(file)
        this.setState({ logoApp: url })
    }

    handleAdmFileChange = e => {
        const file = e.target.files[0]
        this.setState({ logoAdministrativoFile: file })
        const url = URL.createObjectURL(file)
        this.setState({ logoAdministrativo: url })
    }

    changeCusto = (a, b, floatvalue) => {
        this.setState({ custo: floatvalue })
    }

    handlePrinterFileChange = e => {
        const file = e.target.files[0]
        this.setState({ logoPrinterFile: file })
        const url = URL.createObjectURL(file)
        this.setState({ logoPrinter: url })
    }

    handleTermsFileChange = e => {
        const file = e.target.files[0]
        this.setState({ caminhoTermosDeUsoFile: file })
    }

    startEdit = () => {
        this.setState({ isEditing: true })
        this.setState({ nome: this.state.nome })
        this.setState({ nomeRazaoSocial: this.state.nomeRazaoSocial })
        this.setState({ codigo: this.state.codigo })
        this.setState({ email: this.state.email })
        this.setState({ documento: this.state.documento })
        this.setState({ representanteDocumento: this.state.representanteDocumento })
        this.setState({ tipoDocumento: this.state.tipoDocumento })
        this.setState({ representante: this.state.representante })
        this.setState({ celular: this.state.celular })
        this.setState({ cep: this.state.cep })
        this.setState({ logradouro: this.state.logradouro })
        this.setState({ numero: this.state.numero })
        this.setState({ bairro: this.state.bairro })
        this.setState({ cidade: this.state.cidade })
        this.setState({ estado: this.state.estado })
    }

    changeTypeSupport = (e) => {
        const value = e.target.value
        this.setState({ tipoSuporte: value })
        if (value === "SELFPARKING") {
            this.setState({ emailSuporte: "parceiros@selfparking.com.br" })
            this.setState({ whatsAppSuporte: "(11)98418-0090" })
        }
    }

    changeTab = (tab) => {
        setPathWindow(`/revenda/detalhes/${this.props.match.params.id}?tab=${tab}`)
        this.setState({ key: tab })
        if (tab === "nota") {
            this.loadNote()
        }
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    loadNote = async () => {
        this.setState({ isLoadingNote: true })
        const option = {
            method: "GET"
        }
        const result = await fetchRelaseFunction("/newnote/company/resale", option)
        this.setState({ isLoadingNote: false })
        if (result.success) {
            this.setState({ settings: result.data })
        }
    }

    updateNote = async (data) => {
        this.setState({ isLoadingNote: true })
        const option = {
            method: "POST",
            body: {
                ...data,
                email: "financeiro@web.com.br"
            }
        }
        const result = await fetchRelaseFunction("/newnote/company/resale", option)
        this.setState({ isLoadingNote: false })
        if (result.success) {
            var data = {
                habilitarEmissaoNotaFiscal: this.state.habilitarEmissaoNotaFiscal
            }
            await updateRevenda({ id: this.state.id, data: data })
            reloadWithAlert(result.message)
        } else {
            toastError(result.message)
        }
    }

    handleRessalesAssociateds = (selected) => {
        this.setState({ revendasAssociadas: selected })
    };

    render() {
        return (
            <NavBar>
                <div className='row'>
                    <div className='col-lg-3'>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                {this.state.isEditing === false ? "Detalhes da revenda" : "Editar dados da revenda"}
                            </div>
                            <div className='card-body'>
                                {
                                    this.state.isEditing === true &&
                                    <>
                                        <div className='row'>
                                            <div className="col-lg-4">
                                                <label>Código</label>
                                                <input type="text" maxLength={4} className="form-control" onChange={(e) => this.setState({ codigo: e.target.value })} value={this.state.codigo} required />
                                            </div>
                                            <div className="col-lg-8">
                                                <label>Representante</label>
                                                <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" required />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Celular representante</label>
                                                <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Documento representante</label>
                                                <NumberFormat className="form-control" value={this.state.representanteDocumento} mask='_' onChange={(e) => this.setState({ representanteDocumento: e.target.value })} format="###.###.###-##" />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Nome do estabelecimento</label>
                                                <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" required />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Razão social</label>
                                                <input type="text" onChange={(e) => this.setState({ nomeRazaoSocial: e.target.value })} value={this.state.nomeRazaoSocial} className="form-control" required />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>E-mail</label>
                                                <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Tipo documento</label>
                                                <div className="input-group mb-3">
                                                    <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                        <option value='CNPJ'>CNPJ</option>
                                                        <option value='CPF'>CPF</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Número documento</label>
                                                <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <h4>Endereço</h4>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-3">
                                                <label>CEP</label>
                                                <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                            </div>
                                            <div className="col-lg-9">
                                                <label>Logradouro</label>
                                                <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                                            </div>
                                            <div className="col-lg-5">
                                                <label>Número</label>
                                                <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                                            </div>
                                            <div className="col-lg-7">
                                                <label>Complemento</label>
                                                <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-lg-6">
                                                <label>Bairro</label>
                                                <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Cidade</label>
                                                <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Estado</label>
                                                <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    this.state.isEditing === false &&
                                    <>
                                        {
                                            this.state.isLoading === false ?
                                                <>
                                                    <div className='row my-3'>
                                                        <div className="col-lg-12">
                                                            <strong>Código: </strong> {this.state.codigo}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>Nome fantasia: </strong> {this.state.nome}
                                                        </div>
                                                        {
                                                            this.state.razaoSocial &&
                                                            <div className="col-lg-12">
                                                                <strong>Razão social: </strong> {this.state.cliente.razaoSocial}
                                                            </div>
                                                        }
                                                        <div className="col-lg-12">
                                                            <strong>Representante: </strong> {this.state.representante}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>Documento representante: </strong> {this.state.representanteDocumento}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>E-mail: </strong> {this.state.email ?? "Não informado"}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>Documento: </strong> {this.state.documento}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>Celular: </strong> {this.state.celular}
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <strong>Desde: </strong> {Moment(this.state.revenda?.createdAt?.toDate()).format("DD/MM/YYYY [às] HH:mm")}
                                                        </div>
                                                    </div>
                                                    <div className='row my-1'>
                                                        {
                                                            this.state.revenda !== null ?
                                                                <>
                                                                    <div className="col-lg-12">
                                                                        <h4>Endereço</h4>
                                                                        <strong>Endereço: </strong> {this.state.logradouro}, {this.state.numero} - {this.state.cidade}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>CEP: </strong> {this.state.cep}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Bairro: </strong> {this.state.bairro}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Estado: </strong> {this.state.estado}
                                                                    </div>
                                                                </> :
                                                                <>
                                                                    <div className="col-lg-12">
                                                                        <h6>Endereço não informado</h6>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </>
                                                : <Loading />
                                        }
                                    </>
                                }
                            </div>
                            {
                                this.state.isLoading === false &&
                                <div className='card-footer'>
                                    {
                                        this.state.isEditing === false ?
                                            <div className='row'>
                                                <div className='btn-group'>
                                                    <button onClick={(e) => { this.startEdit() }} className='btn btn-primary btn-block'>Editar</button>
                                                    {
                                                        this.state.status === "Ativo" ? <button onClick={(e) => this.setState({ isOpenInativeClient: true })} className='btn btn-danger btn-block'>Desativar</button> :
                                                            <button onClick={(e) => { this.onUpdateStatus("Ativo") }} className='btn btn-success btn-block'>Ativar</button>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className='btn-group'>
                                                <button type="button" onClick={(e) => { this.setState({ isEditing: false }) }} className="btn btn-secondary text-white btn-block">Cancelar</button>
                                                <button type="button" onClick={(e) => { this.saveBasicData() }} className="btn btn-primary btn-block">Atualizar dados</button>
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <RevendaDetalhesAllParksNotFree revendaId={this.props.match.params.id} />
                            </div>
                            <div className='col-lg-4'>
                                <RevendaDetalhesAllClientes revendaId={this.props.match.params.id} />
                            </div>
                            <div className='col-lg-4'>
                                <RevendaDetalhesAllEstabelecimentos revendaId={this.props.match.params.id} />
                            </div>
                        </div>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Configurações
                            </div>
                            <div className='card-body'>
                                <div className='mt-1'>
                                    <AlertDanger message="Só altere estas configurações caso saiba o que esta fazendo, registramos todos os logs de mudanças." />
                                </div>
                                <Tabs className='profile-dropdown-toggle mt-3' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeTab(tab)}>
                                    <Tab eventKey="site" title="Site">
                                        <div className='row mt-3'>
                                            <div className="col-lg-3">
                                                <label>Site</label>
                                                <input type="site" onChange={(e) => this.setState({ site: e.target.value })} value={this.state.site} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Site administrativo</label>
                                                <input type="site" onChange={(e) => this.setState({ siteAdmin: e.target.value })} value={this.state.siteAdmin} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Ticket Site</label>
                                                <input type="site" onChange={(e) => this.setState({ siteTicket: e.target.value })} value={this.state.siteTicket} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Cor principal do site</label>
                                                <input type="text" placeholder='#FFFFFF' maxLength={7} onChange={(e) => this.setState({ siteCor: e.target.value })} value={this.state.siteCor} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className="col-lg-3">
                                                <label>Mostrar ajuda</label>
                                                <select className="form-select" value={this.state.mostrarLinksDeAjuda} onChange={(e) => this.setState({ mostrarLinksDeAjuda: e.target.value })}>
                                                    <option value="true">Mostrar link no menu e nas páginas</option>
                                                    <option value="false">Não mostrar links de ajuda</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.mostrarLinksDeAjuda === "true" &&
                                                <div className="col-lg-3">
                                                    <label>YouTube - Ajuda</label>
                                                    <input type="site" onChange={(e) => this.setState({ youtube: e.target.value })} value={this.state.youtube} className="form-control" />
                                                </div>
                                            }
                                            <div className="col-lg-3">
                                                <label>Site termos de uso</label>
                                                <input type="site" onChange={(e) => this.setState({ sitePrivacidade: e.target.value })} value={this.state.sitePrivacidade} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label for="formFile" className="form-label">Termos de uso</label>
                                                <input type="file" accept="html, txt" onChange={this.handleTermsFileChange} id="formFile" />
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="aplicativo" title="Aplicativo">
                                        <div className='row mt-3'>
                                            <div className="col-lg-4">
                                                <label>Boas vindas</label>
                                                <textarea rows={2} type="text" onChange={(e) => this.setState({ textoBoasVindas: e.target.value })} value={this.state.textoBoasVindas} className="form-control" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Login</label>
                                                <textarea rows={2} type="text" onChange={(e) => this.setState({ textoLogin: e.target.value })} value={this.state.textoLogin} className="form-control" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Abertura de conta</label>
                                                <textarea rows={2} type="text" onChange={(e) => this.setState({ textoAberturaConta: e.target.value })} value={this.state.textoAberturaConta} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className="col-lg-3">
                                                <label>Cor principal app</label>
                                                <input type="text" onChange={(e) => this.setState({ appCorPrincipalLight: e.target.value })} value={this.state.appCorPrincipalLight} className="form-control" />
                                            </div>
                                            {
                                                isPlatformOwner() &&
                                                <div className="col-lg-3">
                                                    <label>Pacote App</label>
                                                    <input type="site" disabled={this.state.pacoteApp} onChange={(e) => this.setState({ pacoteApp: e.target.value })} value={this.state.pacoteApp} className="form-control" />
                                                </div>
                                            }
                                            <div className="col-lg-3">
                                                <label>Mostrar <strong>PowerBy</strong></label>
                                                <select className="form-select" value={this.state.mostrarPowerBy} onChange={(e) => this.setState({ mostrarPowerBy: e.target.value })}>
                                                    <option value="true">Sim</option>
                                                    <option value="false">Não</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Link Google Play</label>
                                                <input type="text" onChange={(e) => this.setState({ appLinkLoja: e.target.value })} value={this.state.appLinkLoja} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="email" title="E-mail">
                                        <div className='alert alert-success mt-3'>
                                            <strong>Informação:</strong> Utilizamos a plataforma <a href="https://sendgrid.com/" target='_blank'> <strong>SendGrid</strong></a> para fazer o envio de todos os e-mails da plataforma para que todos os e-mails enviados aqui na plataforma chegue aos seus clientes com a sua marca, é preciso criar uma conta: <a href='https://sendgrid.com/' target='_blank'>https://sendgrid.com</a>
                                        </div>
                                        <div className='row'>
                                            <div className="col-lg-4">
                                                <label>Sendgrid Key</label>
                                                <input type="text" onChange={(e) => this.setState({ sendgridKey: e.target.value })} value={this.state.sendgridKey} className="form-control" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>E-mail de remetente</label>
                                                <input type="text" onChange={(e) => this.setState({ emailRemetente: e.target.value })} value={this.state.emailRemetente} className="form-control" />
                                            </div>
                                            <div className="col-lg-4">
                                                <label>Nome do remetente</label>
                                                <input type="text" onChange={(e) => this.setState({ nomeRemetente: e.target.value })} value={this.state.nomeRemetente} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <h5 className='mt-3'>Templates de automação</h5>
                                            <div className="col-lg-4">
                                                <div className='alert alert-info'>
                                                    Enviamos um email de boas vindas para todos os seus novos clientes. <br />
                                                    <strong>Boas vindas</strong>
                                                    <input type="text" placeholder='Template ID' onChange={(e) => this.setState({ emailTemplateBoasVindas: e.target.value })} value={this.state.emailTemplateBoasVindas} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className='alert alert-info'>
                                                    Usaremos este template para enviar comunicação genérica para o cliente.<br />
                                                    <strong>Template genérico</strong>
                                                    <input type="text" placeholder='Template ID: TITLE, SUB_TITLE, BUTTON_TITLE' onChange={(e) => this.setState({ emailTemplateGenerico: e.target.value })} value={this.state.emailTemplateGenerico} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="whatsApp" title="WhatsApp">
                                        <div className='row mt-3'>
                                            <div className="col-lg-3 mb-2">
                                                <label>Provedor</label>
                                                <select className="form-select" value={this.state.whatsAppProvider} onChange={(e) => this.setState({ whatsAppProvider: e.target.value })}>
                                                    <option value="">Nenhum</option>
                                                    <option value="ChatCenter">Chatcenter</option>
                                                    <option value="Twilio">Twilio</option>
                                                    <option value="ApiBrasilWPP">Api Brasil - WPP</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.whatsAppProvider === "ApiBrasilWPP" &&
                                                <div className='col-lg-12'>
                                                    <div className='alert alert-success mt-3'>
                                                        <div className='row'>
                                                            <div className="col-lg-4">
                                                                <label>DeviceToken</label>
                                                                <input type="text" onChange={(e) => this.setState({ whatsAppApiBrasilWPPDeviceToken: e.target.value })} value={this.state.whatsAppApiBrasilWPPDeviceToken} className="form-control" />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>Auth Token</label>
                                                                <input type="text" onChange={(e) => this.setState({ whatsAppApiBrasilWPPAuthorization: e.target.value })} value={this.state.whatsAppApiBrasilWPPAuthorization} className="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.whatsAppProvider === "Twilio" &&
                                                <div className='col-lg-12'>
                                                    <div className='alert alert-success mt-3'>
                                                        <div className='row'>
                                                            <div className="col-lg-4">
                                                                <label>Número de envio</label>
                                                                <NumberFormat className="form-control" value={this.state.whatsAppNumber} mask='_' onChange={(e) => this.setState({ whatsAppNumber: e.target.value })} format="(##)#####-####" />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>Account SID</label>
                                                                <input type="text" onChange={(e) => this.setState({ whatsAppTwilioAccountSID: e.target.value })} value={this.state.whatsAppTwilioAccountSID} className="form-control" />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>Auth Token</label>
                                                                <input type="text" onChange={(e) => this.setState({ whatsAppTwilioAuthToken: e.target.value })} value={this.state.whatsAppTwilioAuthToken} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>Informação:</strong> É preciso configurar uma conta na plataforma <a href="https://www.twilio.com/" target='_blank'> <strong>twilio</strong></a> para fazer o envio de todos as mensagem via WhatsApp. Consulte custos no link abaixo: <a href='https://www.twilio.com/' target='_blank'>https://www.twilio.com</a> <p />
                                                            <span>É preciso configurar os templates pré definidos: </span> <a href="https://console.twilio.com/us1/develop/sms/senders/whatsapp-templates" target='_blank'><strong>Cadastrar template</strong></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.whatsAppProvider === "ChatCenter" &&
                                                <div className='col-lg-12'>
                                                    <div className='alert alert-success mt-3'>
                                                        <div className="col-lg-4">
                                                            <label>Api Key</label>
                                                            <input type="text" onChange={(e) => this.setState({ whatsAppChatCenterApiKey: e.target.value })} value={this.state.whatsAppChatCenterApiKey} className="form-control" />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>Informação:</strong> É preciso configurar uma conta na plataforma <a href="https://app.chatcenter.com.br/" target='_blank'> <strong>ChatCenter</strong></a> para fazer o envio de todos as mensagem via WhatsApp. Consulte custos no link abaixo: <a href='https://chatcenter.com.br/#pricing' target='_blank'>https://chatcenter.com.br/#pricing</a> <p />
                                                            <span>Não é preciso configurar os templates na plataforma da chatcenter.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.whatsAppProvider !== "" &&
                                            <div className='row'>
                                                <h6>Templates</h6>
                                                <div className="col-lg-4">
                                                    <label>Recibo de entrada do veículo</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL' rows='5' cols={10} value={this.state.whatsAppTemplateReceiptIn} onChange={(e) => this.setState({ whatsAppTemplateReceiptIn: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Recibo de saída do veículo</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL' rows='5' cols={10} value={this.state.whatsAppTemplateReceiptOut} onChange={(e) => this.setState({ whatsAppTemplateReceiptOut: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Recibo de pagamento da mensalidade do mensalista</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL' rows='5' cols={10} value={this.state.whatsAppTemplateMonthlyChargePayed} onChange={(e) => this.setState({ whatsAppTemplateMonthlyChargePayed: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Envio de status ao cliente</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL, CUSTOM_MESSAGE' rows='5' cols={10} value={this.state.whatsAppTemplateStatus} onChange={(e) => this.setState({ whatsAppTemplateStatus: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Recibo de pagamento de cobrança do mensalista</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL' rows='5' cols={10} value={this.state.whatsAppTemplateMonthlyPayment} onChange={(e) => this.setState({ whatsAppTemplateMonthlyPayment: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Cobrança da mensalidade do estacionamento</label>
                                                    <textarea className='form-control' placeholder='PARK_REPRESENTATIVE_NAME, PARK_REPRESENTATIVE_PHONE, URL_DETAIL, DUE_DATE' rows='5' cols={10} value={this.state.whatsAppTemplateParkCharge} onChange={(e) => this.setState({ whatsAppTemplateParkCharge: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Cobrança avulsa para o estacionamento</label>
                                                    <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL, DUE_DATE' rows='5' cols={10} value={this.state.whatsAppTemplateParkSingleCharge} onChange={(e) => this.setState({ whatsAppTemplateParkSingleCharge: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Aviso de cobrança paga do estacionamento</label>
                                                    <textarea className='form-control' rows='5' cols={10} value={this.state.whatsAppTemplateParkChargePayed} onChange={(e) => this.setState({ whatsAppTemplateParkChargePayed: e.target.value })} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Aviso sobre novo estacionamento cadastrado</label>
                                                    <textarea className='form-control' placeholder='URL_DETAIL, CUSTOM_MESSAGE' rows='5' cols={10} value={this.state.whatsAppTemplateNewClient} onChange={(e) => this.setState({ whatsAppTemplateNewClient: e.target.value })} />
                                                </div>
                                                <h5 className='mt-3'>Cobranças para os mensalistas dos estacionamentos.</h5>
                                                <div className='row'>
                                                    <div className='col-lg-4'>
                                                        <label>Mensagem enviada antes do vencimento</label>
                                                        <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL, DUE_DATE, CLIENT_NAME' onChange={(e) => this.setState({ cobrancaMensalistaTemplateAntesDoVencimento: e.target.value })} rows={5}>{this.state.cobrancaMensalistaTemplateAntesDoVencimento}</textarea>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label>Mensagem enviada no dia do vencimento</label>
                                                        <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL, DUE_DATE, CLIENT_NAME' onChange={(e) => this.setState({ cobrancaMensalistaTemplateNoPagamento: e.target.value })} rows={5}>{this.state.cobrancaMensalistaTemplateNoPagamento}</textarea>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label>Mensagem enviada após o vencimento</label>
                                                        <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL, DUE_DATE, CLIENT_NAME' onChange={(e) => this.setState({ cobrancaMensalistaTemplateAposVencimento: e.target.value })} rows={5}>{this.state.cobrancaMensalistaTemplateAposVencimento}</textarea>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <label>Mensagem enviada no link de cadastro</label>
                                                        <textarea className='form-control' placeholder='PARK_NAME, PARK_PHONE, URL_DETAIL' onChange={(e) => this.setState({ cobrancaMensalistaTemplateNoCadastro: e.target.value })} rows={5}>{this.state.cobrancaMensalistaTemplateNoCadastro}</textarea>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className='alert alert-success mt-3'>
                                                        <strong>Variaveis:</strong> <br />
                                                        PARK_NAME: Nome do estabelecimento <br />
                                                        PARK_PHONE: Telefone do estabelecimento <br />
                                                        PARK_REPRESENTATIVE_NAME: Nome do representante do estabelecimento <br />
                                                        PARK_REPRESENTATIVE_PHONE: Telefone do representante do estabelecimento <br />
                                                        URL_DETAIL: Site para acessar mais detalhes <br />
                                                        CUSTOM_MESSAGE: Mensagem customizada <br />
                                                        DUE_DATE: Data de vencimento <br />
                                                        CLIENT_NAME: Primeiro nome do mensalista<p />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="imagens" title="Imagens">
                                        <div className='row mt-3'>
                                            <div className="col-lg-3">
                                                <div>
                                                    <img src={this.state.logoApp} width={200} height={200} />
                                                </div>
                                                <label htmlFor="formFile" className="form-label">Logo aplicativo</label>
                                                <div>
                                                    <input type="file" accept="png" onChange={this.handleAppFileChange} id="formFile" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div>
                                                    <img src={this.state.logoAdministrativo} width={200} height={200} />
                                                </div>
                                                <label htmlFor="formFile" className="form-label">Logo site</label>
                                                <div>
                                                    <input type="file" accept="png" onChange={this.handleAdmFileChange} id="formFile" />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div>
                                                    <img src={this.state.logoPrinter} width={200} height={48} />
                                                </div>
                                                <label htmlFor="formFile" className="form-label">Logo impressão</label>
                                                <div>
                                                    <input type="file" accept="png" onChange={this.handlePrinterFileChange} id="formFile" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="financeiro" title="Financeiro">
                                        <div className='row mt-3'>
                                            <div className='col-lg-12'>
                                                {
                                                    this.state.formaCobranca === "SELFPARKING" &&
                                                    <div className='mt-2'>
                                                        <AlertSuccess message="A SelfParking ficará responsável em fazer a cobrança dos estabelecimentos." />
                                                    </div>
                                                }
                                                {
                                                    this.state.formaCobranca === "REVENDA" &&
                                                    <div className='mt-2'>
                                                        <AlertDanger message="A revenda ficará responsável em fazer a cobrança dos estabelecimentos." />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                isPlatformOwner() &&
                                                <div className='row'>
                                                    <div className="col-lg-3">
                                                        <label>Forma de cobrança</label>
                                                        <select className="form-select" value={this.state.formaCobranca} onChange={(e => this.setState({ formaCobranca: e.target.value }))}>
                                                            <option value="SELFPARKING">SelfParking</option>
                                                            <option value="REVENDA">Revenda</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Tempo de teste na abertura de conta</label>
                                                        <select className="form-select" value={this.state.diasDeTeste} onChange={(e) => this.setState({ diasDeTeste: e.target.value })}>
                                                            <option value="2">2 de testes</option>
                                                            <option value="5">5 de testes</option>
                                                            <option value="7">7 de testes</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <label>Bloquear acesso após</label>
                                                        <select className="form-select" value={this.state.diasParaBloqueioPosVencimento} onChange={(e) => this.setState({ diasParaBloqueioPosVencimento: e.target.value })}>
                                                            <option value="2">2 dias sem pagamento</option>
                                                            <option value="5">5 dias sem pagamento</option>
                                                            <option value="7">7 dias sem pagamento</option>
                                                            <option value="10">10 dias sem pagamento</option>
                                                            <option value="15">15 dias sem pagamento</option>
                                                            <option value="20">20 dias sem pagamento</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div>
                                        {/* <div className='row mt-3'>
                                            <h5>Dados bancários</h5>
                                            <div className="col-lg-3">
                                                <label>Tipo conta</label>
                                                <select className="form-select" value={this.state.bankTypeAccount} onChange={(e) => this.setState({ bankTypeAccount: e.target.value })}>
                                                    <option value="">Selecione</option>
                                                    <option value="CC">Conta corrente</option>
                                                    <option value="PP">Conta poupança</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label>Código do banco</label>
                                                <input type="text" onChange={(e) => this.setState({ bankCode: e.target.value })} value={this.state.bankCode} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Agencia</label>
                                                <input type="text" onChange={(e) => this.setState({ bankAgency: e.target.value })} value={this.state.bankAgency} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Digito agencia</label>
                                                <input type="text" onChange={(e) => this.setState({ bankAgencyDigit: e.target.value })} value={this.state.bankAgencyDigit} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Conta</label>
                                                <input type="text" onChange={(e) => this.setState({ bankAccount: e.target.value })} value={this.state.bankAccount} className="form-control" />
                                            </div>
                                            <div className="col-lg-3">
                                                <label>Digito Conta</label>
                                                <input type="text" onChange={(e) => this.setState({ bankAccountDigit: e.target.value })} value={this.state.bankAccountDigit} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <h5>Formas de pagamentos</h5>
                                            <div className='col-lg-12 mt-3'>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="paymentMethodBoleto" checked={this.state.paymentMethodBoleto} onChange={(e) => this.setState({ paymentMethodBoleto: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" for="paymentMethodBoleto">
                                                        Boleto bancário ( Por boleto pago R$ 1,70 )
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="paymentMethodCredito" checked={this.state.paymentMethodCredito} onChange={(e) => this.setState({ paymentMethodCredito: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" for="paymentMethodCredito">
                                                        Cartão de crédito ( 2,80% 1x )
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="paymentMethodPix" checked={this.state.paymentMethodPix} onChange={(e) => this.setState({ paymentMethodPix: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" for="paymentMethodPix">
                                                        Pix ( 1,00% )
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 mt-3'>
                                                <div className='alert alert-warning'>
                                                    <strong>Atenção:</strong> todas as transações são processadas por <a href='https://www.safe2pay.com.br/' target='_blank'>Safe2Pay</a> e as taxas acima podem sofrer alterações sem aviso prévio.
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <a href='https://admin.safe2pay.com.br/taxas' className='btn btn-sm btn-primary' target='_blank'>Acessar painel</a>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                        </div> */}
                                    </Tab>
                                    <Tab eventKey="nota" title="NFS-e">
                                        {
                                            this.state.isLoadingNote && <Loading />
                                        }
                                        {
                                            !this.state.isLoadingNote &&
                                            <div className='mt-3'>
                                                <div className='alert alert-success mt-3'>
                                                    <strong>Informação:</strong> Emita automaticamente as notas fiscais de serviço. <p />
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" id="habilitarEmissaoNotaFiscal" checked={this.state.habilitarEmissaoNotaFiscal} onChange={(e) => this.setState({ habilitarEmissaoNotaFiscal: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEmissaoNotaFiscal">
                                                            Habilitar emissão de nota fiscal
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.habilitarEmissaoNotaFiscal &&
                                                    <ConfigurarNotaNovoBody key={this.state.settings} fromRessale={true} settings={this.state.settings} save={this.updateNote} showAppTab={false} />
                                                }
                                            </div>
                                        }
                                    </Tab>
                                    <Tab eventKey="suporte" title="Suporte">
                                        <div className='row mt-3'>
                                            {
                                                isPlatformOwner() &&
                                                <div className="col-lg-3">
                                                    <label>Tipo de suporte</label>
                                                    <select className="form-select" value={this.state.tipoSuporte} onChange={this.changeTypeSupport}>
                                                        <option value="SELFPARKING">SelfParking</option>
                                                        <option value="REVENDA">Revenda</option>
                                                        <option value="CONCESSIONARIO">Concessionário</option>
                                                    </select>
                                                </div>
                                            }
                                            {
                                                this.state.tipoSuporte !== "CONCESSIONARIO" && <>
                                                    <div className="col-lg-3">
                                                        <label>WhatsApp suporte</label>
                                                        <NumberFormat className="form-control" disabled={this.state.tipoSuporte === "SELFPARKING"} value={this.state.whatsAppSuporte} mask='_' onChange={(e) => this.setState({ whatsAppSuporte: e.target.value })} format="(##)#####-####" />
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <label>E-mail suporte</label>
                                                        <input type="site" onChange={(e) => this.setState({ emailSuporte: e.target.value })} disabled={this.state.tipoSuporte === "SELFPARKING"} value={this.state.emailSuporte} className="form-control" />
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.tipoSuporte === "CONCESSIONARIO" && <div className='mt-3'>
                                                    <AlertWarning message="É preciso associar um contato de suporte do concessionário para cada cliente." />
                                                </div>
                                            }
                                            <div className='mt-3'>
                                                <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                            </div>
                                        </div>
                                    </Tab>
                                    {
                                        isPlatformOwner() &&
                                        <Tab eventKey="taxas" title="Taxas">
                                            <div className='row mt-3'>
                                                <div className="col-lg-3">
                                                    <label>Taxa revenda ( % )</label>
                                                    <input type="text" maxLength={3} onChange={(e) => this.setState({ taxa: e.target.value })} value={this.state.taxa} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Imposto ( % )</label>
                                                    <input type="text" maxLength={3} onChange={(e) => this.setState({ imposto: e.target.value })} value={this.state.imposto} className="form-control" />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Tipo custo</label>
                                                    <select className="form-select" value={this.state.tipoCusto} onChange={(e) => this.setState({ tipoCusto: e.target.value })}>
                                                        <option value="IMPLANTACAO">Implantação</option>
                                                        <option value="MENSAL">Mensal</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Custo integração</label>
                                                    <CurrencyInput value={this.state.custo} prefix="R$" className='form-control' onChangeEvent={this.changeCusto} />
                                                </div>
                                                <div className='mt-3'>
                                                    <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {
                                        isPlatformOwner() &&
                                        <Tab eventKey="lojas" title="Lojas">
                                            <div className='col-lg-12 mt-3'>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posGooglePlay" checked={this.state.posGooglePlay} onChange={(e) => this.setState({ posGooglePlay: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posGooglePlay">
                                                        Google Play
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posPagSeguro" checked={this.state.posPagSeguro} onChange={(e) => this.setState({ posPagSeguro: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posPagSeguro">
                                                        PagSeguro
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posStone" checked={this.state.posStone} onChange={(e) => this.setState({ posStone: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posStone">
                                                        Stone
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posGetnet" checked={this.state.posGetnet} onChange={(e) => this.setState({ posGetnet: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posGetnet">
                                                        Getnet
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posSafra" checked={this.state.posSafra} onChange={(e) => this.setState({ posSafra: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posSafra">
                                                        Safra
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posRede" checked={this.state.posRede} onChange={(e) => this.setState({ posRede: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posRede">
                                                        Rede
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="posCielo" checked={this.state.posCielo} onChange={(e) => this.setState({ posCielo: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="posCielo">
                                                        Cielo
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 mt-3'>
                                                <div className='col-lg-3'>
                                                    <label>Revendas associadas:</label>
                                                    <Select
                                                        isMulti
                                                        name="excluded_status"
                                                        value={this.state.revendasAssociadas}
                                                        options={this.state.allRessales}
                                                        onChange={this.handleRessalesAssociateds}
                                                        className="basic-multi-select"
                                                    />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <button type="button" onClick={this.saveSettings} className="btn btn-success">Atualizar informações</button>
                                            </div>
                                        </Tab>
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </NavBar >
        )
    }
}


class RevendaDetalhesAllClientes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            count: 0
        }
        this.load()
    }

    load = async () => {
        const count = await countClientByReseleId({ revendaId: this.props.revendaId })
        this.setState({ count: count })
    }

    render() {
        return (
            <a href={`/revenda/cliente?revendaId=${this.props.revendaId}`}>
                <div className="card text-light bg-success mb-3" role="button">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Total clientes</h5>
                        <h3 className="card-text text-center text-white">
                            <span>{this.state.count}</span>
                        </h3>
                    </div>
                </div>
            </a>
        )
    }
}

class RevendaDetalhesAllParksNotFree extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            count: 0
        }
        this.load()
    }

    load = async () => {
        const count = await getAllLicencasByRevendaId({ revendaId: this.props.revendaId })
        this.setState({ count: count.filter(e => e.status === "PAYED" || e.status === "GENERATED").length })
    }

    render() {
        return (
            <a href={`/revenda/financeiro/gerenciar-assinaturas?revendaId=${this.props.revendaId}`}>
                <div className="card text-light bg-success mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Total pagantes</h5>
                        <h3 className="card-text text-center text-white">
                            <span>{this.state.count}</span>
                        </h3>
                    </div>
                </div>
            </a>
        )
    }
}

class RevendaDetalhesAllEstabelecimentos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            count: 0
        }
        this.load()
    }

    load = async () => {
        const count = await countParksByReseleId({ revendaId: this.props.revendaId })
        this.setState({ count: count })
    }

    render() {
        return (
            <div className="card text-light bg-info mb-3" role="button">
                <div className="card-body">
                    <h5 className="card-title text-center text-white">Estabelecimentos</h5>
                    <h3 className="card-text text-center text-white">
                        <span>{this.state.count}</span>
                    </h3>
                </div>
            </div>
        )
    }
}

export { ListarRevendas, RevendaDetalhes, RevendaDetalhesAllEstabelecimentos }
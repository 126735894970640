import moment from 'moment';
import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { hoursToMin } from '../../shared/utils';
import { deleteDoc } from './collectionBaseWorker';

export const getAllPaymentTables = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection("tabela-precos").where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItems(result).sort((a, b) => b.nome - a.nome).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getAllActivePaymentTables = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection("tabela-precos").where("status", "==", "true").where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItems(result).sort((a, b) => b.nome - a.nome).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getAllPaymentTablesBySectorId = async ({ sectorId }) => {
    const result = await firebase.firestore().collection("tabela-precos").where("setorAssociadoId", "==", sectorId).get()
    return docsToItems(result)
}

export const deletePaymentTableById = async ({ id }) => {
    await deleteDoc({ collection: "tabela-precos", id: id })
}

export const addPaymentTable = async ({ clienteId, estacionamentoId, revendaId }) => {
    const doc = await firebase.firestore().collection('tabela-precos').add({
        nome: "Periodo Integral",
        estacionamentoId: estacionamentoId,
        clienteId: clienteId,
        valorMaximo: 20.0,
        valorHoraAdicional: 2.0,
        periodoMaximo: "12:00",
        tipo: "Carro",
        principal: "true",
        status: "true",
        periodos: [],
        revendaId: revendaId ?? getRevenda()?.id,
        semanas: {
            horarioInicio: "07:00", horarioFim: "22:00",
            dias: {
                segunda: true,
                terca: true,
                quarta: true,
                quinta: true,
                sexta: true,
                sabado: true,
                domingo: true
            }
        },
        isDeleted: false,
    })
    return doc.id;
}

export const getCurrentPriceTableByDayAndTime = async ({ parkId, type }) => {
    const currentDate = moment()
    const currentMinute = hoursToMin(currentDate.format("HH:mm"))
    var field = ""
    switch (currentDate.isoWeekday()) {
        case 1: field = "semanas.dias.segunda"; break
        case 2: field = "semanas.dias.terca"; break
        case 3: field = "semanas.dias.quarta"; break
        case 4: field = "semanas.dias.quinta"; break
        case 5: field = "semanas.dias.sexta"; break
        case 6: field = "semanas.dias.sabado"; break
        case 7: field = "semanas.dias.domingo"; break
    }
    const result = await firebase.firestore().collection('tabela-precos').where(field, "==", true).where("status", "==", 'true').where("estacionamentoId", "==", parkId).get()
    const items = docsToItems(result)
    const found = items.filter(e => e.tipo === (type ?? "carro") && e.habilitarVinculacaoNoTotem !== false && e.isAllDay === true || (currentMinute >= hoursToMin(e.semanas.horarioInicio) && currentMinute <= hoursToMin(e.semanas.horarioFim)))
    if (found.length >= 1) {
        return found[0]
    }
    return null
}
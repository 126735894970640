import { getUsuario } from '../config/auth'
import firebase from '../config/firebase';

export const sendLastAccess = async () => {
    const usuario = getUsuario()
    if (usuario.administrativo === true) {
        return
    }
    await firebase.firestore().collection('usuarios').doc(usuario.id).update({
        lastAccess: new Date(),
        version: '',
        flavor: 'site'
    })
}
import React from 'react';
import store from '../src/store';
import moment from 'moment';
import ptLocale from "moment/locale/pt";
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Routes from './config/routes'

moment().locale("pt", [ptLocale]);

function App() {

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistStore(store)} >
          <Routes />
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  );
}

export default App;

import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';

export const addPayment = async ({ clienteId, estacionamentoId, nome, tipo, status, revendaId }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? getRevenda()?.id,
        nome: nome,
        tipo: tipo,
        status: status,
        isDeleted: false
    }
    const doc = await firebase.firestore().collection('formasPagamentos').add(data)
    return doc.id;
}
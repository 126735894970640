import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento, getRevenda, isAdministrador, setUsuario } from '../../config/auth'
import { ManagerDashboard, TicketDashboard } from '../dashboard/dashboard-ticket'
import { ProductsDashboard } from '../dashboard/dashboard-products'
import { CashierRevenuesDashboard } from '../dashboard/dashboard-cashier'
import { getUserBySession, updateNotShowBannerDownloadedApp, updateNotShowBannerNFE, updateUserById } from '../../store/collections/userWorker';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { goToNewWindow, goToWindow, isNotNull, isNull, isSelfParking, normalizedField, queryString, reloadWindow, takeIfIsNotNullOrEmpty } from '../../shared/utils';
import { sendClickButton, sendScreenView } from '../../shared/analytics';
import { getParameter, updateParameter } from '../../store/collections/parameterWorker';
import { OpenCashier } from '../cashier/components';
import { activeCashier } from '../../store/collections/cashierWorker';
import { getAllTicketsOpenByParkId, getTicketsOpenByParkId } from '../../store/collections/ticketWorker';

class Home extends React.Component {

    constructor(props) {
        super(props)
        const user = getUsuario()
        this.state = {
            isLoading: true,
            hasCashier: false,
            caixa: null,
            caixaId: "",
            estacionamentoId: getEstacionamento()?.id,
            showDownloadedApp: false,
            notShowDownloadApp: false,
            showBannerMonthlyCharge: false,
            phone: user.cellphone,
            name: user.nome
        }
    }

    componentDidMount() {
        this.loadingCurrentCashier()
        this.loadCurrentUser()
        this.verifyDownloadApp()
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Menu", "Home | Por e-mail")
        }
    }

    loadCurrentUser = async () => {
        const currentUser = await getUserBySession()
        if (currentUser.isTermsUpdated) {
            window.location.href = "/conta/termos"
        }
    }

    loadingCurrentCashier = async () => {
        if (this.state.estacionamentoId) {
            this.setState({ isLoading: true });
            const cashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
            if (cashier) {
                const tickets = await getTicketsOpenByParkId(this.state.estacionamentoId)
                this.setState({ tickets: tickets })
                this.setState({ caixaId: cashier.id })
                this.setState({ caixa: cashier })
                this.setState({ hasCashier: true })
            } else {
                this.setState({ hasCashier: false })
            }
            this.setState({ isLoading: false })
        }
    }

    applyFilter = () => {
        this.setState({ random: Math.random() })
    }

    verifyDownloadApp = () => {
        if (isSelfParking()) {
            var user = getUsuario()
            if (isAdministrador()) {
                const parameter = getParameter()
                if (isNull(parameter?.habilitarPagamentoOnlineMensalista)) {
                    if (isNull(user.showBannerMonthlyCharge)) {
                        this.setState({ showBannerMonthlyCharge: true })
                    }
                }
            }
            // if (user !== null && (user.notShowBannerDownloadedApp === undefined || user.notShowBannerDownloadedApp === false)) {
            //     this.setState({ showDownloadedApp: true })
            // } else {
            //     this.setState({ showDownloadedApp: false })
            // }
            // if (!parameter.habilitarEmissaoNotaV2) {
            //     if (user !== null && (user.notShowBannerNFE === undefined || user.notShowBannerNFE === false)) {
            //         this.setState({ showNFEBanner: true })
            //     } else {
            //         this.setState({ showNFEBanner: false })
            //     }
            // }
        }
    }

    closeDownloadApp = async () => {
        this.setState({ showDownloadedApp: false })
    }

    closeDownloadAppNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            user.notShowBannerDownloadedApp = true
            setUsuario(user)
            await updateNotShowBannerDownloadedApp({ userId: user.id, notShowBannerDownloadedApp: true })
            reloadWindow()
        }
    }

    closeNFEAppNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showNFEBanner: false })

            user.notShowBannerNFE = true
            setUsuario(user)
            await updateNotShowBannerNFE({ userId: user.id, notShowBannerNFE: true })
            const message = `O usuário ${user.nome} tocou no banner de não ter interesse em habilitar emissão de notas.`
            sendClickButton("Banner home", message)
        }
    }

    closeAndEnableNFE = async () => {
        var user = getUsuario()
        if (user !== null) {
            user.notShowBannerNFE = true
            setUsuario(user)
            await updateNotShowBannerNFE({ userId: user.id, notShowBannerNFE: true })

            const message = `O usuário ${user.nome} tocou no banner de habilitar emissão de notas.`
            sendClickButton("Banner home", message)

            window.location.href = "/nota-fiscal/configurar"
        }
    }

    closeBannerMonthlyChargeNotShowAnymore = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showBannerMonthlyCharge: false })
            user.showBannerMonthlyCharge = true
            setUsuario(user)
            await updateUserById({ id: user.id, data: user })

            const message = `O usuário ${user.nome} tocou no banner de não ter interesse em habilitar cobrança de mensalista.`
            sendClickButton("Banner home", message)
        }
    }

    closeBannerMonthlyChargeAndEnable = async () => {
        var user = getUsuario()
        if (user !== null) {
            this.setState({ showBannerMonthlyCharge: false })
            user.showBannerMonthlyCharge = true
            setUsuario(user)
            await updateUserById({ id: user.id, data: user })

            const message = `O usuário ${user.nome} tocou no banner de habilitar cobrança de mensalista.`
            sendClickButton("Banner home", message)

            let revenda = getRevenda()
            const url = `https://api.whatsapp.com/send?phone=55${normalizedField(revenda.celular)}&message=Quero%20habilitar%20a%20cobrança%20dos%20mensalistas`
            goToNewWindow(url)
        }
    }

    openNewCashier = () => {
        this.setState({ showModalToOpenCashier: true })
    }

    render() {
        return (
            <>
                <NavBar>
                    <Dialog maxWidth='sm' fullWidth open={this.state.showBannerMonthlyCharge}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <img alt='Cobrança mensalista' style={{ width: "100%", height: "auto", borderRadius: "8px" }} src={require('../../files/marketing/cobranca_mensalista_quadrado.svg')} />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeBannerMonthlyChargeNotShowAnymore} className='btn btn-secondary'>Não tenho interesse</button>
                                    <button onClick={this.closeBannerMonthlyChargeAndEnable} className='btn btn-success'>Falar com especialista</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='xs' fullWidth open={this.state.showNFEBanner}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <h3>Mais facilidade no seu<br /> dia a dia.</h3>
                                    <span>Agora com a SelfParking você consegue <strong>emitir até 20 notas fiscais de serviço</strong> sem custo para pagamentos de tickets e mensalistsa com alguns cliques!</span> <p />
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeNFEAppNotShowAnymore} className='btn btn-secondary'>Não tenho interesse</button>
                                    <button onClick={this.closeAndEnableNFE} className='btn btn-success'>Habilitar emissão de notas</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth='sm' open={this.state.showDownloadedApp}>
                        <MuiDialogContent>
                            <div className='text-center'>
                                <div className='my-4'>
                                    <h3>Bem vindo ao Self<strong>Parking</strong></h3>
                                    <span>Em breve entraremos em contato para te ajudar a configurar o estabelecimento do seu jeito.</span> <p />
                                    <span>Aproveite para baixar o nosso aplicativo.</span> <p />
                                    <div className='col-md-12 col-lg-12 col-sm-12'>
                                        <a href="https://play.google.com/store/apps/details?id=com.selfparking.app" rel="noopener noreferrer" target="_blank">
                                            <img src={require('../../files/animations/download_app.gif')} width={300} height={300} alt="Baixar aplicativo" />
                                            <img width='200' alt='Baixar aplicativo' src={require('../../files/icons/google-play-badge.png')} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="m-3">
                                <div className="btn-group">
                                    <button onClick={this.closeDownloadAppNotShowAnymore} className='btn btn-secondary'>Fechar e não mostrar novamente</button>
                                    <button onClick={this.closeDownloadApp} className='btn btn-success'>Fechar</button>
                                </div>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    {
                        this.state.isLoading ? <></> :
                            <>
                                {this.state.hasCashier ?
                                    <div className='row'>
                                        <div className='col-sm-2 col-lg-1 col-xs-2 mb-3'>
                                            <ManagerDashboard />
                                        </div>
                                        <div className='col-sm-10 col-lg-11 col-xs-10 mb-3'>
                                            <TicketDashboard
                                                key={this.state.random + 1}
                                                estacionamentoId={this.state.estacionamentoId}
                                                tickets={this.state.tickets}
                                                caixaId={this.state.caixaId} />
                                            {/* <div className='row mt-3'>
                                                <CashierRevenuesDashboard key={this.state.random + 3}
                                                    estacionamentoId={this.state.estacionamentoId}
                                                    tickets={this.state.tickets}
                                                    caixa={this.state.caixa} />
                                                <ProductsDashboard key={this.state.random + 2}
                                                    estacionamentoId={this.state.estacionamentoId}
                                                    tickets={this.state.tickets}
                                                    caixa={this.state.caixa} />
                                            </div> */}
                                        </div>
                                    </div> :
                                    <>
                                        <div className='col-lg-12 text-center my-5'>
                                            <img alt='Icone produtos vazios' width='130' height='130' src={require('../../files/icons/icon_report.png')} />
                                            <div className='col-lg-12'>
                                                <div>
                                                    <h2 className='item-h4 my-3'>Olá, {this.state.name} <p />Abra um caixa e acompanhe <br />em tempo real os lançamentos.</h2>
                                                </div>
                                                <div className="m-3">
                                                    <div className="btn-group">
                                                        <button onClick={this.openNewCashier} className='btn btn-sm btn-success'>Toque para abrir um caixa</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='container my-5'>
                                                <div className='row d-flex justify-content-center'>
                                                    <div className='col-lg-6 col-6'>
                                                        <a href={'/relatorios/vendas'}>
                                                            <img alt='Icone produtos vazios' width='60' height='60' src={require('../../files/icons/icon_product.png')} />
                                                            <h5 className='item-h4 my-3 text-success'>Toque e veja o faturamento de produtos e serviço
                                                                <i className="fas fa-long-arrow-right mx-2" />
                                                            </h5>
                                                        </a>
                                                    </div>
                                                    <div className='col-lg-6 col-6'>
                                                        <a href={'/relatorios/caixas'}>
                                                            <img alt='Icone produtos vazios' width='60' height='60' src={require('../../files/icons/icon_money.png')} />
                                                            <h5 className='item-h4 my-3 text-warning'>Toque e veja o faturamento de caixas
                                                                <i className="fas fa-long-arrow-right mx-2" />
                                                            </h5>
                                                        </a>
                                                    </div>
                                                    <div className='col-lg-6 col-6'>
                                                        <a href={'/relatorios/caixas'}>
                                                            <img alt='Icone produtos vazios' width='60' height='60' src={require('../../files/icons/icon_ticket.png')} />
                                                            <h5 className='item-h4 my-3 text-primary'>Toque para buscar veículos lançados
                                                                <i className="fas fa-long-arrow-right mx-2" />
                                                            </h5>
                                                        </a>
                                                    </div>
                                                    <div className='col-lg-6 col-6'>
                                                        <a href={'/cadastrar/mensalista'}>
                                                            <img alt='Icone produtos vazios' width='60' height='60' src={require('../../files/icons/icon_calendar.png')} />
                                                            <h5 className='item-h4 my-3 text-danger'>Toque para buscar todos os mensalistas
                                                                <i className="fas fa-long-arrow-right mx-2" />
                                                            </h5>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                    }
                    {
                        this.state.showModalToOpenCashier === true && <OpenCashier showModal={this.state.showModalToOpenCashier} />
                    }
                </NavBar >
            </>
        )
    }
}

export default Home;
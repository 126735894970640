import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent } from '../../../components/common/commons'
import { setMinimumVersions } from '../../../config/auth'
import firebase from '../../../config/firebase';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'firebase/auth';
import 'firebase/storage';
import { reloadWithAlert, reloadWithAlertError } from '../../../shared/utils';

const initializeState = {
    id: null,
    versions: [],
    newsMessage: "",
    showModalNews: false
}

class DeveloperMinimumVersion extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
        this.load()
    }

    load = async () => {
        this.setState({ loading: true });
        const results = await firebase.firestore().collection('remote-config').where("key", "==", "NEW_MINIMUM_VERSION").get()
        var versions = JSON.parse(results.docs[0].data()["value"])
        this.setState({ versions: versions })
        this.setState({ id: results.docs[0].id })
        this.setState({ loading: false });
    }

    handlerLimitDate = (value, index) => {
        const minimum = Moment(value).format("DD/MM/yyyy")
        this.state.versions[index].limited_date = minimum
        this.setState({ versions: this.state.versions })
    }

    handlerMinimumVersion = (value, index) => {
        this.state.versions[index].minimum = value
        this.setState({ versions: this.state.versions })
    }

    handlerNews = (value, index) => {
        this.state.versions[index].newsMessage = value
        this.setState({ newsMessage: this.state.newsMessage })
    }

    openModalWhatIsNews = (value, index) => {
        this.setState({ newsMessage: value })
        this.setState({ currentIndex: index })
        this.setState({ showModalNews: true })
    }

    saveWhatIsNews = () => {
        const index = this.state.currentIndex
        const newsMessage = this.state.newsMessage
        this.state.versions[index].newsMessage = newsMessage
        this.setState({ showModalNews: false })
    }

    saveWhatIsNewsAll = () => {
        for (let index = 0; index < this.state.versions.length; index++) {
            const newsMessage = this.state.newsMessage
            this.state.versions[index].newsMessage = newsMessage
            this.setState({ showModalNews: false })
        }
    }

    update = () => {
        setMinimumVersions(this.state.versions)
        firebase.firestore().collection('remote-config').doc(this.state.id).update({
            value: JSON.stringify(this.state.versions)
        }).then(() => {
            reloadWithAlert("Versão miníma alterada com sucesso!")
        }).catch(error => {
            reloadWithAlertError(error.message);
        });
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Versões minímas
                    </div>
                    {
                        this.state.versions.length === 0 && this.state.loading === false ?
                            <EmptyContent text='Nenhuma versão mínima cadastrada' />
                            :
                            this.state.loading === false ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plataforma</th>
                                                <th scope="col">Versão miníma</th>
                                                <th scope="col">Data limite</th>
                                                <th scope="col" width={10}>Novidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.versions.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {item.store?.platform?.includes("pagSeguro") && 'PagSeguro'}
                                                            {item.store?.platform?.includes("cielo") && 'Cielo'}
                                                            {item.store?.platform?.includes("getnet") && 'GetNet'}
                                                            {item.store?.platform?.includes("Stone") && 'Stone'}
                                                            {item.store?.platform?.includes("safra") && 'Safra'}
                                                            {item.store?.platform?.includes("store") && 'Google Play'}
                                                            {item.store?.platform?.includes("rede") && 'Rede'}
                                                            {item.store?.platform?.includes("site") && 'Site'}
                                                        </td>
                                                        <td>
                                                            <input type="text" onChange={(e) => this.handlerMinimumVersion(e.target.value, index)} value={item.minimum} className="form-control" />
                                                        </td>
                                                        <td>
                                                            <MobileDatePicker
                                                                label="Data limite"
                                                                inputFormat="DD/MM/yyyy"
                                                                value={Moment(item.limited_date, "DD/MM/yyyy")}
                                                                onChange={(e) => { this.handlerLimitDate(e, index) }}
                                                                renderInput={(params) => <TextField {...params} size="small" className='btn-block' />}
                                                            />
                                                        </td>
                                                        <td width={10}>
                                                            <button type="button" onClick={(e) => this.openModalWhatIsNews(item.newsMessage, index)} className="btn btn-success">Alterar</button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className="card-footer">
                                        <button type="button" onClick={this.update} className="btn btn-primary">Salvar alterações</button>
                                    </div>
                                    <Dialog maxWidth={"lg"} onClose={e => this.setState({ showModalNews: false })} open={this.state.showModalNews}>
                                        <MuiDialogTitle className='text-center'>
                                            Novidades da versão
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div className="alert alert-secondary" role="alert">
                                                Informe aos clientes as novidades que estarão nesta versão.
                                            </div>
                                            <textarea className='form-control' rows='10' cols={10} value={this.state.newsMessage} onChange={(e) => this.setState({ newsMessage: e.target.value })} />
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className="btn-group m-3">
                                                <button onClick={this.saveWhatIsNewsAll} className='btn btn-warning text-white'>Confirmar a todos</button>
                                                <button onClick={this.saveWhatIsNews} className='btn btn-primary'>Confirmar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </NavBar>
        )
    }
}

export { DeveloperMinimumVersion };
import React from 'react'
import { NavBar } from '../../../components/navbar/index.js'
import { Loading, EmptyContent } from '../../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getEstacionamento, getUsuario } from '../../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { deleteEquipment, getAllEquipmentsByParkId, updateEquipment } from '../../../store/collections/equipmentWorker.js';
import { getRemoteConfigByKey } from '../../../store/collections/remoteConfigWorker.js';
import { TicketListModal } from '../../ticket/index.js';
import { getAllCamerasLPRByParkId } from '../../../store/collections/cameraLPRWorker.js';
import { CancelaCameraLPRPreview } from '../lpr/index.js';
import { AlertWarning } from '../../../shared/alert-manager.js';
import { addUserLog } from '../../../store/collections/userLogsWorker.js';
import { updateTicket } from '../../../store/collections/ticketWorker.js';

class CancelaListarEquipamentos extends React.Component {

    render() {
        return (
            <NavBar>
                <CancelaListarEquipamentosBody />
            </NavBar>
        )
    }
}

class CancelaListarEquipamentosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            allEmergencyModel: false,
            items: [],
            camerasLPR: [],
            isLoading: true,
            lprIdentifier: "",
            delay: "0"
        }
    }

    componentDidMount = async () => {
        await this.load(true)
        await this.loadCommands()
    }

    load = async (isLoading) => {
        this.setState({ isLoading: isLoading })
        var items = await getAllEquipmentsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allEmergencyModel: items.filter(e => e.emergency === true).length === items.length })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    loadCommands = async () => {
        const item = await getRemoteConfigByKey({ key: "GATE_TOTEMS_COMMANDS" })
        if (item) {
            this.setState({ commands: JSON.parse(item.value) })
        }
    }

    showEditEquipmentNameModal = async (item) => {
        this.setState({ equipmentSelected: item })
        this.setState({ ...item })

        this.setState({ printTicketForMonthlyOrAccredited: item.printTicketForMonthlyOrAccredited ?? true })
        this.setState({ printTicketForSeparate: item.printTicketForSeparate ?? true })
        this.setState({ printTicketForSeparateWithTag: item.printTicketForSeparateWithTag ?? true })
        this.setState({ isShowingEquipmentSettings: true })
    }

    saveEquipmentSettings = async () => {
        var equipment = this.state.equipmentSelected
        equipment.name = this.state.name
        equipment.delay = this.state.delay
        equipment.printTicketForMonthlyOrAccredited = this.state.printTicketForMonthlyOrAccredited
        equipment.printTicketForSeparate = this.state.printTicketForSeparate
        equipment.printTicketForSeparateWithTag = this.state.printTicketForSeparateWithTag
        await updateEquipment({ id: equipment.id, data: equipment })
        this.setState({ isShowingEquipmentSettings: false })
        await this.load()
    }

    showSettingsLPRModal = async (item) => {
        this.setState({ equipmentSelected: item })
        this.setState({ lprIdentifier: item.lprIdentifier })
        const items = await getAllCamerasLPRByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ camerasLPR: items })
        this.setState({ isShowingLPRModal: true })
    }

    confirmCameraLPR = async () => {
        var equipment = this.state.equipmentSelected
        equipment.lprIdentifier = this.state.lprIdentifier
        await updateEquipment({ id: equipment.id, data: equipment })
        this.setState({ isShowingLPRModal: false })
        await this.load()
    }

    showCommandEquipmentModal = (item) => {
        this.setState({ equipmentSelected: item })
        this.setState({ isShowingCommandModal: true })
    }

    cancelCommand = async () => {
        var equipment = this.state.equipmentSelected
        equipment.command = null
        equipment.commandDescription = null
        await updateEquipment({ id: equipment.id, data: equipment })
        this.setState({ isShowingCommandModal: false })
        await this.load()
    }

    verifyCommand = async (item) => {
        if (item.commandParams === "ticket") {
            this.setState({ commandSelected: item })
            this.setState({ isShowingCommandModal: false })
            this.setState({ showListTicketsModal: true })
        } else {
            this.confirmCommandEquipment(item)
        }
    }

    confirmCommandEquipment = async (item) => {
        var equipment = this.state.equipmentSelected
        equipment.command = item.command
        equipment.commandDescription = item.commandDescription
        equipment.commandValue = item.commandValue
        await updateEquipment({ id: equipment.id, data: equipment })
        this.setState({ isShowingCommandModal: false })
        await this.load()
    }

    onSelectedTicket = async (ticket) => {
        var command = this.state.commandSelected
        command.commandValue = ticket.ticket
        this.confirmCommandEquipment(command)

        const user = getUsuario()
        const message = `O operador ${user.nome} abriu a cancela manualmente para o ticket ${ticket.ticket}`
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            message: message,
            createdAt: new Date(),
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email
            }
        })
        await updateTicket(ticket.id, ticket)
        await addUserLog({
            estacionamentoId: ticket.estacionamentoId,
            module: "Totem",
            message: message
        })
        this.onCloseList()
    }

    onCloseList = () => {
        this.setState({ showListTicketsModal: false })
    }

    removeEquipment = async (item) => {
        var confirm = window.confirm(`Confirma mesmo a remoção deste equipamento?`);
        if (confirm) {
            await deleteEquipment({ id: item.id })
            await this.load()
        }
    }

    changeEquipmentEmergencyMode = async (item, emergency) => {
        item.emergency = emergency
        await updateEquipment({ id: item.id, data: item })
        await this.load()
    }

    changeEquipmentBlockGate = async (item, blocked) => {
        item.blocked = blocked
        await updateEquipment({ id: item.id, data: item })
        await this.load()
    }

    changeEquipmentsEmergencyMode = async (emergency) => {
        for (const item of this.state.items) {
            item.emergency = emergency
            await updateEquipment({ id: item.id, data: item })
        }
        await this.load()
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Totens
                    {
                        this.state.items.length > 0 && !this.state.isLoading &&
                        <div className='btn-group'>
                            {
                                this.state.allEmergencyModel ?
                                    <Tooltip title="Desativar modo de emergência" placement="top">
                                        <button type="button" onClick={() => { this.changeEquipmentsEmergencyMode(false) }} className="btn btn-sm btn-danger">
                                            <i className="fas fa-fire-extinguisher mx-2" />
                                            Emergência
                                        </button>
                                    </Tooltip> :
                                    <Tooltip title="Ativar modo de emergência" placement="top">
                                        <button type="button" onClick={() => { this.changeEquipmentsEmergencyMode(true) }} className="btn btn-sm btn-success">
                                            <i className="fas fa-fire-extinguisher mx-2" />
                                            Emergência
                                        </button>
                                    </Tooltip>
                            }
                        </div>
                    }
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Configure um totem e ele aparecerá aqui.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td align='center' width='10' />
                                                        <td align='left'><strong>Nome</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Identificador</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Impressora</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Memória</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Última atualização</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='center'>
                                                                    {
                                                                        item.memory?.updatedAt ?
                                                                            <Tooltip title={Moment(item.memory?.updatedAt?.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")} placement="top">
                                                                                {
                                                                                    item.memory?.status === "on" ?
                                                                                        <span className="badge text-bg-success w-100">Ligado</span> :
                                                                                        <span className="badge text-bg-danger w-100">Desligado</span>
                                                                                }
                                                                            </Tooltip> :
                                                                            <div>
                                                                                {
                                                                                    item.memory?.status === "on" ?
                                                                                        <span className="badge text-bg-success w-100">Ligado</span> :
                                                                                        <span className="badge text-bg-danger w-100">Desligado</span>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </td>
                                                                <td align='left'>{item.name ?? "Não informado"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.equipmentId ?? "Não informado"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.printer?.status ?? "Sem status"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.energy?.status ?? "Sem status"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">
                                                                    {
                                                                        item.updatedAt && <span>
                                                                            {Moment(item.updatedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}
                                                                        </span>
                                                                    }
                                                                </td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        <Tooltip title="Configurações" placement="top">
                                                                            <button className='btn btn-secondary' onClick={(e) => this.showEditEquipmentNameModal(item)}>
                                                                                <i className="fas fa-gear" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title={item.lprIdentifier ? "Editar câmera LPR" : "Configurar câmera LPR"} placement="top">
                                                                            <button className='btn btn-success' onClick={(e) => this.showSettingsLPRModal(item)}>
                                                                                <i className="fas fa-camera" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        {
                                                                            item.emergency === true ?
                                                                                <Tooltip title="Desativar modo de emergência" placement="top">
                                                                                    <button className='btn btn-danger' onClick={(e) => this.changeEquipmentEmergencyMode(item, false)}>
                                                                                        <i className="fas fa-fire-extinguisher" />
                                                                                    </button>
                                                                                </Tooltip> :
                                                                                <>
                                                                                    <Tooltip title="Ativar modo de emergência" placement="top">
                                                                                        <button className='btn btn-success' onClick={(e) => this.changeEquipmentEmergencyMode(item, true)}>
                                                                                            <i className="fas fa-fire-extinguisher" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </>
                                                                        }
                                                                        {
                                                                            item.blocked === false ?
                                                                                <Tooltip title="Bloquear entrada" placement="top">
                                                                                    <button className='btn btn-success' onClick={(e) => this.changeEquipmentBlockGate(item, true)}>
                                                                                        <i className="fas fa-door-open" />
                                                                                    </button>
                                                                                </Tooltip> :
                                                                                <Tooltip title="Desbloquear entrada" placement="top">
                                                                                    <button className='btn btn-warning text-white' onClick={(e) => this.changeEquipmentBlockGate(item, false)}>
                                                                                        <i className="fas fa-door-closed" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                        }
                                                                        {
                                                                            item.emergency === false && item.blocked === false &&
                                                                            <Tooltip title="Executar comando" placement="top">
                                                                                <button className='btn btn-dark' onClick={(e) => this.showCommandEquipmentModal(item)}>
                                                                                    <i className="fas fa-terminal" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                        <Tooltip title="Remover equipamento" placement="top">
                                                                            <button className='btn btn-danger' onClick={(e) => this.removeEquipment(item)}>
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {
                                        this.state.showListTicketsModal && <TicketListModal onClose={this.onCloseList} status={["Pago e Estacionado", "Estacionado", "Pago e Aguardando", "Cancelado"]} onSelected={this.onSelectedTicket} />
                                    }
                                    <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingLPRModal} onClose={(e) => this.setState({ isShowingLPRModal: false })}>
                                        {
                                            this.state.camerasLPR.length > 0 ? <>
                                                <MuiDialogTitle className='text-center'>
                                                    {this.state.lprIdentifier ? "Editar câmera LPR" : "Configurar câmera LPR"}
                                                </MuiDialogTitle>
                                                <MuiDialogContent>
                                                    <select className='form-select' value={this.state.lprIdentifier} onChange={(e) => this.setState({ lprIdentifier: e.target.value })}>
                                                        <option value={""}>Selecionar</option>
                                                        {
                                                            this.state.camerasLPR?.map(item => { return <option value={item.identifier}>{item.name}</option> })
                                                        }
                                                    </select>
                                                    {
                                                        this.state.lprIdentifier && this.state.lprIdentifier !== "" &&
                                                        <div className='mt-3'>
                                                            <CancelaCameraLPRPreview
                                                                identifier={this.state.lprIdentifier} />
                                                        </div>
                                                    }
                                                </MuiDialogContent>
                                                <MuiDialogActions>
                                                    <div className='btn-group m-3'>
                                                        <button onClick={() => this.confirmCameraLPR()} className='btn btn-success'>Salvar</button>
                                                    </div>
                                                </MuiDialogActions>
                                            </> :
                                                <MuiDialogContent>
                                                    <div className='alert alert-warning'>
                                                        <span className="badge text-bg-warning text-white">Atenção</span><br />
                                                        <span>Este estabelecimento não tem nenhuma câmera LPR cadastrada.</span><br />
                                                        <a href='/equipamentos/cameras-lpr' className='btn btn-warning text-white mt-2'>Cadastrar câmera</a>
                                                    </div>
                                                </MuiDialogContent>
                                        }
                                    </Dialog>
                                    <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingEquipmentSettings} onClose={(e) => this.setState({ isShowingEquipmentSettings: false })}>
                                        <MuiDialogTitle className='text-center'>
                                            Configurações
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div className="col-lg-12">
                                                <label>Nome</label>
                                                <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Aguardar para enviar ticket</label>
                                                <select className='form-select' value={this.state.delay} onChange={(e) => this.setState({ delay: e.target.value })}>
                                                    <option value={"0"}>0 Segundos</option>
                                                    <option value={"3"}>3 Segundos</option>
                                                    <option value={"5"}>5 Segundos</option>
                                                    <option value={"10"}>10 Segundos</option>
                                                    <option value={"15"}>15 Segundos</option>
                                                </select>
                                            </div>
                                            {/* <div className="col-lg-12 mt-3">
                                                <AlertWarning message="Regras de impressão">
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="printTicketForMonthly" checked={this.state.printTicketForMonthlyOrAccredited} onChange={(e) => this.setState({ printTicketForMonthlyOrAccredited: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="printTicketForMonthly">
                                                            Imprimir ticket para mensalistas ou credenciados
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="printTicketForSeparate" checked={this.state.printTicketForSeparate} onChange={(e) => this.setState({ printTicketForSeparate: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="printTicketForSeparate">
                                                            Imprimir ticket para veículos avulsos
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="printTicketForSeparateWithTag" checked={this.state.printTicketForSeparateWithTag} onChange={(e) => this.setState({ printTicketForSeparateWithTag: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="printTicketForSeparateWithTag">
                                                            Imprimir ticket para veículos com tag de pagamento
                                                        </label>
                                                    </div>
                                                </AlertWarning>
                                            </div> */}
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={(e) => this.setState({ isShowingEquipmentSettings: false })} className='btn btn-secondary'>Fechar</button>
                                                <button onClick={() => this.saveEquipmentSettings()} className='btn btn-success'>Salvar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                    <Dialog maxWidth={"xs"} open={this.state.isShowingCommandModal} onClose={(e) => this.setState({ isShowingCommandModal: false })}>
                                        <MuiDialogTitle className='text-center'>
                                            Executar comando no terminal
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div>
                                                {
                                                    this.state.equipmentSelected?.command &&
                                                    <AlertWarning message={`Existe um comando pendente para este equipamento você pode aguardar que seja executado ou executar outro comando.`}>
                                                        <button onClick={() => this.cancelCommand()} className='btn btn-warning text-white'>Cancelar comando</button>
                                                    </AlertWarning>
                                                }
                                            </div>
                                            {
                                                this.state.commands?.map(item => (
                                                    <button onClick={() => this.verifyCommand(item)} className='btn btn-secondary w-100'>{item.commandDescription}</button>
                                                ))
                                            }
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={(e) => this.setState({ isShowingCommandModal: false })} className='btn btn-secondary'>Fechar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </div>
        )
    }
}
export { CancelaListarEquipamentos }
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllBranchesAndModels = async () => {
    var results = await firebase.firestore().collection("vehicle-models").get()
    return docsToItems(results).sort((a, b) => -b.value.localeCompare(a.value))
}

export const addBranchAndModel = async ({ data }) => {
    await firebase.firestore().collection('vehicle-models').add(data)
}

export const deleteBranchAndModelById = async (item) => {
    await deleteDoc({ collection: "vehicle-models", id: item.id })
}

export const updateBranchAndModel = async (item) => {
    await firebase.firestore().collection("vehicle-models").doc(item.id).update(item.data)
}
import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from "../../config/auth";
import { docsToItem } from '../transform.docs';

export const saveNewPrismSettings = async ({ typePrismaEstacionamento, maxSequencePrismaEstacionamento, sequencePrismaEstacionamento }) => {
    const user = getUsuario()
    var data = {
        clienteId: getCliente()?.id,
        estacionamentoId: getEstacionamento()?.id,
        typePrismaEstacionamento: typePrismaEstacionamento,
        maxSequencePrismaEstacionamento: maxSequencePrismaEstacionamento.toString(),
        sequencePrismaEstacionamento: sequencePrismaEstacionamento.toString(),
        revendaId: getRevendaIdByPark(),
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await firebase.firestore().collection('prism-settings').add(data)
    } else {
        await firebase.firestore().collection('prism-settings').doc(currentPrism.id).update(data)
    }
}

export const resetPrism = async () => {
    const user = getUsuario()
    var data = {
        sequenceUsing: [],
        sequencePrismaEstacionamento: "0",
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await firebase.firestore().collection('prism-settings').add(data)
    } else {
        await firebase.firestore().collection('prism-settings').doc(currentPrism.id).update(data)
    }
}

export const resetPrismSettings = async ({ estacionamentoId, typePrismaEstacionamento, maxSequencePrismaEstacionamento, sequencePrismaEstacionamento }) => {
    const user = getUsuario()
    var data = {
        clienteId: user.clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: getRevendaIdByPark(),
        typePrismaEstacionamento: typePrismaEstacionamento,
        maxSequencePrismaEstacionamento: maxSequencePrismaEstacionamento.toString(),
        sequenceUsing: [],
        sequencePrismaEstacionamento: sequencePrismaEstacionamento.toString(),
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await firebase.firestore().collection('prism-settings').add(data)
    } else {
        await firebase.firestore().collection('prism-settings').doc(currentPrism.id).update(data)
    }
}

export const getPrismSettings = async () => {
    const parkId = getEstacionamento()?.id
    if (parkId) {
        return await getPrismSettingsByParkId({ estacionamentoId: parkId })
    }
    return null
}

export const getPrismSettingsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('prism-settings').where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItem(result)
}
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';

export const getFormasPagamentos = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('formasPagamentos').where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result)
}

export const getFormasPagamentosAtivas = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('formasPagamentos').where('estacionamentoId', '==', estacionamentoId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getAllPaymentMethodByType = async ({ estacionamentoId, type }) => {
    const result = await firebase.firestore().collection('formasPagamentos').where('estacionamentoId', '==', estacionamentoId).where('tipo', '==', type).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getAllFormasPagamentos = async () => {
    const result = await firebase.firestore().collection('formasPagamentos').get()
    return docsToItems(result)
}
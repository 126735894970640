import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import Moment from 'moment';
import { docsToItem, docsToItems } from '../transform.docs';

export const addLicenca = async ({ clienteId, estacionamentoId, revendaId }) => {
    const revenda = getRevenda()
    const doc = await firebase.firestore().collection('licencas').add({
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? revenda.id,
        vencimento: Moment().add(revenda?.diasDeTeste ?? 7, 'days').toDate(),
        isDeleted: false,
    })
    return doc.id;
}

export const getLicencas = async ({ clientId }) => {
    try {
        const result = await firebase.firestore().collection('licencas').where('clienteId', '==', clientId).get()
        return { success: true, data: docsToItems(result) }
    } catch (err) {
        return { success: false, message: "Cliente não encontrado" }
    }
}

export const getAllLicencasByRevendaId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection('licencas').where('revendaId', '==', revendaId).get()
    return docsToItems(result)
}

export const getAllLicencas = async () => {
    const result = await firebase.firestore().collection('licencas').get()
    return docsToItems(result)
}

export const getAllLicencasByClienteId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection('licencas').where('clienteId', '==', clienteId).get()
    return docsToItems(result)
}

export const getLicencaByParkId = async ({ parkId }) => {
    const result = await firebase.firestore().collection('licencas').where('estacionamentoId', '==', parkId).get()
    return docsToItem(result)
}

export const getLastBillPayment = async ({ estacionamentoId }) => {
    try {
        const result = await firebase.firestore().collection('licencas-historico').where('estacionamentId', '==', estacionamentoId).get()
        if (result.empty) {
            return { success: true, data: result[0] }
        }
        var listHistorico = docsToItems(result)
        const list = listHistorico.sort((a, b) => b.buyAt.toDate() - a.buyAt.toDate()).filter(item => item.transaction?.idTransaction !== undefined || item.motivo !== undefined)
        if (list.length > 0) {
            return { success: true, data: list[0] }
        }
        return { success: false, data: null }
    } catch (err) {
        return { success: false, message: "Última cobrança não encontrada" }
    }
}

export const updateLicenceRevendaId = async ({ id, revendaId }) => {
    await firebase.firestore().collection('licencas').doc(id).update({
        revendaId: revendaId
    })
}

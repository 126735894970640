import { getRevenda, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const addHelpdesk = async ({ platform, type, criticality, body, clienteId, estacionamentoId }) => {
    const revenda = getRevenda()
    const user = getUsuario()
    const doc = await firebase.firestore().collection('helpdesk').add({
        revendaId: revenda.id,
        revendaNome: revenda.nome,
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        criticality: criticality,
        platform: platform,
        type: type,
        body: body,
        user: {
            nome: user.nome,
            email: user.email
        },
        status: "CREATED",
        createdAt: new Date(),
        isDeleted: false,
    })
    return doc.id
}

export const getAllHelpdeskByClientId = async ({ clientId }) => {
    const results = await firebase.firestore().collection('helpdesk').where("clienteId", "==", clientId).get()
    return docsToItems(results)
}

export const getAllHelpdeskByRevendaId = async ({ revendaId }) => {
    const results = await firebase.firestore().collection('helpdesk').where("revendaId", "==", revendaId).get()
    return docsToItems(results)
}

export const getAllHelpdesk = async () => {
    const results = await firebase.firestore().collection('helpdesk').get()
    return docsToItems(results)
}

export const updateHelpdesk = async ({ id, data }) => {
    await firebase.firestore().collection('helpdesk').doc(id).update(data)
}

export const deleteHelpdesk = async ({ id }) => {
    await deleteDoc({ collection: "helpdesk", id: id })
}

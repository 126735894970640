
import React from 'react'
import { documentMask, takeIfIsNotNullOrEmpty, toCurrency, timestampToSeconds, toDateFormat } from '../utils'
import { getRevenda } from '../../config/auth'
import { urlTicketOnlineFromTicket } from '../../shared/url-manager.js';
import { QRCodeSVG } from 'qrcode.react';

class PrinterHeaderComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park
        }
    }

    render() {
        return (
            <div >
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoNome && <>
                        {
                            this.state.parameters.tamanhoDoNomeDoEstacionamento === "grande" ?
                                <h5>{this.state.park.nome}</h5> :
                                <span>{this.state.park.nome}</span>
                        }
                    </>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoDocumento &&
                    <div>{documentMask(this.state.park.documento)}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoEndereco &&
                    <div>{this.state.park.endereco?.logradouro}, {this.state.park.endereco?.numero}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento &&
                    <div>{this.state.park.horarios}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoCelular &&
                    <div>{this.state.park.celular}</div>
                }
            </div>
        )
    }
}

class MessageInTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            position: props.position
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.parameters.habilitarMensagemImpressaNoTicket && this.state.position === this.state.parameters.mensagemLancamentoTicketLocal &&
                    <div>
                        {this.state.parameters.mensagemLancamentoTicket}
                    </div>
                }
            </div>
        )
    }
}

class BodyTicketInComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }
    render() {
        return (
            <div className='text-start mt-2'>
                {
                    this.state.ticket.paymentsLogged &&
                    <div className='text-center fs-6'>RECIBO DE PAGAMENTO</div>
                }
                {
                    <div className='text-center mb-1'><MessageInTicketComponent parameters={this.state.parameters} position={"comeco"} /></div>
                }
                {
                }
                {
                    <div>Entrada: {toDateFormat(this.state.ticket.createAt)}</div>
                }
                {
                    this.state.ticket.status === "Pago e Entregue" &&
                    <div>Saida: {toDateFormat(this.state.ticket.closedAt)} </div>
                }
                {
                    this.state.ticket.status === "Pago e Entregue" &&
                    <div>Permanência: {
                        (() => {
                            const diff = timestampToSeconds(this.state.ticket.closedAt) - timestampToSeconds(this.state.ticket.createAt)
                            if (diff < 60) {
                                return "1 minuto";
                            }
                            const horas = Math.floor(diff / 3600);
                            const minutos = Math.floor((diff % 3600) / 60);
                            return horas > 0 ? `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}` : `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
                        })()
                    }</div>
                }
                {
                    <div>Operador: {this.state.ticket.createByName}</div>
                }
                {
                    this.state.ticket.prisma?.length > 0 &&
                    <div>{this.state.parameters.descricaoPrisma + this.state.ticket.prisma}</div>
                }
                {
                    this.state.ticket.placa?.length > 0 &&
                    <div className='fs-6'>Placa: {this.state.ticket.placa}</div>
                }
                {
                    !this.state.parameters.habilitarTicketResumido &&
                    <>
                        {
                            this.state.ticket.setor &&
                            <div>Setor: {this.state.ticket.setor}</div>
                        }
                        {
                            this.state.ticket.vehicler && (
                                <div>
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.marca) &&
                                        <div>Marca: {this.state.ticket.vehicler.marca}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.modelo) &&
                                        <div>Modelo: {this.state.ticket.vehicler.modelo}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.cor) > 0 &&
                                        <div>Cor: {this.state.ticket.vehicler.cor}<br /></div>
                                    }
                                </div>
                            )
                        }
                    </>
                }


                {
                    this.state.ticket.tableSelectedInCheckIn && !this.state.parameters.habilitarTicketResumido &&
                    <div>Tabela: {this.state.ticket.tableSelectedInCheckIn.nome}</div>
                }
            </div>
        )
    }
}

class PrinterPaymentsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.ticket.paymentsLogged?.length > 0 && (
                        <>
                            <div className='fs-6'>{this.state.ticket.paymentsLogged.length === 1 ? 'PAGAMENTO' : 'PAGAMENTOS'}</div>
                            {this.state.ticket.paymentsLogged.map(pagamento => (
                                <ul key={pagamento.id} className="text-start list-unstyled">
                                    <li>Data: {toDateFormat(pagamento.createAt)}</li>
                                    <li>Tipo: {pagamento.formaPagamento?.nome ?? "Não disponível"}</li>
                                    <li>Operador: {pagamento.user.nome}</li>
                                    <li>Valor: {toCurrency(pagamento.resumo.valorCobrado)}</li>
                                </ul>
                            ))}
                        </>
                    )
                }
            </div>
        )
    }
}

class PrinterInternalReviewComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.ticket.interno?.length > 0 && !this.state.parameters.habilitarTicketResumido &&
                    <div>Avaliações internas:
                        {
                            this.state.ticket.interno.map((item) => {
                                return <div> {item.quantidade} - {item.nome} </div>
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

class PrinterProductsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        var totalProdutos = 0
        return (
            <div className='text-start mt-2'>
                {
                    this.state.ticket.produtos?.length > 0 && !this.state.parameters.habilitarTicketResumido &&
                    <div>PRODUTOS
                        {
                            this.state.ticket.produtos.map((item) => {
                                totalProdutos += (Number(item.quantidade) * Number(item.valor))
                                return <div> ({item.quantidade}x) {item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} - {item.descricao} </div>
                            })
                        }
                        Total Produtos: {totalProdutos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </div>

                }
            </div>
        )
    }
}

class PrinterQRCodeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div className='mt-2'>
                {
                    this.state.parameters.imprimirTicketNoFormato === "QRCode" &&
                    <div>
                        <p>Aponte a sua câmera para o QRCode abaixo.<br />Solicite o seu veículo online.</p>
                        <QRCodeSVG value={urlTicketOnlineFromTicket(this.state.ticket, "payment-receipt")} size={100} />
                    </div>
                }
                {
                    this.state.parameters.datahabilitarNumeroImpressoNoTicket &&
                    <div className='fs-1'>{this.state.ticket.ticket}</div>
                }
            </div>
        )
    }
}

class PrinterFooterComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park,
            revenda: props.revenda
        }
    }

    render() {
        var revenda = getRevenda()
        var siteRevenda = revenda.site.split("/")[2]
        return (
            <div >
                {
                    <div>{siteRevenda}</div>
                }
                {
                    <div>
                        Impresso {new Date().toLocaleDateString('pt-BR')} às {new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                    </div>
                }
            </div>
        )
    }
}

export { PrinterHeaderComponent, BodyTicketInComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, PrinterFooterComponent, PrinterQRCodeComponent, MessageInTicketComponent }
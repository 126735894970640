import firebase from '../../config/firebase';
import { getUsuarioCompact } from '../../config/auth';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllAccessCardsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('accessCard').where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result)
}

export const getAccessCardById = async ({ id }) => {
    const result = await firebase.firestore().collection('accessCard').doc(id).get()
    return docToItem(result)
}

export const getAllAccessCardsByReferenceId = async ({ referenceId, estacionamentoId }) => {
    const result = await firebase.firestore().collection('accessCard').where('estacionamentoId', '==', estacionamentoId).where('referenceId', '==', referenceId).get()
    return docsToItems(result)
}

export const getAccessCardByParkIdAndNumber = async ({ estacionamentoId, accessCard }) => {
    const result = await firebase.firestore().collection('accessCard').where('estacionamentoId', '==', estacionamentoId).where('cardNumber', '==', parseInt(accessCard).toString()).get()
    return docsToItem(result)
}

export const getAllAccessCardsOfAccreditedByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection('accessCard').where('estacionamentoId', '==', estacionamentoId).where('type', '==', "accredited").get()
    return docsToItems(result)
}

export const addAccessCard = async ({ clienteId, estacionamentoId, referenceId, type, accessCard }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        referenceId: referenceId,
        type: type,
        cardNumber: parseInt(accessCard).toString(),
        createdAt: new Date(),
        createdBy: getUsuarioCompact(),
        isDeleted: false,
    }
    const doc = await firebase.firestore().collection('accessCard').add(data)
    return doc.id;
}

export const updateAccessCard = async ({ id, data }) => {
    await firebase.firestore().collection('accessCard').doc(id).update(data)
}

export const removeAccessCard = async ({ id }) => {
    var data = await getAccessCardById({ id: id })
    data.isDeleted = true
    await deleteDoc({ collection: "accessCard", id: id })
}

import firebase from '../../config/firebase';
import { docsToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllCamerasLPRByParkId = async ({ estacionamentoId }) => {
    const results = await firebase.firestore().collection('cameras-lpr')
        .where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(results)
}

export const getCamerasLPRByIdentifierAndParkId = async ({ identifier, estacionamentoId }) => {
    const results = await firebase.firestore().collection('cameras-lpr')
        .where('estacionamentoId', '==', estacionamentoId)
        .where('identifier', '==', identifier).get()
    return docsToItem(results)
}

export const addCameraLPR = async ({ data }) => {
    await firebase.firestore().collection('cameras-lpr').add(data)
}

export const updateCameraLPR = async ({ id, data }) => {
    await firebase.firestore().collection('cameras-lpr').doc(id).update(data)
}

export const deleteCameraLPR = async ({ id }) => {
    await deleteDoc({ collection: "cameras-lpr", id: id })
}

import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllMovementsCategoriesCashiers = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection("caixas-movimentacoes-categorias").where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItems(result)
}

export const getMovementsCategoryCashierByName = async ({ name, estacionamentoId }) => {
    const result = await firebase.firestore().collection("caixas-movimentacoes-categorias").where("name", "==", name).where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItems(result)
}

export const createMovementCategoryCashier = async ({ data }) => {
    await firebase.firestore().collection("caixas-movimentacoes-categorias").add(data)
}

export const deleteMovementCategoryCashier = async ({ id }) => {
    await deleteDoc({ collection: "caixas-movimentacoes-categorias", id: id })
}
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllEquipmentsByParkId = async ({ estacionamentoId }) => {
    const results = await firebase.firestore().collection('equipamentos')
        .where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(results)
}

export const getAllEquipmentsByLPRIdentifierAndParkId = async ({ lprIdentifier, estacionamentoId }) => {
    const results = await firebase.firestore().collection('equipamentos')
        .where('estacionamentoId', '==', estacionamentoId)
        .where('lprIdentifier', '==', lprIdentifier).get()
    return docsToItems(results)
}

export const updateEquipment = async ({ id, data }) => {
    await firebase.firestore().collection('equipamentos').doc(id).update(data)
}

export const deleteEquipment = async ({ id }) => {
    await deleteDoc({ collection: "equipamentos", id: id })
}

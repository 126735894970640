import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent } from '../../../components/common/commons'
import { generateUUID, reloadWindow, toastWarning } from '../../../shared/utils';
import { getUsuarioCompact } from '../../../config/auth';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { addApplication, deleteApplicationById, getAllApplications, updateApplicationById } from '../../../store/collections/applicationsWorker';

class DeveloperApplications extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            name: "",
            field: "",
            applicationId: generateUUID(),
            technicalManager: "",
            technicalContact: "",
        }
    }

    onAdd = async () => {
        this.setState({})
        this.setState({ isOpenModal: true })
    }

    onEdit = (item) => {
        this.setState({ ...item, id: item.id })
        this.setState({ isOpenModal: true })
    }

    saveNew = async () => {
        if (!this.checkFields()) {
            return
        }
        var data = this.retriveData()
        data.createdAt = new Date()
        data.createdBy = getUsuarioCompact()
        await addApplication({ data: data })
        reloadWindow()
    }

    saveEdit = async () => {
        if (!this.checkFields()) {
            return
        }
        var data = this.retriveData()
        data.updatedAt = new Date()
        data.updatedBy = getUsuarioCompact()
        await updateApplicationById({ id: this.state.id, data: data })
        reloadWindow()
    }

    checkFields = () => {
        if (this.state.name === "") {
            toastWarning("Informe o nome da aplicação")
            return false
        }
        if (this.state.field === "") {
            toastWarning("Informe o campo da aplicação")
            return false
        }
        if (this.state.technicalManager === "") {
            toastWarning("Informe o nome da aplicação")
            return false
        }
        if (this.state.technicalPhone === "") {
            toastWarning("Informe o nome da aplicação")
            return false
        }
        if (this.state.technicalEmail === "") {
            toastWarning("Informe o nome da aplicação")
            return false
        }
        return true
    }

    retriveData = () => {
        return {
            name: this.state.name,
            applicationId: this.state.applicationId,
            field: this.state.field,
            technicalManager: this.state.technicalManager,
            technicalPhone: this.state.technicalPhone,
            technicalEmail: this.state.technicalEmail,
            allowTicketCalculate: this.state.allowTicketCalculate,
            allowTicketPayment: this.state.allowTicketPayment,
        }
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover esta aplicação?`);
        if (confirm) {
            await deleteApplicationById({ id: item.id })
            reloadWindow()
        }
    }

    render() {
        return (
            <NavBar>
                <Dialog fullWidth maxWidth={'sm'} open={this.state.isOpenModal}>
                    <MuiDialogTitle className='text-center'>
                        Cadastrar aplicação
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='row'>
                            <div className="col-lg-6">
                                <label>Nome da aplicação</label>
                                <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                            </div>
                            <div className="col-lg-6">
                                <label>Id de autenticação</label>
                                <input type="text" disabled onChange={(e) => this.setState({ applicationId: e.target.value })} value={this.state.applicationId} className="form-control" required />
                            </div>
                            <div className="col-lg-6">
                                <label>Campo único</label>
                                <input type="text" onChange={(e) => this.setState({ field: e.target.value })} value={this.state.field} className="form-control" required />
                            </div>
                            <h5 className='mt-3'>Representante técnico</h5>
                            <div className="col-lg-12">
                                <label>Nome</label>
                                <input type="text" onChange={(e) => this.setState({ technicalManager: e.target.value })} value={this.state.technicalManager} className="form-control" required />
                            </div>
                            <div className="col-lg-6">
                                <label className="my-2">Celular</label>
                                <NumberFormat className="form-control" value={this.state.technicalPhone} mask='_' onChange={(e) => this.setState({ technicalPhone: e.target.value })} format="(##)#####-####" />
                            </div>
                            <div className="col-lg-6">
                                <label className="my-2">E-mail</label>
                                <input type="text" onChange={(e) => this.setState({ technicalEmail: e.target.value })} value={this.state.technicalEmail} className="form-control" required />
                            </div>
                            <h5 className='mt-3'>Permissões</h5>
                            <div className='col-lg-12'>
                                <div className="form-check form-switch my-2">
                                    <input className="form-check-input" id="allowTicketCalculate" checked={this.state.allowTicketCalculate} onChange={(e) => this.setState({ allowTicketCalculate: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="allowTicketCalculate">
                                        Permitir calcular valor total de um ticket.
                                    </label>
                                </div>
                                <div className="form-check form-switch my-2">
                                    <input className="form-check-input" id="allowTicketPayment" checked={this.state.allowTicketPayment} onChange={(e) => this.setState({ allowTicketPayment: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="allowTicketPayment">
                                        Permitir registrar pagamento em um ticket.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions className='m-3'>
                        <div className='btn-group'>
                            <button onClick={(e) => this.setState({ isOpenModal: false })} className='btn btn-secondary'>Fechar</button>
                            {
                                this.state.id ?
                                    <button onClick={this.saveEdit} className='btn btn-success'>Atualizar</button>
                                    :
                                    <button onClick={this.saveNew} className='btn btn-success'>Cadastrar</button>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <DeveloperApplicationList
                    onEdit={this.onEdit}
                    onAdd={this.onAdd}
                    onRemove={this.onRemove} />
            </NavBar >
        )
    }
}

class DeveloperApplicationList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true
        }
        this.load()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    onAdd = () => {
        this.props.onAdd()
    }

    load = async () => {
        this.setState({ loading: true });
        const items = await getAllApplications()
        this.setState({ items: items })
        this.setState({ loading: false });
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Aplicações
                        <button type="button" onClick={this.onAdd} className="btn btn-sm btn-success text-white">
                            <i className="fab fa-app-store-ios mx-2" />
                            Criar nova
                        </button>
                    </div>
                    {
                        this.state.items.length === 0 && this.state.loading === false ?
                            <EmptyContent text='Nenhuma aplicação cadastrada' />
                            :
                            this.state.loading === false ?
                                <div className='row'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Id de autenticação</th>
                                                    <th scope="col">Campo</th>
                                                    <th scope="col">Desde</th>
                                                    <th scope="col" width={10}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>{item.applicationId}</td>
                                                            <td>{item.field}</td>
                                                            <td className="d-none d-sm-table-cell">{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY")}</td>
                                                            <td width={10}>
                                                                <div className='btn-group'>
                                                                    <button type="button" onClick={() => this.onEdit(item)} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                    <button type="button" onClick={() => this.onRemove(item)} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </>
        );
    }
}

export { DeveloperApplications, DeveloperApplicationList };
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllApplications = async () => {
    const result = await firebase.firestore().collection('aplicacoes').get()
    const data = docsToItems(result).sort((a, b) => a.name.localeCompare(b.name))
    console.log(data)
    return data
}

export const addApplication = async ({ data }) => {
    const doc = await firebase.firestore().collection('aplicacoes').add({ ...data })
    return doc.id;
}

export const updateApplicationById = async ({ id, data }) => {
    await firebase.firestore().collection('aplicacoes').doc(id).update(data)
}

export const deleteApplicationById = async ({ id }) => {
    await deleteDoc({ collection: "aplicacoes", id: id })
}

import { getRevendaIdByPark } from '../../config/auth';
import firebase from '../../config/firebase';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllCoupons = async () => {
    const result = await firebase.firestore().collection('planos-licenca-novo-cupons').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result)
}

export const getCouponByIdentifier = async ({ identifier }) => {
    const result = await firebase.firestore().collection('planos-licenca-novo-cupons').where("identifier", "==", identifier).get()
    return docsToItem(result)
}

export const getCouponById = async ({ id }) => {
    const result = await firebase.firestore().collection('planos-licenca-novo-cupons').doc(id).get()
    return docToItem(result)
}

export const addCoupon = async (data) => {
    data.revendaId = getRevendaIdByPark()
    const doc = await firebase.firestore().collection('planos-licenca-novo-cupons').add(data)
    return doc.id;
}

export const deleteCouponById = async ({ id }) => {
    await deleteDoc({ collection: "planos-licenca-novo-cupons", id: id })
}
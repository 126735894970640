import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getAllSupportCentersByRessales = async ({ associatedResales }) => {
    var results = await firebase.firestore().collection('support-center').where("revendaId", "in", associatedResales.map(e => e.value)).get()
    return docsToItems(results)
}

export const addSupportCenter = async ({ data }) => {
    await firebase.firestore().collection('support-center').add(data)
}

export const updateSupportCenterById = async ({ id, data }) => {
    await firebase.firestore().collection('support-center').doc(id).update(data)
}

export const removeSupportCenterById = async ({ id }) => {
    await deleteDoc({ collection: "support-center", id: id })
}
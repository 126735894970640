import { getRevenda, getUsuarioCompact, isAdministrador } from "../../../config/auth";
import { sendClickButton } from "../../../shared/analytics";
import React from 'react';
import { goToNewWindow, isSelfParking, normalizedField } from "../../../shared/utils";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getParameter } from "../../../store/collections/parameterWorker";

class MarketingBannerTaggy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showBanner: (window.location.href.indexOf("desenvolvimento") === -1) && (window.location.href.indexOf("revenda") === -1 && window.location.href.indexOf("plataforma") === -1)
        }
    }

    showWhatsAppLink = () => {
        sendClickButton(this.props.screen, "Banner Taggy")
        const revenda = getRevenda()
        const number = "11984180090"// revenda.whatsAppSuporte?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "") ?? ""
        window.open(
            `https://api.whatsapp.com/send/?phone=55${number}&text=Quero saber mais sobre como habilitar o pagamento via Taggy&type=phone_number`,
            '_blank'
        );
    }

    render() {
        return (
            <>
                {this.state.showBanner &&
                    <div className='col-lg-12 mb-3'>
                        <div className="card text-white bg-success" role='button' onClick={this.showWhatsAppLink} >
                            <div className="card-body">
                                <img src="https://www.taggy.com.br/images/taggy.svg" alt="Taggy" />
                                <h5 className='mt-2'>Ofereça esta nova forma de pagamento e traga mais agilidade para os seus clientes.</h5>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

class TermosDeUsoBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showBanner: isSelfParking() && (window.location.href.indexOf("desenvolvimento") === -1) && (window.location.href.indexOf("revenda") === -1 && window.location.href.indexOf("plataforma") === -1)
        }
    }

    click = () => {
        const user = getUsuarioCompact()
        const message = `O usuário ${user.nome} tocou no botão para visualizar os de termos de uso e politica de privacidade atualizados.`
        sendClickButton(this.props.screen, message)
        window.open(
            getRevenda()?.sitePrivacidade,
            '_blank'
        );
    }

    showNewItems = () => {
        const user = getUsuarioCompact()
        const message = `O usuário ${user.nome} tocou no banner de termos de uso e politica de privacidade.`
        sendClickButton(this.props.screen, message)
        goToNewWindow("/termos-de-uso-e-politica-de-privacidade")
    }

    hideNewItems = () => {
        this.setState({ isShowingNewItems: false })
    }

    render() {
        return (
            <>
                {this.state.showBanner &&
                    <div className='col-lg-12 mb-3'>
                        <div className="card text-white bg-secondary" role='button' onClick={this.showNewItems} >
                            <div className="card-body">
                                <h5 className='mt-2'>Atualizamos o nosso termo de uso e nossa política de privacidade para te proporcionar mais segurança e transparência. Toque para ler.</h5>
                                <small>* Caso continue usando o nosso sistema você concorda com o novo termo de uso e a política de privacidade.</small>
                            </div>
                        </div>
                    </div>
                }
                {/* <Dialog onClose={this.hideNewItems} fullWidth maxWidth='sm' open={this.state.isShowingNewItems}>
                    <MuiDialogTitle className='text-center'>
                        Mudanças
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        1.4. Planos: Diferentes escolhas ou pacotes que a SELFPARKING oferece aos seus clientes ou usuários ao contratar seus produtos ou serviços. <br />
                        Esses planos são projetados para atender às variadas necessidades, proporcionando flexibilidade e opções personalizadas, incluindo diferentes combinações de recursos, níveis de serviço e preços. <br />
                        Em caso de upgrade de plano por maior volume de veículos do que foi contratado, a SELFPARKING manterá a cobrança do novo plano por, no mínimo, 3 (três meses), quando poderá o usuário solicitar o downgrade, se aplicável.
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.hideNewItems()} className='btn btn-secondary'>Fechar</button>
                            <button onClick={(e) => this.click()} className='btn btn-success'>Visualizar documento completo</button>
                        </div>
                    </MuiDialogActions>
                </Dialog> */}
            </>
        )
    }
}

class CobrancaMensalistaBanner extends React.Component {

    constructor(props) {
        super(props);
        this.load()
        this.state = {
            showBanner: false
        }
    }

    load = async () => {
        const parameter = await getParameter()
        const showBanner = isSelfParking() && parameter?.habilitarPagamentoMensalistasOnline === false && (window.location.href.indexOf("desenvolvimento") === -1) && (window.location.href.indexOf("revenda") === -1 && window.location.href.indexOf("plataforma") === -1)
        this.setState({ showBanner: showBanner })
    }

    showNewItems = () => {
        const user = getUsuarioCompact()
        const message = `O usuário ${user.nome} tocou no botão para conversar com um especialista para ativar a cobrança de mensalista.`
        sendClickButton(this.props.screen, message)
        let revenda = getRevenda()
        const url = `https://api.whatsapp.com/send?phone=55${normalizedField(revenda.celular)}&message=Quero%20habilitar%20a%20cobrança%20dos%20mensalistas`
        goToNewWindow(url)
    }

    render() {
        return (
            <>
                {this.state.showBanner &&
                    <div className='col-lg-12 mb-3' role='button' onClick={this.showNewItems} >
                        <div className="d-none d-sm-block">
                            <img alt='Cobrança mensalista' style={{ width: "100%", height: "auto", borderRadius: "8px" }} src={require('../../../files/marketing/cobranca_mensalista_horizontal.svg')} />
                        </div>
                        <div className="d-block d-sm-none">
                            <img alt='Cobrança mensalista' style={{ width: "100%", height: "auto", borderRadius: "8px" }} src={require('../../../files/marketing/cobranca_mensalista_horizontal_xs.svg')} />
                        </div>
                    </div>
                }
            </>
        )
    }
}

class MarketingBanner extends React.Component {

    render() {
        return (
            <CobrancaMensalistaBanner />
        )
    }
}

export { MarketingBanner }